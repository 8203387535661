import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useDispatch } from 'react-redux'
import Voicemail from './Voicemail'
import { getallvmboxes, getmainvmboxes } from '../../actions/voicemails.action'
import CONFIG from '../../constants/config.json'
import { getPhoneNumber } from '../../lib/utilities'

import './voicemails.css'

const accountId = localStorage.getItem('account_id')
const userId = localStorage.getItem('user_id')
const authToken = localStorage.getItem('token')

const VoicemailsTable = (props) => {
  const dispatch = useDispatch()

  const [audioPlay, setAudioPlay] = useState(false)
  const [audioId, setAudioId] = useState('')
  const [messageRecords, setMessageRecords] = useState('')
  const [loader, setLoader] = useState(false)

  useEffect(() => {
    const { allmessages } = props

    if (allmessages) {
      let perPage = props.perPage
      let currentPage = props.currentPage
      let filteredMessages = filtermailList(allmessages, perPage, currentPage)

      setMessageRecords(filteredMessages)
    }
  }, [props])

  const filtermailList = (messageRecords, perPage, currentPage, search) => {
    let subMessageRecords = []
    if (messageRecords && messageRecords.length > 0) {
      for (var index = perPage * currentPage; index < perPage * (currentPage + 1); index++) {
        if (messageRecords[index]) {
          if (!search) {
            subMessageRecords.push(messageRecords[index])
          } else {
            let searchKey = search.trim()
            let from = getPhoneNumber(messageRecords[index].from.split('@')[0])
            let to = getPhoneNumber(messageRecords[index].to.split('@')[0])
            if (
              from.includes(searchKey) ||
              to.includes(searchKey) ||
              messageRecords[index].caller_id_name.includes(searchKey)
            )
              subMessageRecords.push(messageRecords[index])
          }
        }
      }
    }
    return subMessageRecords
  }

  const audioPlayer = (key) => {
    setAudioId(key)
    setAudioPlay(!audioPlay)
  }

  const voicemailDelete = (vmbox_id, media_id, skipConfirm = false) => {
    if (skipConfirm || window.confirm('Are you sure wish to delete this Voicemail?')) {
      let URL = `${CONFIG.API_URL}/accounts/${accountId}/vmboxes/${vmbox_id}/messages/${media_id}`
      axios
        .delete(URL)
        .then((res) => {
          dispatch(getallvmboxes())
          dispatch(getmainvmboxes())
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }

  const voicemailChangeStatus = (folderName, vmbox_id, media_id) => {
    if (window.confirm('Are you sure wish to change status of this Voicemail?')) {
      let URL = `${CONFIG.API_URL}/accounts/${accountId}/vmboxes/${vmbox_id}/messages/${media_id}`
      setLoader(true)
      axios
        .post(URL, { data: { folder: folderName } })
        .then((res) => {
          dispatch(getallvmboxes())
          dispatch(getmainvmboxes())
          setLoader(false)
        })
        .catch((error) => {
          console.log(error)
          setLoader(false)
        })
    }
  }

  const audioPlayerEnd = (key, vmbox_id, media_id, state) => {
    if (state === 'new') {
      let url = `${CONFIG.API_URL}/accounts/${accountId}/vmboxes/${vmbox_id}/messages/${media_id}`
      axios
        .post(url)
        .then((res) => {
          dispatch(getallvmboxes())
          dispatch(getmainvmboxes())
        })
        .catch((error) => {
          console.log(error)
        })
    }
    setAudioId(key)
    setAudioPlay(!audioPlay)
  }

  const selectRowToUpdate = (media_id) => {
    const newSelected = Object.assign({}, props.selected)
    newSelected[media_id] = !props.selected[media_id]
    props.setSelectedState(newSelected)
  }

  const selectAllToUpdate = () => {
    let newSelected = {}
    const { allmessages, perPage, currentPage, searchKey } = props
    let messageRecords = filtermailList(allmessages, perPage, currentPage, searchKey)
    if (props.selectAll === false) {
      messageRecords.forEach((message) => {
        newSelected[message.media_id] = true
      })
    }
    props.setAllSelectedState(newSelected)
  }

  return (
    <>
      <div className="voicemail-row border-0">
        <div className="col-md-1 col-6 d-flex justify-content-between align-items-center">
          <input
            type="checkbox"
            className="multi-select-control-input mr-2"
            id="selectAll"
            checked={props.selectAll === true}
            onChange={selectAllToUpdate}
          />
          <span>STATUS</span>
        </div>
        <div className="col-md-2 col-6">DATE TIME</div>
        <div className="col-md-2 col-6">FROM</div>
        <div className="col-md-2 col-6">TO</div>
        <div className="col-md-1 col-12">DURATION</div>
        <div className="col-md-4 col-12"></div>
      </div>
      {messageRecords?.length > 0 ? (
        messageRecords.map((message, index) => (
          <Voicemail
            key={index}
            selectRowToUpdate={selectRowToUpdate}
            history={props.history}
            selected={props.selected}
            audioPlayer={audioPlayer}
            audioPlayerEnd={audioPlayerEnd}
            voicemailDelete={voicemailDelete}
            voicemailChangeStatus={voicemailChangeStatus}
            authToken={authToken}
            accountId={accountId}
            userId={userId}
            vmboxId={props.vmbox_id}
            audioId={audioId}
            audioPlay={audioPlay}
            loader={loader}
            mediaId={message.media_id}
            from={message.from}
            to={message.to}
            timestamp={message.timestamp}
            length={message.length}
            folder={message.folder}
          />
        ))
      ) : (
        <div className="col-md-12 text-center">
          <h2>No Results!</h2>
        </div>
      )}
    </>
  )
}

export default VoicemailsTable
