import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import DatePicker from 'react-datepicker'
import moment from 'moment'

import { ToastContainer } from 'react-toastify'
import { getCallForward } from '../../actions/message.action'
import { getCallLogs } from '../../actions/callreport.action'
import UserHeader from '../Header/UserHeader'
import Sidebar from '../Sidebar/Sidebar'
import Dialog from '../Sidebar/Dialog'
import './styles.css'

const CallReport = (props) => {
  const dispatch = useDispatch()
  const day = new Date()
  const { isAdmin } = useSelector((state) => state.message.numbers)
  const { users } = useSelector((state) => state.dashboard)
  const { callreports } = useSelector((state) => state.callreport)

  const [contactToogle, updateContactUs] = useState(false)
  const [setNumberToogle, updateSetNumber] = useState(false)
  const [allUserNames, setAllUserNames] = useState([])
  const [filterUsers, setFilterUsers] = useState([])
  const [startDate, setStartDate] = useState(new Date(day.setDate(day.getDate() - 30)))
  const [endDate, setEndDate] = useState(new Date())
  const [callLogs, setCallLogs] = useState([])
  const contactUsModal = () => {
    updateContactUs(!contactToogle)
  }
  const changeSetNumberModal = () => {
    if (!setNumberToogle) {
      dispatch(getCallForward())
    }
    updateSetNumber(!setNumberToogle)
  }

  const selectUser = (e) => {
    const userData = [...allUserNames]
    const index = allUserNames.findIndex((user) => user.email === e.target.id)
    userData[index].state = e.target.checked
    setAllUserNames(userData)
  }

  const startDateChange = (date) => {
    setStartDate(date)
    const dateDiff = parseInt((endDate.getTime() - date.getTime()) / (24 * 3600 * 1000), 10)
    if (dateDiff > 30) {
      setEndDate(new Date(date.getTime() + 30 * 24 * 3600 * 1000))
    }
  }
  const endDateChange = (date) => {
    setEndDate(date)
    const dateDiff = parseInt((date.getTime() - startDate.getTime()) / (24 * 3600 * 1000), 10)
    if (dateDiff > 30) {
      setStartDate(new Date(endDate.getTime() - 30 * 24 * 3600 * 1000))
    }
  }

  const filterReport = () => {
    const firstDate = moment(startDate).unix()
    const secondDate = moment(endDate).unix()
    const filters = allUserNames.filter((user) => user.state)
    setFilterUsers(filters)
    dispatch(getCallLogs(firstDate, secondDate, filters))
  }

  const getUserCallReports = () => {
    let cdrsList = []
    if (callreports && callreports.length > 0) {
      for (let i = 0; i < callreports.length; i++) {
        let inCount = 0,
          inMins = 0,
          outCount = 0,
          outMins = 0,
          totalCount = 0,
          totalMins = 0
        if (callreports[i].length > 0) {
          for (let j = 0; j < callreports[i].length; j++) {
            if (callreports[i][j].direction === 'inbound') {
              inCount++
              inMins += parseInt(callreports[i][j].duration_seconds)
            } else if (callreports[i][j].direction === 'outbound') {
              outCount++
              outMins += parseInt(callreports[i][j].duration_seconds)
            }
          }
        }
        totalCount = inCount + outCount
        totalMins = inMins + outMins
        cdrsList.push({
          userName: filterUsers[i].userName,
          inCount: inCount,
          inMins: (inMins / 60).toFixed(2),
          outCount: outCount,
          outMins: (outMins / 60).toFixed(2),
          totalCount: totalCount,
          totalMins: (totalMins / 60).toFixed(2),
        })
      }
      setCallLogs(cdrsList)
    }
  }

  useEffect(() => {
    if (!isAdmin) {
      props.history.push('/home')
    }
    //eslint-disable-next-line
  }, [isAdmin])

  useEffect(() => {
    const dataArray = []
    users &&
      users.forEach((user) => {
        dataArray.push({
          email: user.username,
          userName: user.first_name + ' ' + user.last_name,
          state: false,
          userId: user.id,
        })
      })

    setAllUserNames(dataArray)
    // eslint-disable-next-line
  }, [users])

  useEffect(() => {
    getUserCallReports()
    // eslint-disable-next-line
  }, [callreports])

  return (
    <div className="light">
      <ToastContainer autoClose={8000} />
      <div className="layout-admin">
        <Sidebar contactUsModal={contactUsModal} changeSetNumberModal={changeSetNumberModal} history={props.history} />
        <Dialog
          contactUsModal={contactUsModal}
          contactToogle={contactToogle}
          changeSetNumberModal={changeSetNumberModal}
          setNumberToogle={setNumberToogle}
        />
        <div className="content">
          <UserHeader changeSetNumberModal={changeSetNumberModal} history={props.history} />
          <div className="management">
            <div className="row">
              <div className="col-md-12 d-flex justify-content-center align-items-center">
                <div className="mr-3">
                  <label className="mr-2">START DATE :</label>
                  <DatePicker
                    className="form-control calendar1"
                    onChange={startDateChange}
                    maxDate={endDate}
                    selected={startDate}
                    placeholderText="mm/dd/yyyy"
                  />
                </div>
                <div className="mr-3">
                  <label className="mr-2">END DATE :</label>
                  <DatePicker
                    className="form-control calendar1"
                    onChange={endDateChange}
                    selected={endDate}
                    minDate={startDate}
                    maxDate={Date.now()}
                    placeholderText="mm/dd/yyyy"
                  />
                </div>
                <div>
                  <button className="btn history-search-button btn-primary" onClick={filterReport}>
                    Filter
                  </button>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-3 col-md-4 mb-4 mt-4">
                {allUserNames.map((user, index) => (
                  <div className="user-list">
                    <div className="form-item custom-control custom-switch" key={index}>
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        checked={user.state}
                        onChange={selectUser}
                        id={user.email}
                      />
                      <label className="custom-control-label user-name" htmlFor={user.email}>
                        {user.userName}
                      </label>
                    </div>
                  </div>
                ))}
              </div>
              <div className="col-lg-9 col-md-8 report-table">
                <table className="table table-striped mb-0 client-table mt-4">
                  <thead>
                    <tr>
                      <th>User Name</th>
                      <th>Inbound Calls </th>
                      <th>Inbound Minutes</th>
                      <th>Outbound Calls</th>
                      <th>Outbound Minutes</th>
                      <th>Total Calls</th>
                      <th>Total Minutes</th>
                    </tr>
                  </thead>
                  <tbody>
                    {callLogs.length > 0 &&
                      callLogs.map((log, index) => (
                        <tr key={index}>
                          <td>{log.userName}</td>
                          <td>{log.inCount}</td>
                          <td>{log.inMins}</td>
                          <td>{log.outCount}</td>
                          <td>{log.outMins}</td>
                          <td>{log.totalCount}</td>
                          <td>{log.totalMins}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CallReport
