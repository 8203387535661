import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { getCallForward } from '../../actions/message.action'
import UserHeader from '../Header/UserHeader'
import Dialog from '../Sidebar/Dialog'
import Sidebar from '../Sidebar/Sidebar'
import './tabing.css'
import Col from 'react-bootstrap/Col'
import Nav from 'react-bootstrap/Nav'
import Row from 'react-bootstrap/Row'
import Tab from 'react-bootstrap/Tab'
import CampaignRegistryTable from './CampaignRegistryTable'
import BrandManagement from './BrandManagement'

const Tabing = (props) => {
  const [contactToogle, updateContactUs] = useState(false)
  const [setNumberToogle, updateSetNumber] = useState(false)
  const dispatch = useDispatch()

  const contactUsModal = () => {
    updateContactUs(!contactToogle)
  }

  const changeSetNumberModal = () => {
    if (!setNumberToogle) {
      dispatch(getCallForward())
    }
    updateSetNumber(!setNumberToogle)
  }

  return (
    <div className="light">
      <div className="layout-user">
        <Sidebar contactUsModal={contactUsModal} changeSetNumberModal={changeSetNumberModal} history={props.history} />
        <Dialog
          contactUsModal={contactUsModal}
          contactToogle={contactToogle}
          changeSetNumberModal={changeSetNumberModal}
          setNumberToogle={setNumberToogle}
        />
        <div className="content">
          <UserHeader changeSetNumberModal={changeSetNumberModal} history={props.history} />
          <div className="tabing-part">
            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
              <Row>
                <Col sm={3}>
                  <div className="tablist">
                    <Nav variant="pills" className="flex-column">
                      <Nav.Item>
                        <Nav.Link eventKey="first">Brands</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="second">Campaigns</Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </div>
                </Col>
                <Col sm={9}>
                  <div className="tabcontent">
                    <Tab.Content>
                      <Tab.Pane eventKey="first">
                        <BrandManagement />
                      </Tab.Pane>
                      <Tab.Pane eventKey="second">
                        <CampaignRegistryTable />
                      </Tab.Pane>
                    </Tab.Content>
                  </div>
                </Col>
              </Row>
            </Tab.Container>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Tabing
