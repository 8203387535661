import React, { Fragment } from 'react'
import { useDispatch } from 'react-redux'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { delDistribution } from '../../actions/distribution.action'

const DistributionDelete = (props) => {
  const dispatch = useDispatch()

  const toggle = () => {
    props.toggleDeleteDialog(!props.showDeleteDialog)
  }

  const deleteDistribution = () => {
    dispatch(delDistribution(props.distributionId))
    props.toggleDeleteDialog()
  }

  return (
    <Fragment>
      <Modal
        isOpen={props.showDeleteDialog}
        toggle={toggle}
        className={`modalHeader del-contact-modal modal-dialog modal-dialog-centered modal-dialog-zoom`}
      >
        <ModalHeader></ModalHeader>
        <ModalBody className="delete-body">
          <div className="delete-icon">
            <i className="fas fa-trash"></i>
          </div>
          <div>
            <h5>
              Are you sure you want to <br /> delete this distribution?
            </h5>
          </div>
        </ModalBody>
        <ModalFooter className="delete-footer">
          <Button className="assign-footer-btn cancel-btn" onClick={toggle}>
            {'Cancel'}
          </Button>
          <Button color="primary" className="deleteContact" onClick={deleteDistribution}>
            {'Delete'}
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  )
}

export default DistributionDelete
