import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { ToastContainer, toast } from 'react-toastify'
import _ from 'lodash'
import Sidebar from '../Sidebar/Sidebar'
import Dialog from '../Sidebar/Dialog'
import { getCallForward } from '../../actions/message.action'
import {
  getPhoneNumbers,
  saveCallerIdState,
  getAssignMainNumState,
  saveAssignMainNumState,
  getAssignGroupNumState,
  saveAssignGroupNumState,
  saveE911State,
  clearE911State,
  removeE911State,
  resyncNums,
  generatePortOutPin,
} from '../../actions/admindid.action'

import './styles.css'
import UserHeader from '../Header/UserHeader'

const AdminDID = (props) => {
  const dispatch = useDispatch()
  const { isAdmin, port_pin, port_pin_date } = useSelector((state) => state.message.numbers)
  const { userName } = useSelector((state) => state.message)
  const { loading } = useSelector((state) => state.message)
  const { allNumbers, allUsers, groupUsers, e911Errors } = useSelector((state) => state.adminDID)
  const [contactToogle, updateContactUs] = useState(false)
  const [setNumberToogle, updateSetNumber] = useState(false)
  const [assignMainNumToogle, setAssignMainNumToogle] = useState(false)
  const [assignGroupNumToogle, setAssignGroupNumToogle] = useState(false)
  const [allPhoneNum, setAllPhoneNum] = useState([])
  const [allUserNames, setAllUserNames] = useState([])
  const [allGroupNames, setGroupNames] = useState([])
  const [e911Toogle, setE911Toogle] = useState(false)
  const [callerIDToogle, setCallerIDToogle] = useState(false)
  const [callerID, setCallerID] = useState({
    displayName: '',
    inboundLookup: false,
  })
  const [e911Data, setE911Data] = useState({
    postal_code: '',
    region: '',
    locality: '',
    astreet_address: '',
    extended_address: ' ',
  })
  const [selectedNum, setSelectedNum] = useState('')
  const [selectedAssignNum, setSelectedAssignNum] = useState('')
  const [selectedKey, setSelectedKey] = useState('')
  const [diffDays, diffDaysUpdate] = useState(false)

  const contactUsModal = () => {
    updateContactUs(!contactToogle)
  }
  const changeSetNumberModal = () => {
    if (!setNumberToogle) {
      dispatch(getCallForward())
    }
    updateSetNumber(!setNumberToogle)
  }
  const e911Modal = () => {
    setE911Toogle(!e911Toogle)
  }
  const callerIDModal = () => {
    setCallerIDToogle(!callerIDToogle)
  }
  const changeCallName = (e) => {
    if (e.target.id === 'inboundLookup') {
      setCallerID({ ...callerID, [e.target.id]: e.target.checked })
    } else {
      setCallerID({ ...callerID, [e.target.id]: e.target.value })
    }
  }
  const getCallerID = async (number) => {
    await getCAMNData(number)
    await setSelectedNum(number)
    await callerIDModal()
  }
  const getE911 = async (number) => {
    await setSelectedNum(number)
    await getE911Data(number)
    await e911Modal()
  }

  const assignMainNum = (num) => {
    dispatch(getAssignMainNumState(num))
    setSelectedAssignNum(num)
    assignMainNumModal()
  }
  const assignGroupNum = (groupId, number) => {
    dispatch(getAssignGroupNumState(groupId, number))
    setSelectedAssignNum(number)
    assignGroupNumModal()
  }
  const assignMainNumModal = () => {
    setAssignMainNumToogle(!assignMainNumToogle)
  }
  const assignGroupNumModal = () => {
    setAssignGroupNumToogle(!assignGroupNumToogle)
  }
  const changeMainAssignState = (e) => {
    const userData = [...allUserNames]
    const index = allUserNames.findIndex((user) => user.userId === e.target.id)
    userData[index].state = e.target.checked
    setAllUserNames(_.sortBy(userData, 'userName'))
  }
  const changeGroupAssignState = (e) => {
    const userData = [...allGroupNames]
    const index = allGroupNames.findIndex((user) => user.userId === e.target.id)
    userData[index].state = e.target.checked
    setAllUserNames(_.sortBy(userData, 'userName'))
  }
  const saveMainAssignState = () => {
    dispatch(saveAssignMainNumState(allUserNames, selectedAssignNum))
    setSelectedAssignNum('')
    assignMainNumModal()
  }
  const saveGroupAssignState = () => {
    dispatch(saveAssignGroupNumState(allGroupNames, selectedAssignNum))
    setSelectedAssignNum('')
    assignGroupNumModal()
  }
  const handleSearchNumber = (e) => {
    setSelectedKey(e.target.value)
  }
  const searchNumber = () => {
    if (selectedKey !== '') {
      const numbers = allNumbers.filter((num) => num.number.indexOf(selectedKey) !== -1)
      setAllPhoneNum(numbers)
    } else {
      setAllPhoneNum(allNumbers)
    }
  }
  const changeHandleE911 = (e) => {
    setE911Data({
      ...e911Data,
      [e.target.id]: e.target.value,
    })
  }
  const saveE911Data = () => {
    dispatch(saveE911State(selectedNum, e911Data))
    setE911Toogle(!e911Toogle)
  }

  const removeE911Data = () => {
    dispatch(removeE911State(selectedNum))
    setE911Toogle(!e911Toogle)
  }

  const getE911Data = (number) => {
    const data = allPhoneNum.filter((ele) => ele.number === number)
    if (data[0] && data[0].e911 !== '')
      setE911Data({
        ...e911Data,
        postal_code: data[0].e911.postal_code,
        region: data[0].e911.region,
        locality: data[0].e911.locality,
        street_address: data[0].e911.street_address,
        extended_address: data[0].e911.extended_address,
      })
    else {
      setE911Data({
        ...e911Data,
        postal_code: '',
        region: '',
        locality: '',
        street_address: '',
        extended_address: '',
      })
    }
  }
  const getCAMNData = (number) => {
    const data = allPhoneNum.filter((ele) => ele.number === number)
    if (data[0] && data[0].cnam !== '') {
      setCallerID({
        ...callerID,
        displayName: data[0].cnam.display_name,
        inboundLookup: data[0].cnam.inbound_lookup,
      })
    } else {
      setCallerID({ ...callerID, displayName: '', inboundLookup: '' })
    }
  }
  const saveCallerIdData = () => {
    dispatch(saveCallerIdState(selectedNum, callerID))
    callerIDModal()
  }
  const resyncNumbers = () => {
    dispatch(resyncNums())
  }
  const portOutPin = () => {
    dispatch(generatePortOutPin(userName.userEmail))
  }

  useEffect(() => {
    const diffTime = Math.abs(new Date() - new Date(port_pin_date))
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
    diffDaysUpdate(diffDays)
  }, [port_pin_date])

  useEffect(() => {
    if (allNumbers.length > 0) {
      setAllPhoneNum(allNumbers)
    }
  }, [allNumbers])

  useEffect(() => {
    if (e911Errors !== '') {
      toast.warn('Location is not geocoded ', {
        position: toast.POSITION.TOP_RIGHT,
      })
      dispatch(clearE911State())
    }
    //eslint-disable-next-line
  }, [e911Errors])

  useEffect(() => {
    if (allUsers.length > 0) {
      setAllUserNames(_.sortBy(allUsers, 'userName'))
    }
  }, [allUsers])
  useEffect(() => {
    if (groupUsers.length > 0) {
      setGroupNames(_.sortBy(groupUsers, 'userName'))
    }
  }, [groupUsers])
  useEffect(() => {
    if (!isAdmin) {
      props.history.push('/home')
    }
    //eslint-disable-next-line
  }, [isAdmin])
  useEffect(() => {
    dispatch(getPhoneNumbers())
    //eslint-disable-next-line
  }, [])

  return (
    <div className="light">
      {loading && (
        <div className="loader-container">
          <div className="loader"></div>
        </div>
      )}
      <ToastContainer autoClose={8000} />
      <div className="layout-admin">
        <Sidebar contactUsModal={contactUsModal} changeSetNumberModal={changeSetNumberModal} history={props.history} />
        <Dialog
          contactUsModal={contactUsModal}
          contactToogle={contactToogle}
          changeSetNumberModal={changeSetNumberModal}
          setNumberToogle={setNumberToogle}
        />
        <div className="content">
          <UserHeader changeSetNumberModal={changeSetNumberModal} history={props.history} />
          {/* <div className="right-dash-heading">
            <div onClick={() => props.history.push('/dashboard')}>Dashboard</div>
            <div onClick={() => props.history.push('/admindid')}>Numbers</div>
          </div> */}
          <div className="numbers">
            <div className="numbers-btns">
              <div className="search-btns">
                <input
                  type="text"
                  className="form-control"
                  id="search"
                  placeholder="search"
                  onChange={handleSearchNumber}
                  onKeyPress={(event) => {
                    if (event.key === 'Enter') {
                      searchNumber()
                    }
                  }}
                />
                <Button color="primary" onClick={searchNumber}>
                  Number Search
                </Button>
              </div>

              <Button color="primary" onClick={resyncNumbers}>
                Refresh Numbers
              </Button>
            </div>

            <div>
              <table className="table table-striped mb-0 client-table mt-4">
                <thead>
                  <tr>
                    <th>Number</th>
                    <th>Assigned</th>
                    <th>Features</th>
                    <th>SMS Assignment</th>
                  </tr>
                </thead>
                <tbody>
                  {allPhoneNum &&
                    allPhoneNum.length > 0 &&
                    allPhoneNum.map((number, index) => (
                      <tr key={index}>
                        <td className="td-center">{number.number}</td>
                        <td>
                          <span className="ml-2">
                            {number.ownerType === 'main' && <i className="fas fa-home"></i>}
                            {number.ownerType === 'user' && <i className="fas fa-user"></i>}
                            {number.ownerType === 'group' && <i className="fas fa-users"></i>}
                            {number.ownerType === 'callflows' && <i className="fas fa-random"></i>}
                            {number.ownerType === 'conference' && <i className="fas fa-comments"></i>}
                          </span>
                          <span className="ml-2">
                            {number.ownerType === 'callflows'
                              ? number.callflowName === ''
                                ? number.assignedName
                                : number.callflowName
                              : number.assignedName}
                          </span>
                        </td>
                        <td className="td-center">
                          {number.features_available.includes('e911') ? (
                            number.e911 !== '' ? (
                              <Button color="primary" className="mr-2" size="sm" onClick={() => getE911(number.number)}>
                                <i className="fas fa-ambulance"></i>
                              </Button>
                            ) : (
                              <Button
                                color="secondary"
                                className="mr-2"
                                size="sm"
                                onClick={() => getE911(number.number)}
                              >
                                <i className="fas fa-ambulance"></i>
                              </Button>
                            )
                          ) : (
                            ''
                          )}
                          {number.features_available.includes('cnam') ? (
                            number.cnam && number.cnam.display_name ? (
                              <Button color="primary" size="sm" onClick={() => getCallerID(number.number)}>
                                <i className="fas fa-user"></i>
                              </Button>
                            ) : (
                              <Button color="secondary" size="sm" onClick={() => getCallerID(number.number)}>
                                <i className="fas fa-user"></i>
                              </Button>
                            )
                          ) : (
                            ''
                          )}
                        </td>
                        <td className="td-center">
                          {number.ownerType === 'user' && <span>{number.assignedName}</span>}
                          {number.ownerType === 'callflows' && (
                            <span className="assignment" onClick={() => assignMainNum(number.number)}>
                              {number.assignment}
                            </span>
                          )}
                          {number.ownerType === 'conference' && <span>{number.assignedName}</span>}
                          {number.ownerType === 'main' && (
                            <span className="assignment" onClick={() => assignMainNum(number.number)}>
                              {number.assignment}
                            </span>
                          )}
                          {number.ownerType === 'group' && (
                            <span className="assignment" onClick={() => assignGroupNum(number.groupId, number.number)}>
                              {number.assignment}
                            </span>
                          )}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
              <div className="text-center mt-2">
                {isAdmin === true && (!port_pin || diffDays > 3) && (
                  <Button color="primary" size="lg" onClick={() => portOutPin()}>
                    <i className="fas fa-"></i>Generate port out pin
                  </Button>
                )}
                {isAdmin === true && port_pin && diffDays <= 3 && (
                  <span>
                    <strong> PORT PIN : </strong>
                    {port_pin}
                  </span>
                )}
              </div>
            </div>
          </div>
          <Modal
            isOpen={e911Toogle}
            toggle={e911Modal}
            className={'light-modal modal-dialog modal-dialog-centered modal-dialog-zoom'}
          >
            <ModalHeader toggle={e911Modal}>
              <i className="fas fa-ambulance"></i> 911 Details({selectedNum})
            </ModalHeader>
            <ModalBody>
              <div className="contact">
                <input
                  type="text"
                  className="form-control mb-3"
                  id="street_address"
                  placeholder="Address Line1"
                  value={e911Data.street_address}
                  onChange={changeHandleE911}
                />
                <input
                  type="text"
                  className="form-control mb-3"
                  id="extended_address"
                  placeholder="Address Line2"
                  value={e911Data.extended_address}
                  onChange={changeHandleE911}
                />
                <input
                  type="text"
                  className="form-control mb-3"
                  placeholder="City"
                  value={e911Data.locality}
                  id="locality"
                  onChange={changeHandleE911}
                />
                <input
                  type="text"
                  className="form-control mb-3"
                  placeholder="State"
                  value={e911Data.region}
                  id="region"
                  onChange={changeHandleE911}
                />
                <input
                  type="text"
                  className="form-control mb-3 "
                  placeholder="Zip"
                  value={e911Data.postal_code}
                  id="postal_code"
                  onChange={changeHandleE911}
                />
              </div>
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={e911Modal}>
                Cancel
              </Button>
              {e911Data.postal_code !== '' && (
                <Button color="danger" onClick={removeE911Data}>
                  Remove
                </Button>
              )}

              <Button color="primary" onClick={saveE911Data}>
                Submit
              </Button>
            </ModalFooter>
          </Modal>
          <Modal
            isOpen={callerIDToogle}
            toggle={callerIDModal}
            className={`light-modal modal-dialog modal-dialog-centered modal-dialog-zoom`}
          >
            <ModalHeader toggle={callerIDModal}>
              <i className="fas fa-user"></i> Caller ID({selectedNum})
            </ModalHeader>
            <ModalBody>
              <div className="contact">
                <div className="mb-3">
                  <input
                    type="text"
                    className="form-control"
                    id="displayName"
                    placeholder="Outbound CallerID"
                    value={callerID.displayName}
                    onChange={changeCallName}
                    maxLength="15"
                  />
                  <div className="mt-1">15 character max, may take up to 72 hours to process</div>
                </div>
                <div className="form-item custom-control custom-switch mt-2">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="inboundLookup"
                    checked={callerID.inboundLookup}
                    onChange={changeCallName}
                  />
                  <label className="custom-control-label" htmlFor="inboundLookup">
                    Disable/Enable Inbound Caller ID
                  </label>
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={() => setCallerIDToogle(false)}>
                Cancel
              </Button>
              <Button color="primary" onClick={saveCallerIdData}>
                Submit
              </Button>
            </ModalFooter>
          </Modal>
          <Modal
            isOpen={assignMainNumToogle}
            toggle={assignMainNumModal}
            className={`light-modal modal-dialog modal-dialog-centered modal-dialog-zoom`}
          >
            <ModalHeader toggle={assignMainNumModal}>
              <i className="fas fa-user-edit"></i> SMS Assignment(
              {selectedAssignNum})
            </ModalHeader>
            <ModalBody>
              {allUserNames.map((name, index) => (
                <div className="form-item custom-control custom-switch mt-2" key={index}>
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    checked={name.state}
                    onChange={changeMainAssignState}
                    id={name.userId}
                  />
                  <label className="custom-control-label" htmlFor={name.userId}>
                    {name.userName}
                  </label>
                </div>
              ))}
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={saveMainAssignState}>
                Save
              </Button>
            </ModalFooter>
          </Modal>
          <Modal
            isOpen={assignGroupNumToogle}
            toggle={assignGroupNumModal}
            className={`light-modal modal-dialog modal-dialog-centered modal-dialog-zoom`}
          >
            <ModalHeader toggle={assignGroupNumModal}>
              <i className="fas fa-user-edit"></i> SMS Assignment(
              {selectedAssignNum})
            </ModalHeader>
            <ModalBody>
              {allGroupNames.map((name, index) => (
                <div className="form-item custom-control custom-switch mt-2" key={index}>
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    checked={name.state}
                    onChange={changeGroupAssignState}
                    id={name.userId}
                  />
                  <label className="custom-control-label" htmlFor={name.userId}>
                    {name.userName}
                  </label>
                </div>
              ))}
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={saveGroupAssignState}>
                Save
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      </div>
    </div>
  )
}

export default AdminDID
