import firebase from 'firebase/app'
import 'firebase/messaging'
import 'firebase/storage'
// import firebaseConfigData from './constants/firebaseConfigData.json'

const firebaseConfigData = {
  FIREBASE_API_KEY: 'AIzaSyBRtVyuxnes9JBDhVGpkRVTHbkGUG8smuE',
  FIREBASE_AUTH_DOMAIN: 'venturetel-142716.firebaseapp.com',
  FIREBASE_DB_URL: 'https://venturetel-142716.firebaseio.com',
  FIREBASE_PROJECT_ID: 'venturetel-142716',
  FIREBASE_STORAGE_BUCKET: 'venturetel-142716.appspot.com',
  FIREBASE_MESSAGING_SENDER_ID: '399058483488',
  FIREBASE_APP_ID: '1:399058483488:web:d73869add8da33e0dc3f45',
  FIREBASE_VAPID_KEY: 'BBO1_oxDYgjN8c2afoVMNfFEFwtSuhvgjPgMhbNKD3WU2SSa6eBgx7O7dQB-vOdxHBLYXqJogmsbBp44HtdpqV0',
}

const firebaseConfig = {
  apiKey: firebaseConfigData.FIREBASE_API_KEY,
  authDomain: firebaseConfigData.FIREBASE_AUTH_DOMAIN,
  databaseURL: firebaseConfigData.FIREBASE_DB_URL,
  projectId: firebaseConfigData.FIREBASE_PROJECT_ID,
  storageBucket: firebaseConfigData.FIREBASE_STORAGE_BUCKET,
  messagingSenderId: firebaseConfigData.FIREBASE_MESSAGING_SENDER_ID,
  appId: firebaseConfigData.FIREBASE_APP_ID,
  measurementId: firebaseConfigData.FIREBASE_MEASUREMENT_ID,
}
const app = firebase.initializeApp(firebaseConfig)
export const Storage = app.storage()

export const messaging = firebase.messaging.isSupported() ? firebase.messaging() : null

const REACT_APP_VAPID_KEY = firebaseConfigData.FIREBASE_VAPID_KEY

export const getToken = async () => {
  if (messaging) {
    try {
      const token = await messaging.getToken({
        vapidKey: REACT_APP_VAPID_KEY,
      })
      return token
    } catch (error) {
      console.log('temp', error)
      return null
    }
  }
  return null
}

export const getFirebaseToken = async () => {
  if (messaging) {
    try {
      const getTokenData = await messaging.getToken({
        vapidKey: REACT_APP_VAPID_KEY,
      })
      return getTokenData
    } catch (err) {
      console.log(err, 'somthing went wrongs')
    }
  }
  return null
}
