import InitialsAvatar from 'react-initials-avatar'
import 'react-initials-avatar/lib/ReactInitialsAvatar.css'
import React, { useEffect, useRef, useState } from 'react'
import CONFIG from '../../constants/config'
import { Storage } from '../../firebaseInit'
import { v4 } from 'uuid'
import axios from 'axios'
import { toast } from 'react-toastify'

const ContactImage = ({ name, _id, profileUrl, size = 50 }) => {
  const inputRef = useRef(null)
  const [image, setImage] = useState('')
  const uploadContactAvatar = (e) => {
    const file = e.target.files[0]
    if (!file) {
      return
    }
    const path = `images/${file.name + v4()}`
    Storage.ref(path)
      .put(file)
      .then(() => {
        Storage.ref(path)
          .getDownloadURL()
          .then((url) => {
            axios.post(`${CONFIG.serverURL}/uploadImage`, { _id, url }).then(() => {
              setImage(url)
            })
            toast.success(`Image upload successfully`, {
              position: toast.POSITION.TOP_RIGHT,
            })
          })
      })
  }

  useEffect(() => {
    if (profileUrl) {
      setImage(profileUrl)
    }
  }, [profileUrl])

  return (
    <div onClick={() => inputRef.current.click()}>
      {image ? (
        <img
          src={image}
          height={size}
          width={size}
          style={{ borderRadius: '50%', backgroundColor: 'white', objectFit: 'contain' }}
          alt="profile_uplaod"
        />
      ) : (
        <InitialsAvatar name={name} className="" />
      )}
      <input type="file" accept="image/*" ref={inputRef} onChange={uploadContactAvatar} />
    </div>
  )
}

export default ContactImage
