import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { parsePhoneNumber } from 'libphonenumber-js'
import telIcon from '../../asset/media/svg/telicon-2.2.0.svg'
import NewSms from './NewSms'
import './History.css'
import QuickCall from '../Voicemails/QuickCall'
import AddNotes from './AddNotes'
import { getNotes } from '../../actions/callhistory.action'
import CallRecording from './CallRecording'
import { getPhoneNumber, getDateTime, formatDuration } from '../../lib/utilities'

const HistoryTable = ({ list, perPage, currentPage, filter, handleTotalChange, history }) => {
  const dispatch = useDispatch()

  const [filteredCalls, setFilteredCalls] = useState([])

  const filterCallList = useCallback(
    (callRecords, perPage, currentPage, filter) => {
      let subCallRecords = []
      let totalRecords = []

      if (callRecords && callRecords.length > 0) {
        if (!filter) {
          for (let index = perPage * currentPage; index < perPage * (currentPage + 1); index++) {
            if (callRecords[index]) {
              subCallRecords.push(callRecords[index])
              handleTotalChange(callRecords.length)
            }
          }
        } else {
          callRecords.forEach((call) => {
            if (
              call.dialed_number.indexOf(filter) >= 0 ||
              call.caller_id_number.indexOf(filter) >= 0 ||
              getPhoneNumber(call.callee_id_name.toUpperCase()).indexOf(filter) >= 0 ||
              getPhoneNumber(call.caller_id_name.toUpperCase()).indexOf(filter) >= 0
            ) {
              totalRecords.push(call)
            }
          })

          for (let indexData = perPage * currentPage; indexData < perPage * (currentPage + 1); indexData++) {
            if (totalRecords[indexData]) {
              subCallRecords.push(totalRecords[indexData])
              handleTotalChange(totalRecords.length)
            }
          }
        }
      }

      return subCallRecords
    },
    [handleTotalChange],
  )

  const getNumber = (number) => {
    if (!number.includes('+')) {
      return number.length === 11 || number.length === 10
        ? parsePhoneNumber('+' + (number.length === 11 ? number : '1' + number))
        : number
    } else {
      return number
    }
  }

  useEffect(() => {
    const callRecords = filterCallList(list, perPage, currentPage, filter)
    setFilteredCalls(callRecords)
    //eslint-disable-next-line
  }, [list, perPage, currentPage, filter])

  useEffect(() => {
    const callList = list.length > 0 ? list.map((call) => call.interaction_id) : []
    dispatch(getNotes(callList))
    //eslint-disable-next-line
  }, [list])

  return (
    <div className="call-history-table">
      <div className="row history-table-title">
        <div className="col-md-3">FROM</div>
        <div className="col-md-2">TO</div>
        <div className="col-md-3  action-header">ACTIONS</div>
        <div className="col-md-2">DATE TIME</div>
        <div className="col-md-2">DURATION</div>
      </div>
      {filteredCalls && filteredCalls.length > 0 ? (
        filteredCalls.map((call, index) => (
          <div key={index} className="row call-history-row">
            <div
              className="col-md-3 history-from"
              onClick={() => history.push('/callhistory/legs/' + call.interaction_id)}
            >
              <div className="mr-3 call-icon">
                {call.disposition === 'NO_ANSWER' ? (
                  <svg className="missed-icon">
                    <use href={`${telIcon}#phone-missed`} />
                  </svg>
                ) : (
                  <>
                    {call.authorizing_id ? (
                      <svg className="outcalls-icon">
                        <use href={`${telIcon}#phone-outbound`} />
                      </svg>
                    ) : (
                      <svg className="incalls-icon">
                        <use href={`${telIcon}#phone-inbound`} />
                      </svg>
                    )}
                  </>
                )}
              </div>
              <div>
                <div className="text-left name">{call.caller_id_name}</div>
                <div className="text-left number">{getPhoneNumber(call.caller_id_number)}</div>
              </div>
            </div>
            <div className="col-md-2" onClick={() => history.push('/callhistory/legs/' + call.interaction_id)}>
              <div className="name text-left">{call.callee_id_name}</div>
              <div className="number text-left">{getPhoneNumber(call.dialed_number)}</div>
            </div>
            <div className="col-md-3 action-col">
              <div className="number action-btn text-left">
                {(getPhoneNumber(call.caller_id_number).split(' ')[1]?.split('-').join('').length === 10 ||
                  getPhoneNumber(call.caller_id_number).split(' ')[0] === '+1') && (
                  <NewSms
                    history={history}
                    phoneNumber={getPhoneNumber(call.caller_id_number)}
                    toNumber={call.caller_id_number}
                    fromNumber={getNumber(call.dialed_number)}
                  />
                )}
              </div>
              <div className="number text-left">
                <QuickCall
                  history={history}
                  direction={call.direction}
                  phoneNumber={getPhoneNumber(call.caller_id_number)}
                  fromNumber={call.caller_id_number}
                  toNumber={getNumber(call.dialed_number)}
                />
              </div>
              <div className="number action-btn text-left">
                <AddNotes
                  call={call}
                  fromNumber={call.caller_id_number}
                  toNumber={call.dialed_number}
                  history={history}
                  isOutboundCall={!!call.authorizing_id}
                  interaction_id={call.interaction_id}
                />
              </div>
              <div className="number text-left">
                {call.media_recordings.length > 0 && <CallRecording recording={call.media_recordings[0]} />}
              </div>
            </div>
            <div className="col-md-2" onClick={() => history.push('/callhistory/legs/' + call.interaction_id)}>
              <div className="text-left name">{getDateTime(call.timestamp).date}</div>
              <div className="text-left number">{getDateTime(call.timestamp).time}</div>
            </div>
            <div className="col-md-2" onClick={() => history.push('/callhistory/legs/' + call.interaction_id)}>
              {formatDuration(call.duration_seconds)}
            </div>
          </div>
        ))
      ) : (
        <div className="col-md-12 text-center">
          <h2>No Results!</h2>
        </div>
      )}
    </div>
  )
}

export default HistoryTable
