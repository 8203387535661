import React from 'react'
import { BallBeat } from 'react-pure-loaders'

const Loader = ({ loader }) => {
  return (
    <div>
      <BallBeat color={'#33db24'} loading={loader} />
    </div>
  )
}

export default Loader
