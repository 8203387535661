import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

import {
  getFollowDevices,
  getUserCallForward,
  getUserDirectory,
  saveUserEditData,
  changeUserCredential,
} from '../../actions/management.action'

import { timezone } from '../../constants/const'
import telIcon from '../../asset/media/svg/telicon-2.2.0.svg'

const UserEdit = ({ editModal, changeEditModal, selectUser }) => {
  const dispatch = useDispatch()
  const { userFlowData, userCallforward, userNumbers, userDirectories } = useSelector((state) => state.management)

  const [userFristName, setUserFirstName] = useState('')
  const [userLastName, setUserLastName] = useState('')
  const [notificationEmail, setNotificationEmail] = useState('')
  const [privLevel, setPrivLevel] = useState('')
  const [userTimezone, setUserTimezone] = useState('')
  const [userLanguage, setUserLanguage] = useState('')
  const [callerPrivacy, setCallerPrivacy] = useState('')
  const [userDirectory, setUserDirectory] = useState(false)
  const [extNums, setExtNums] = useState([])
  const [userExtNum, setUserExtNum] = useState('')
  const [isSendEmail, setSendEmail] = useState(false)
  const [timeOut, setTimeOut] = useState('')
  const [credentialMoal, setCredentialModal] = useState(false)
  const [userEmail, setUserEmail] = useState('')
  const [userPass, setUserPass] = useState('')
  const [userConfirmPass, SetUserConfirmPass] = useState('')

  const changeName = (e) => {
    if (e.target.name === 'first_name') {
      setUserFirstName(e.target.value)
    } else setUserLastName(e.target.value)
  }
  const getExtNumbers = (id) => {
    let extNumbers = []
    let nums
    userNumbers.filter((number) => {
      if (number.owner_id && number.owner_id === id) {
        nums = number.numbers
      }
      return true
    })
    if (nums && nums.length > 0) {
      nums.forEach((num) => {
        if (num.length > 2 && num.length < 5) {
          extNumbers.push(num)
        }
      })
    }
    return extNumbers
  }

  const saveUserData = () => {
    const userData = {
      firstName: userFristName,
      lastName: userLastName,
      notificationEmail: notificationEmail,
      directory: userDirectory,
      privLevel: privLevel,
      timezone: userTimezone,
      language: userLanguage,
      mainExtNum: userExtNum,
      privacy: callerPrivacy,
    }
    dispatch(saveUserEditData(userData, selectUser.id))
    changeEditModal()
  }

  const changeCredentialModal = () => {
    setCredentialModal(!credentialMoal)
  }

  const updateUserCredential = () => {
    if (userPass === userConfirmPass && userPass.length > 0) {
      dispatch(changeUserCredential(userEmail, userPass, selectUser.id))
    }
    changeCredentialModal()
  }

  useEffect(() => {
    console.log(selectUser)
    dispatch(getUserCallForward(selectUser.id))
    dispatch(getFollowDevices(selectUser.id))
    dispatch(getUserDirectory())
    setUserFirstName(selectUser.first_name)
    setUserLastName(selectUser.last_name)
    setNotificationEmail(selectUser.email)
    setPrivLevel(selectUser.priv_level)
    setUserExtNum(selectUser.presence_id)
    setExtNums(getExtNumbers(selectUser.id))
    setUserEmail(selectUser.username)
    if (selectUser.email) {
      setSendEmail(true)
    }
    // eslint-disable-next-line
  }, [selectUser])

  useEffect(() => {
    if (Object.keys(userFlowData).length > 0) {
      setTimeOut(userFlowData.timeout)
    }
  }, [userFlowData])
  useEffect(() => {
    if (Object.keys(userCallforward).length > 0) {
      if (userCallforward.timezone) {
        setUserTimezone(userCallforward.timezone)
      }
      if (userCallforward.language) {
        setUserLanguage(userCallforward.language)
      }
      if (userCallforward.caller_id_options) {
        setCallerPrivacy(userCallforward.caller_id_options.outbound_privacy)
      }
      if (userCallforward.directories) {
        userDirectories.forEach((directory) => {
          if (Object.keys(userCallforward.directories).includes(directory.id)) {
            setUserDirectory(true)
          }
        })
      }
    }
    // eslint-disable-next-line
  }, [userCallforward])

  return (
    <>
      <Modal
        size="lg"
        isOpen={editModal}
        toggle={changeEditModal}
        className={`light-modal modal-dialog modal-dialog-centered modal-dialog-zoom`}
      >
        <ModalHeader toggle={changeEditModal}>
          <i className="fas fa-users-cog" /> Edit User
        </ModalHeader>
        <ModalBody>
          <div className="user-edit">
            <div className="row">
              <div className="col-md-6">
                <div className="d-flex align-items-center">
                  <i className="fas fa-user icon" />
                  <input className="form-control mr-3" value={userFristName} onChange={changeName} name="first_name" />
                  <input className="form-control" value={userLastName} onChange={changeName} name="last_name" />
                </div>
                <div className="d-flex align-items-center mt-4">
                  <i className="fas fa-key icon" />
                  <span>{selectUser.username}</span>
                  <div className="text-primary ml-2 change_btn" onClick={() => setCredentialModal(!credentialMoal)}>
                    Change
                  </div>
                </div>
                <div className="d-flex align-items-center mt-4">
                  <div className="form-item custom-control custom-switch">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="alternate"
                      checked={isSendEmail}
                      onChange={() => setSendEmail(!isSendEmail)}
                    />
                    <label className="custom-control-label" htmlFor="alternate">
                      Send emails to an alternate address
                    </label>
                  </div>
                </div>
                {isSendEmail && (
                  <div className="d-flex align-items-center ml-3 mt-3">
                    <i className="fas fa-envelope icon" />
                    <input
                      className="form-control mr-3"
                      name="notification_email"
                      placeholder="Notification Email"
                      value={notificationEmail}
                      onChange={(e) => setNotificationEmail(e.target.value)}
                    />
                  </div>
                )}
                <div className="d-flex align-items-center mt-4">
                  <div className="form-item custom-control custom-switch">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="directory"
                      checked={userDirectory}
                      onChange={() => setUserDirectory(!userDirectory)}
                    />
                    <label className="custom-control-label" htmlFor="directory">
                      Include User in the Company Directory
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="d-flex align-items-center">
                  <i className="fas fa-user-tie icon" />
                  <select className="form-control" value={privLevel} onChange={(e) => setPrivLevel(e.target.value)}>
                    <option value="admin">Admin</option>
                    <option value="user">User</option>
                  </select>
                </div>
                <div className="d-flex align-items-center mt-3">
                  <i className="fas fa-clock icon" />
                  <select
                    className="form-control"
                    value={userTimezone}
                    onChange={(e) => setUserTimezone(e.target.value)}
                  >
                    <option value="">Default</option>
                    {timezone &&
                      timezone.length > 0 &&
                      timezone.map((time, index) => (
                        <option key={index} value={time}>
                          {time}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="d-flex align-items-center mt-3">
                  <i className="fas fa-flag icon" />
                  <select
                    className="form-control"
                    value={userLanguage}
                    onChange={(e) => setUserLanguage(e.target.value)}
                  >
                    <option value="">Default</option>
                    <option value="en-US">English(en-US)</option>
                    <option value="fr-FR">French(fr-FR)</option>
                    <option value="de-DE">German(de-DE)</option>
                    <option value="ru-RU">Russian(ru-RU)</option>
                  </select>
                </div>
                <div className="d-flex align-items-center mt-3">
                  <i className="fas fa-bell icon" />
                  <span className="w-100">Ringing Timeout</span>
                  <input type="text" className="form-control" value={timeOut} readOnly />
                  <span className="ml-2">sec</span>
                </div>
                <div className="d-flex align-items-center mt-3">
                  <svg className="extensionIcon">
                    <use href={`${telIcon}#extension`} />
                    extension
                  </svg>
                  <span className="w-100">Main Extension Number</span>
                  <select className="form-control" value={userExtNum} onChange={(e) => setUserExtNum(e.target.value)}>
                    {extNums &&
                      extNums.length > 0 &&
                      extNums.map((num, index) => (
                        <option key={index} value={num}>
                          {num}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="d-flex align-items-center mt-3">
                  <i className="fas fa-user-secret icon" />
                  <span className="w-100">Caller-ID privacy</span>
                  <select
                    className="form-control"
                    value={callerPrivacy}
                    onChange={(e) => setCallerPrivacy(e.target.value)}
                  >
                    <option value="">Default</option>
                    <option value="none">Hide nothing</option>
                    <option value="number">Hide number only</option>
                    <option value="name">Hide name only</option>
                    <option value="full">Hide name and number</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={saveUserData}>
            Save
          </Button>
        </ModalFooter>
      </Modal>
      <Modal
        size="md"
        isOpen={credentialMoal}
        toggle={changeCredentialModal}
        className={`light-modal modal-dialog modal-dialog-centered modal-dialog-zoom`}
      >
        <ModalHeader toggle={changeCredentialModal}>
          <i className="fas fa-key icon" /> Update Credentials
        </ModalHeader>
        <ModalBody>
          <div>
            <label htmlFor="email">Email Address</label>
            <input
              type="text"
              className="form-control"
              placeholder="Email Address"
              value={userEmail}
              onChange={(e) => setUserEmail(e.target.value)}
            />
          </div>
          <div className="mt-3">
            <label htmlFor="email">Password</label>
            <input
              type="text"
              className="form-control"
              value={userPass}
              placeholder="Password (Min. 6 characters)"
              onChange={(e) => setUserPass(e.target.value)}
            />
          </div>
          <div className="mt-3">
            <label htmlFor="email">Confirm Password</label>
            <input
              type="text"
              className="form-control"
              value={userConfirmPass}
              onChange={(e) => SetUserConfirmPass(e.target.value)}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={updateUserCredential}>
            Update
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default UserEdit
