import axios from 'axios'
import * as CONSTS from '../constants/const'
import CONFIG from '../constants/config'
import { getNomalConversations, getFavoriteConversations } from './message.action'
import { toast } from 'react-toastify'

export const addNewDistribution = (data) => {
  return async (dispatch) => {
    if (data.id === '') {
      await axios.post(`${CONFIG.serverURL}/addnewdistribution`, { data }).then((res) => {
        dispatch(getDistributionList())
        toast.success(`Distribution Added successfully`, {
          position: toast.POSITION.TOP_RIGHT,
        })
      })
    } else {
      await axios.post(`${CONFIG.serverURL}/editdistribution`, { data }).then((res) => {
        dispatch(getDistributionList())
      })
    }
  }
}

export const delDistribution = (id) => {
  return async (dispatch) => {
    await axios.post(`${CONFIG.serverURL}/deletedistribution`, { id }).then((res) => {
      dispatch(getDistributionList())
      toast.error(`Distribution deleted successfully`, {
        position: toast.POSITION.TOP_RIGHT,
      })
    })
  }
}

export const getDistributionList = () => {
  return async (dispatch) => {
    const result = await axios.post(`${CONFIG.serverURL}/getdistribution`)
    if (result.status === 200) {
      dispatch({ type: CONSTS.GET_ALL_DISTRIBUTION_LIST, payload: result.data })
    } else {
      dispatch({ type: CONSTS.GET_ALL_DISTRIBUTION_LIST, payload: [] })
    }
  }
}

export const getAllContacts = () => {
  return async (dispatch) => {
    const userId = localStorage.getItem('user_id')
    await axios
      .post(`${CONFIG.serverURL}/getcontacts`, {
        userId,
      })
      .then((res) => {
        dispatch({ type: CONSTS.GET_ALL_CONTACTS, payload: res.data })
      })
  }
}

export const getAllContactsById = (contact_id) => {
  return (dispatch) => {
    axios
      .post(`${CONFIG.serverURL}/getcontactsById`, { contactId: contact_id })
      .then((res) => {
        dispatch({ type: CONSTS.GET_ALL_CONTACTS_BYID, payload: res.data })
      })
      .catch((error) => {
        console.log('error', error)
      })
  }
}

export const addNewDistributionContact = (data) => {
  return async (dispatch) => {
    const userId = localStorage.getItem('user_id')
    await axios.post(`${CONFIG.serverURL}/adddistributioncontact`, { data }).then((res) => {
      dispatch(getContactList(data.distributionId))
      dispatch(getDistributionContactList(userId))
      toast.success(`Contact Added in distribution successfully`, {
        position: toast.POSITION.TOP_RIGHT,
      })
    })
  }
}

export const editDistributionContact = (id, data) => {
  return async (dispatch) => {
    await axios.post(`${CONFIG.serverURL}/editdistributioncontact`, { id: id, data: data }).then((res) => {
      dispatch(getContactList(data.distributionId))
    })
  }
}
export const deleteDistributionContact = (id, distributionId, fetchContacts = true) => {
  return async (dispatch) => {
    await axios.post(`${CONFIG.serverURL}/deletedistributioncontact`, { id }).then((res) => {
      if (fetchContacts) {
        dispatch(getContactList(distributionId))
      }
    })
  }
}

export const getContactList = (distributionId) => {
  return async (dispatch) => {
    const result = await axios.post(`${CONFIG.serverURL}/getcontactslist`, { distributionId })
    if (result.status === 200) {
      dispatch({ type: CONSTS.GET_ALL_DISTRIBUTION_CONTACTS_LIST, payload: result.data })
    } else {
      dispatch({ type: CONSTS.GET_ALL_DISTRIBUTION_CONTACTS_LIST, payload: [] })
    }
  }
}

export const updateContact = (
  name,
  company,
  email,
  street,
  street2,
  city,
  zip,
  state,
  phoneNum,
  contactNumberList,
  fromNumber,
  privateContact,
  contactID,
  userID,
  distId,
) => {
  return async (dispatch, getState) => {
    const { paginationContactsSettings } = getState().distribution || {}
    const { pageNumber, sortBy, searchKey } = paginationContactsSettings || {}

    const msgData = {
      name: name,
      company: company,
      email: email,
      street: street,
      street2: street2,
      city: city,
      zip: zip,
      state: state,
      phoneNum: phoneNum,
      contactNumberList: contactNumberList,
      contactID: contactID,
      userID: userID,
      distId: distId,
      privateContact: privateContact,
    }
    await axios
      .post(`${CONFIG.serverURL}/updatecontactinformation`, {
        msgData,
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch(getFilterContactsList(pageNumber, sortBy, searchKey))
          dispatch(getAllContacts(userID))
          dispatch(getNomalConversations(fromNumber, userID))
          dispatch(getFavoriteConversations(fromNumber, userID))
          dispatch(getDistributionContactList())
          if (contactID) {
            dispatch(getAllContactsById(contactID))
          }
          toast.success(`Contact updated successfully`, {
            position: toast.POSITION.TOP_RIGHT,
          })
        } else {
          toast.warn(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          })
        }
      })
  }
}

export const deleteContact = (id, cb) => {
  return async (dispatch) => {
    try {
      const ids = Array.isArray(id) ? id : [id]

      await Promise.all(
        ids.map((el) =>
          axios.post(`${CONFIG.serverURL}/deletecontact`, {
            id: el,
          }),
        ),
      )
      dispatch(getDistributionContactList())
      toast.success(`Contact deleted successfully`, {
        position: toast.POSITION.TOP_RIGHT,
      })
    } catch (err) {
      toast.warn(err.message, {
        position: toast.POSITION.TOP_RIGHT,
      })
    }
  }
}

export const syncAllContacts = (userId, accessToken) => {
  return async (dispatch) => {
    await axios
      .post(`${CONFIG.serverURL}/synccontacts`, {
        userId: userId,
        accessToken: accessToken,
      })
      .then((res) => {
        dispatch({ type: CONSTS.SYNC_ALL_CONTACTS, payload: res.data })
      })
  }
}

export const sendDistributionMessage = (
  text,
  uploadImgName,
  fromNumber,
  distributionId,
  distributionCotnactId,
  scheduleData,
) => {
  return async (dispatch, getState) => {
    const account_id = localStorage.getItem('account_id')
    const owner_id = localStorage.getItem('user_id')
    const { distribution, message } = getState()
    const { contactsList } = distribution
    const { userName } = message

    const sendMsg = text + ' --To stop, reply: STOP ' + distributionCotnactId

    const toNumbers = []
    if (contactsList.length > 0) {
      for (let i = 0; i < contactsList.length; i++) {
        if (contactsList[i].status) toNumbers.push(contactsList[i].phoneNumber)
      }
      let data = {
        toNumbers: toNumbers,
        fromNumber: fromNumber,
        text: sendMsg,
        uploadImgName: uploadImgName,
        sender: userName.userEmail,
        accountName: account_id,
        userId: owner_id,
        distributionId: distributionId,
        scheduleData: scheduleData,
      }
      await axios.post(`${CONFIG.serverURL}/senddistributionmessage`, { data }).then((res) => {
        if (res.data === 'OK') {
          dispatch(getDistributionMessage(fromNumber, distributionId))
          dispatch(getNomalConversations(fromNumber, userName.userEmail))
          dispatch(getFavoriteConversations(fromNumber, userName.userEmail))
          toast.success(`SMS sync with redtail successfully`, {
            position: toast.POSITION.TOP_RIGHT,
          })
        }
      })
    }
  }
}

export const getDistributionMessage = (fromNumber, distributionId) => {
  return async (dispatch) => {
    const msgData = {
      from_number: fromNumber,
      distributionId: distributionId,
    }
    await axios
      .post(`${CONFIG.serverURL}/getdistributionmessages`, {
        msgData,
      })
      .then((res) => {
        dispatch({ type: CONSTS.GET_ALL_MESSAGES, payload: res.data })
        dispatch({
          type: CONSTS.SMS_NOTIFICATION,
          payload: { state: false, fromNumber: '' },
        })
      })
  }
}

export const getDistributionContactList = () => {
  const userId = localStorage.getItem('user_id')
  return async (dispatch) => {
    try {
      const result = await axios.post(`${CONFIG.serverURL}/getdistributioncontacts`, { userID: userId })
      if (result.status === 200) {
        dispatch({ type: CONSTS.GET_ALL_DISTRIBUTION_CONTACTS, payload: result.data })
      } else {
        dispatch({ type: CONSTS.GET_ALL_DISTRIBUTION_CONTACTS, payload: [] })
      }
    } catch (error) {
    } finally {
      dispatch({ type: CONSTS.GET_LOADING_DISTRIBUTION_CONTACTS, payload: false })
    }
  }
}

export const getFilterContactsList = (pageNumber, sortBy, searchKey) => {
  const userId = localStorage.getItem('user_id')
  return async (dispatch) => {
    try {
      dispatch({ type: CONSTS.GET_LOADING_DISTRIBUTION_CONTACTS, payload: true })
      const result = await axios.post(`${CONFIG.serverURL}/getfiltercontactslist`, {
        userID: userId,
        pageNumber,
        sortBy,
        searchKey,
      })
      if (result.status === 200) {
        dispatch({ type: CONSTS.GET_PAGINATION_DISTRIBUTION_CONTACTS, payload: result.data })
      } else {
        dispatch({ type: CONSTS.GET_PAGINATION_DISTRIBUTION_CONTACTS, payload: { lists: [], totalCounts: 0 } })
      }
    } catch (error) {
      dispatch({ type: CONSTS.GET_PAGINATION_DISTRIBUTION_CONTACTS, payload: { lists: [], totalCounts: 0 } })
    } finally {
      dispatch({ type: CONSTS.GET_LOADING_DISTRIBUTION_CONTACTS, payload: false })
    }
  }
}
