import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { getAllUsers } from '../../actions/dashboard.action'

function filterUser(users, number) {
  if (!number) {
    return users
  }
  return (users || []).filter((el) => (el.presence_id || '').includes(number))
}

const CallTransfer = ({ isOpen, onClose, onTransfer }) => {
  const users = useSelector((state) => state.dashboard.users)
  const [selectedId, setSelectedId] = useState('')
  const [search, setSearch] = useState('')
  const filteredUsers = useMemo(() => filterUser(users, search), [users, search])

  useEffect(() => {
    getAllUsers()
  }, [])

  const handleTransferCall = useCallback(() => {
    onTransfer(selectedId)
    onClose();
  }, [onClose, selectedId, onTransfer])

  return (
    <Modal backdrop={false} isOpen={isOpen} toggle={onClose}>
      <ModalHeader>Call Transfer</ModalHeader>
      <ModalBody className="assign-body">
        <div>
          <input
            style={{ width: '400px' }}
            type="text"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder=
            "Find Extension Number......"
          />
          <div className="tab-content">
            <div className="tab-pane show active" id="account" role="tabpanel">
              {filteredUsers.map((el) => (
                <div className="form-item custom-control custom-switch" key={el.id}>
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id={el.id}
                    value={el.presence_id === selectedId}
                    checked={el.presence_id === selectedId}
                    onChange={() => setSelectedId(el.presence_id)}
                  />
                  <label className="custom-control-label" htmlFor={el.id}>
                    <span>
                      {el.first_name} {el.last_name} ({el.presence_id})
                    </span>
                  </label>
                </div>
              ))}
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter className="assign-footer">
        <Button className="assign-footer-btn cancel-btn" onClick={onClose}>
          Cancel
        </Button>
        <Button color="primary" className="cancel-btn" onClick={handleTransferCall} disabled={!selectedId}>
          Transfer
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default CallTransfer
