import axios from 'axios'
import CONFIG from '../constants/config'
import { toast } from 'react-toastify'
import * as CONSTS from '../constants/const'

export const getBrandList = ({ page, recordsPerPage }, onComplete) => {
  return async (dispatch) => {
    try {
      const res = await axios.get(`${CONFIG.serverURL}/get-brand-list?recordsPerPage=${recordsPerPage}&page=${page}`)
      dispatch({ type: CONSTS.GET_BRAND_LIST, payload: res.data })
    } catch (err) {
    } finally {
      onComplete()
    }
  }
}

export const getCampaignList = ({ page, recordsPerPage }, onComplete) => {
  return async (dispatch) => {
    console.log('your reselller data')
    try {
      const res = await axios.get(`${CONFIG.serverURL}/get-campaign-list?recordsPerPage=${recordsPerPage}&page=${page}`)
      dispatch({ type: CONSTS.GET_CAMPAIGN_LIST, payload: res.data })
    } catch (err) {
    } finally {
      onComplete()
    }
  }
}

export const getVerticalList = () => {
  return async (dispatch) => {
    await axios.get(`${CONFIG.serverURL}/get-vertical-list`).then((res) => {
      dispatch({ type: CONSTS.GET_VERTICAL_LIST, payload: res.data })
    })
  }
}

export const getResponsiblePartiesList = () => {
  return async (dispatch) => {
    await axios.get(`${CONFIG.serverURL}/responsible-parties`).then((res) => {
      dispatch({ type: CONSTS.GET_RESPONSIBLE_PARTIES, payload: res.data })
    })
  }
}

export const AddbrandDetalisAction = (payload) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${CONFIG.serverURL}/create-brand`, payload)

      if (response?.data && Array.isArray(response?.data)) {
        throw new Error(response?.data?.[0]?.description)
      }
      toast.success(`Brand Added successfully`, {
        position: toast.POSITION.TOP_RIGHT,
      })
      return true;
    } catch (err) {
      toast.error(err?.response?.data?.message || err?.message || 'Something went wrong...', {
        position: toast.POSITION.TOP_RIGHT,
      })
      return false;
    }
  }
}

export const getCountryList = () => {
  return async (dispatch) => {
    await axios.get(`${CONFIG.serverURL}/get-country-list`).then((res) => {
      dispatch({ type: CONSTS.GET_COUNTRY_LIST, payload: res.data })
    })
  }
}

export const getBrandRelationList = () => {
  return async (dispatch) => {
    await axios.get(`${CONFIG.serverURL}/get-brand-relation`).then((res) => {
      dispatch({ type: CONSTS.GET_BRAND_RELATION_LIST, payload: res.data })
    })
  }
}

export const getUseCaseTypeList = () => {
  return async (dispatch) => {
    await axios.get(`${CONFIG.serverURL}/get-usecase-type`).then((res) => {
      dispatch({ type: CONSTS.GET_USECASE_TYPE_LIST, payload: res.data })
    })
  }
}

export const getSpecialTypeList = () => {
  return async (dispatch) => {
    await axios.get(`${CONFIG.serverURL}/get-special-type`).then((res) => {
      dispatch({ type: CONSTS.GET_USECASE_TYPE_LIST, payload: res.data })
    })
  }
}

export const getResellerList = () => {
  return async (dispatch) => {
    try {
      const res = await axios.get(`${CONFIG.serverURL}/campaign-registry/reseller`)
      dispatch({ type: CONSTS.GET_RESELLER_LIST, payload: res.data?.records ?? [] })
    } catch (err) {
    } finally {
    }
  }
}

export const AddResellerDetalisAction = (payload) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${CONFIG.serverURL}/create-reseller`, payload)

      if (response?.data && Array.isArray(response?.data)) {
        throw new Error(response?.data?.[0]?.description)
      }
      toast.success(`Brand Added successfully`, {
        position: toast.POSITION.TOP_RIGHT,
      })
      return true;
    } catch (err) {
      toast.error(err?.response?.data?.message || err?.message || 'Something went wrong...', {
        position: toast.POSITION.TOP_RIGHT,
      })
      return false;
    }
  }
}

export const AddMutipleImageAction = (payload, campaignId) => {
  return async () => {
    await axios.post(`${CONFIG.serverURL}/uplaod-campaing-image/123456`, payload).then((res) => {
      if (res.status === 200) {
        toast.success(`Image  Uplaod Successfully.`, {
          position: toast.POSITION.TOP_RIGHT,
        })
      } else {
        toast.warn(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        })
      }
    })
  }
}


