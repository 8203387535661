import React from 'react'
import { Tooltip, Card, CardBody, Badge } from 'reactstrap'
import { phoneNumFormat, getMemberName, getCompanyName, truncateString } from '../../lib/utilities'
import { useSelector } from 'react-redux'

import DropMenu from './DropMenu'
import './message.css'

const UnreadConversations = (props) => {
  const {
    unreadMembers,
    isContactInformation,
    setMemberIformation,
    setMemNumber,
    userNumber,
    checkAssignConversation,
    checkNotifies,
    isToolTipOpen,
    toggleTooltip4,
    deleteFavorite,
    addFavorite,
    assignConversationShowPopup,
    checkAssignConversationLength,
    adminPhoneNum,
    deleteHistory,
    isContactDialog,
  } = props
  const { userName, favoriteConversations, normalConversations } = useSelector((state) => state.message)

  return (
    <ul className="list-group list-group-flush">
      {unreadMembers &&
        unreadMembers &&
        unreadMembers.map((member, i) => {
          const memberName = getMemberName(member)
          const companyName = getCompanyName(member)
          return (
            <Card key={i} className="chat-name">
              <CardBody>
                <li
                  key={i}
                  onClick={() => {
                    isContactInformation(false, member)
                    setMemberIformation(member)
                    setMemNumber(member.memberNum)
                  }}
                  className={`list-group-item ${member.memberNum === userNumber ? 'open-chat' : ''}  ${
                    checkAssignConversation(member.memberNum) && !checkNotifies(member.memberNum)
                      ? 'list-chat'
                      : !checkNotifies(member.memberNum) || checkAssignConversation(member.memberNum)
                      ? 'list-count-chat'
                      : ''
                  }`}
                >
                  <figure className="avatar">
                    <img src={member.silhoutte} className="avatar" alt="avatar" />
                  </figure>
                  <div className="users-list-body">
                    <span onClick={() => setMemNumber(member.memberNum)}>
                      {member?.contacts.length === 0 ? (
                        <h5>{phoneNumFormat(member.memberNum)}</h5>
                      ) : (
                        <span>
                          <div className="chat-labelName">
                            <h5>{truncateString(memberName, 14)}</h5>
                            <h5 id={`numbercount-${i}`}>
                              {member?.contacts.length > 1 && `or  ${member?.contacts.length - 1} other`}{' '}
                            </h5>
                            <Tooltip
                              placement="bottom"
                              isOpen={isToolTipOpen(`numbercount-${i}`)}
                              autohide={false}
                              target={`numbercount-${i}`}
                              toggle={() => toggleTooltip4(`numbercount-${i}`)}
                            >
                              {member?.contacts.map((data, i) => (
                                <div key={i}>{data.labelName !== memberName && (data.labelName || data.company)} </div>
                              ))}
                            </Tooltip>
                          </div>
                          <h6>{companyName}</h6>
                          <h6>{phoneNumFormat(member.memberNum)}</h6>
                        </span>
                      )}
                      {member.isFavorite ? (
                        <i
                          onClick={() => deleteFavorite(member.memberNum)}
                          className="far fa-star chat-favorite-icon"
                        ></i>
                      ) : (
                        <i
                          onClick={() => addFavorite(member.memberNum)}
                          className="far fa-star chat-important-icon"
                        ></i>
                      )}
                      {checkAssignConversation(member.memberNum) > 0 && (
                        <Badge className="assign-badge" onClick={() => assignConversationShowPopup(member)} pill>
                          Assigned to {checkAssignConversationLength(member.memberNum)}{' '}
                          {`${checkAssignConversationLength(member.memberNum) === 1 ? 'person' : 'people'}`}
                        </Badge>
                      )}
                      {normalConversations.notifies &&
                        normalConversations.notifies.map((notify, i) => {
                          if (notify.number === member.memberNum) {
                            return (
                              <div key={i} className="users-list-action">
                                <div className="new-message-count">{notify.newMsg}</div>
                              </div>
                            )
                          }
                          return null
                        })}
                    </span>
                  </div>
                </li>
                <div className="users-list-action">
                  <div className="action-toggle">
                    <DropMenu
                      tab="favTab4"
                      toNumber={member.memberNum}
                      readConversation={member.readConversation}
                      fromNumber={adminPhoneNum}
                      email={userName.userEmail}
                      deleteHistory={deleteHistory}
                      contactID={member.contactID}
                      labelName={memberName}
                      favoriteMem={favoriteConversations.favConversationsList}
                      member={member}
                      isContactDialog={isContactDialog}
                    />
                  </div>
                </div>
              </CardBody>
            </Card>
          )
        })}
    </ul>
  )
}

export default UnreadConversations
