import React, { useState } from 'react'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Modal,
  Input,
  ModalFooter,
} from 'reactstrap'
import { getUseCaseTypeList, getSpecialTypeList } from '../../actions/campaign.action'
import './campaignmanagement.css'
import './campaignmanagement.css'
import { useHistory } from 'react-router'

const CreateCampaign = () => {
  const [openCampaignModel] = useState(false)
  const dispatch = useDispatch()
  let history = useHistory()


  const toggleCampaignModel = () => {
    history.push(`/campaign-create`)
  }

  useEffect(() => {
    dispatch(getUseCaseTypeList())
    dispatch(getSpecialTypeList())
  }, [dispatch])

    const CampaignDetailsSection = ({ onBack }) => {
      return (
        <form>
          <CardHeader>
            <p className="title-of-form">Campaign Details</p>
          </CardHeader>
          <CardBody>
            <div className="wrapper-preview">
              <div className="heading-block"></div>
              <div>
                <p>Brand</p>
                <Input placeholder="Brand Name" />
              </div>
            </div>
            <div className="wrapper-preview">
              <div className="heading-block"></div>
              <div>
                <p>Campaign Details</p>
                <form>
                  <div className="row">
                    <div className="col-6 ">
                      <Input
                        type="text"
                        bsSize="lg"
                        placeholder="Use Case"
                      />
                    </div>
                    <div className="col-6 ">
                      <textarea
                        type="text"
                        bsSize="lg"
                        placeholder="Campaign Description*"
                        className="form-control"
                      />
                    </div>
                    <div className="col-6 ">
                      <textarea
                        type="text"
                        bsSize="lg"
                        placeholder="Call-to-Action / Message Flow*"
                        className="form-control"
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </CardBody>
          <ModalFooter style={{ gap: 8, display: 'flex' }}>
            <Button type="button" className="back-color" onClick={onBack}>
              Back
            </Button>
            <Button type="submit" color="primary">
              Next
            </Button>
          </ModalFooter>
        </form>
      )
    }
  return (
    <div>
      <Button onClick={toggleCampaignModel} style={{ backgroundColor: ' #30b365' }}>
        + Create New Campaign
      </Button>
      <Modal isOpen={openCampaignModel} toggle={toggleCampaignModel} size="lg" centered>
        <Card>
          <CampaignDetailsSection />
        </Card>
      </Modal>
    </div>
  )
}
export default CreateCampaign
