import * as CONSTS from '../constants/const'

let defaultState = {
  allData: [],
  preFlow: {},
  saveFlow: {},
  allflows: [],
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case CONSTS.GET_AUTOMATIONS:
      return { ...state, allData: action.payload }
    case CONSTS.GET_PREFLOW:
      return { ...state, preFlow: action.payload, saveFlow: {} }
    case CONSTS.SAVE_CALL_FLOW:
      return { ...state, saveFlow: action.payload }
    case CONSTS.GET_CALL_FLOWS:
      return { ...state, allflows: action.payload }
    default:
      return state
  }
}
