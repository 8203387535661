import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Button, ModalFooter, Spinner, UncontrolledTooltip } from 'reactstrap'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import CONFIG from '../../../constants/config.json'

function groupUsecaseData(data) {
  const output = Object.entries(data ?? {}).reduce(
    (prev, [key, curr]) => {
      if (prev[curr.classification]) {
        prev[curr.classification].push({ ...curr, key })
      }
      return prev
    },
    { STANDARD: [], SPECIAL: [] },
  )

  return [
    {
      label: 'Standard Campaign Type',
      data: output.STANDARD,
    },
    {
      label: 'Special Campaign Type',
      data: output.SPECIAL,
    },
  ]
}

function groupBrandUsecases(data) {
  return data.reduce((prev, curr) => {
    prev[curr.usecase] = {
      ...curr,
      disabled: !Object.values(curr?.mnoMetadata || {}).some((el) => el.qualify),
    }
    return prev
  }, {})
}

async function getBrandListing() {
  try {
    const response = await axios.get(`${CONFIG.serverURL}/get-brand-list?recordsPerPage=500&page=1`)
    return response?.data?.records || []
  } catch (err) {
    return []
  }
}
async function getUseCaseListing() {
  try {
    const response = await axios.get(`${CONFIG.serverURL}/campaign-registry/enum/usecase`)
    return groupUsecaseData(response.data ?? {})
  } catch (err) {
    return groupUsecaseData({})
  }
}

async function getBrandUsecases(brandId) {
  try {
    const response = await axios.get(`${CONFIG.serverURL}/campaign-registry/brand/${brandId}`)
    return groupBrandUsecases(response.data ?? [])
  } catch (err) {
    console.log(err)
  }
}

const UsecaseSelectionSection = React.memo(({ onSubmit }) => {
  const [form, setForm] = useState({ brand: '', usecase: null })
  const isFormValid = useMemo(() => form.brand && form.usecase, [form.brand, form.usecase])
  const router = useHistory()
  const [brands, setBrands] = useState([])
  const [usecases, setUseCases] = useState([])
  const [brandUsecases, setBrandUsecases] = useState({})
  const [isLoading, setLoading] = useState(false)

  const combinedUsecases = useMemo(
    () =>
      usecases.reduce((prev, curr) => {
        prev.push(...curr.data)
        return prev
      }, []),
    [usecases],
  )
  const selectedBrand = useMemo(() => brands.find((el) => el.brandId === form.brand), [brands, form.brand])
  const selectedUsecase = useMemo(
    () => combinedUsecases.find((el) => el.key === form.usecase),
    [combinedUsecases, form.usecase],
  )

  useEffect(() => {
    getBrandListing().then(setBrands)
    getUseCaseListing().then(setUseCases)
  }, [])

  useEffect(() => {
    if (selectedBrand?.brandId) {
      setLoading(true)
      getBrandUsecases(selectedBrand?.brandId)
        .then(setBrandUsecases)
        .then(() => setLoading(false))
    }
  }, [selectedBrand])

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault()
      onSubmit({
        brand: selectedBrand,
        usecase: { ...selectedUsecase, ...brandUsecases[selectedUsecase.key] },
      })
    },
    [selectedBrand, selectedUsecase, onSubmit, brandUsecases],
  )

  const handleBrandChange = useCallback((e) => {
    e.persist()
    setForm((prev) => ({ ...prev, brand: e.target.value }))
  }, [])

  const handleUseCaseChange = useCallback((id) => {
    setForm((prev) => ({ ...prev, usecase: id }))
  }, [])

  const handleBack = useCallback(() => {
    router.goBack()
  }, [router])

  return (
    <form onSubmit={handleSubmit} className="form-rapper">
      <div className="use-start-case">
        <p className="title-of-form">Use Case Selection</p>
      </div>
      <div className="title-of-form" style={{ width: '300px', paddingBottom: '10px' }}>
        <p className="title-of-form">Select Brand</p>
        <select
          name="entityType"
          id="entityType"
          value={form.brand}
          className="form-control"
          onChange={handleBrandChange}
        >
          <option value="">Select Brand</option>
          {brands.map((el) => (
            <option value={el.brandId} key={el.brandId}>
              {el.displayName}
            </option>
          ))}
        </select>
      </div>
      <div className="wrapper-preview position-relative ">
        {(!form.brand || isLoading) && (
          <div
            style={{ position: 'absolute', inset: 0, backdropFilter: 'blur(1px)', zIndex: 10 }}
            className="d-flex align-items-center justify-content-center"
          >
            {isLoading && <Spinner animation="grow" variant="primary" />}
          </div>
        )}
        <h6>Select a Use-Case (Please hover over use case names for a description)</h6>
        <div className="row">
          {usecases.map((section, index) => (
            <div className="col-6" key={index}>
              <div className="wrapper-preview">
                <p style={{ fontSize: '20px', marginLeft: '22%' }}>{section.label}</p>

                <div>
                  <table>
                    <thead>
                      <tr style={{ backgroundColor: '#3db16b' }}>
                        <th scope="col" style={{ width: '8%', textAlign: 'center' }}></th>
                        <th scope="col" style={{ width: '50%', padding: '8px', color: 'white' }}>
                          USE CASE
                        </th>
                        <th scope="col" style={{ width: '60%', textAlign: 'center', padding: '8px', color: 'white' }}>
                          TCR MONTHLY FEE
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {section.data.map((usecase) => (
                        <tr
                          key={usecase.key}
                          onClick={() => !brandUsecases[usecase?.key]?.disabled && handleUseCaseChange(usecase.key)}
                          style={{
                            opacity: brandUsecases[usecase?.key]?.disabled ? 0.4 : 1,
                          }}
                        >
                          <td className="td-center">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              checked={form.usecase === usecase.key}
                            />
                            <div className="form-check"></div>
                          </td>
                          <td style={{ padding: '10px', width: '50%' }} className="td-center">
                            <span id={`USECASE_${usecase.key}`}>{usecase.displayName}</span>
                            <UncontrolledTooltip target={`USECASE_${usecase.key}`} placement="bottom" ve>
                              {usecase.description}
                            </UncontrolledTooltip>
                          </td>
                          <td className="td-center pointer td-data">
                            $
                            {brandUsecases[usecase?.key]?.monthlyFee
                              ? brandUsecases[usecase?.key]?.monthlyFee * 1.5
                              : ''}
                            .-
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <ModalFooter style={{ gap: 8, display: 'flex' }}>
        <Button type="button" style={{ backgroundColor: 'green', color: 'white' }} onClick={handleBack}>
          Back
        </Button>
        <Button type="submit" color="primary" disabled={!isFormValid}>
          Next
        </Button>
      </ModalFooter>
    </form>
  )
})

export default UsecaseSelectionSection
