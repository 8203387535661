import axios from 'axios'
import md5 from 'md5'
import CONFIG from '../constants/config'
import * as CONSTS from '../constants/const'

axios.defaults.headers.get['Content-Type'] = 'application/json'

export const getNewAuthToken = (username, password, accountname) => {
  return (dispatch) => {
    const URI = `${CONFIG.API_URL}/user_auth`
    const body = {
      data: {
        credentials: md5(`${username}:${password}`),
        account_name: accountname,
      },
    }
    axios
      .put(URI, body)
      .then((res) => {
        dispatch({ type: CONSTS.GET_NEW_TOKEN_SUCCESS, payload: res.data })
      })
      .catch((error) => {
        dispatch({ type: CONSTS.FAIL_AUTH_REQUEST, payload: error })
        dispatch(logOut())
      })
  }
}

export const resetInfo = (username, accountname, phone) => {
  return (dispatch) => {
    const URI = `${CONFIG.API_URL}/user_auth/recovery`
    let body = {}
    if (phone && !accountname) {
      body = {
        data: {
          username: username,
          phone_number: phone,
          ui_url: `${CONFIG.APP_URL}/reset-password`,
        },
      }
    } else if (accountname && !phone) {
      body = {
        data: {
          username: username,
          account_name: accountname,
          ui_url: `${CONFIG.APP_URL}/reset-password`,
        },
      }
    } else {
      body = {
        data: {
          username: username,
          phone_number: phone,
          account_name: accountname,
          ui_url: `${CONFIG.APP_URL}/reset-password`,
        },
      }
    }

    axios
      .put(URI, body)
      .then((res) => {
        dispatch({ type: CONSTS.FORGOT_PASSWORD_SUCCESS, payload: res.data })
      })
      .catch((error) => {
        dispatch({ type: CONSTS.FORGOT_PASSWORD_FAIL, payload: error })
      })
  }
}

export const changePassword = (data) => {
  return (dispatch) => {
    const { authToken, accountId, userId, password } = data
    const URI = `${CONFIG.API_URL}/accounts/${accountId}/users/${userId}`
    axios.defaults.headers.common['X-AUTH-TOKEN'] = authToken
    axios.get(URI).then((res) => {
      const body = res.data.data
      body.password = password
      body.require_password_update = false
      axios
        .post(URI, { data: body })
        .then((res) => {
          dispatch({ type: CONSTS.FORGOT_PASSWORD_SUCCESS, payload: res.data })
        })
        .catch((error) => {
          dispatch({ type: CONSTS.FORGOT_PASSWORD_FAIL, payload: error })
        })
    })
  }
}

export const resetPassword = (recovery) => {
  return (dispatch) => {
    const URI = `${CONFIG.API_URL}/user_auth/recovery`
    const body = {
      data: {
        reset_id: recovery,
      },
    }
    axios
      .post(URI, body)
      .then((res) => {
        dispatch({ type: CONSTS.RESET_PASSWORD_SUCCESS, payload: res.data })
      })
      .catch((error) => {
        dispatch({ type: CONSTS.RESET_PASSWORD_FAIL, payload: error })
      })
  }
}
export const getUser = () => {
  return (dispatch) => {
    const account_id = localStorage.getItem('account_id')
    const owner_id = localStorage.getItem('user_id')
    const auth_token = localStorage.getItem('token')
    axios.defaults.headers.common['X-AUTH-TOKEN'] = auth_token

    const URI = `${CONFIG.API_URL}/accounts/${account_id}/users/${owner_id}`
    axios
      .get(URI)
      .then((res) => {
        localStorage.setItem('user_data', JSON.parse(res.data))
        dispatch({ type: CONSTS.GET_NEW_TOKEN_SUCCESS, payload: res.data })
      })
      .catch((error) => {
        dispatch({ type: CONSTS.FAIL_AUTH_REQUEST, payload: error })
        dispatch(logOut())
      })
  }
}

export const logOut = () => {
  return (dispatch) => {
    dispatch({ type: CONSTS.RESET_AUTH_TOKEN })
    localStorage.removeItem('token')
    localStorage.removeItem('account_id')
    localStorage.removeItem('user_id')
    localStorage.removeItem('email')
  }
}
