import * as CONSTS from '../constants/const';

let defaultState = {
  CampaignManagement: [],
  Country: {},
  BrandList: {},
  Vertical: {},
  brandRelation: [],
  campaignList: {},
  useCaseType: {},
  specialType:{},
  resellerList: [],
  imageStored:{},
  responsibleParties:{}
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case CONSTS.CAMPAIGN_MANAGEMENT:
      return { ...state, CampaignManagement: action.payload }
    case CONSTS.GET_COUNTRY_LIST:
      return { ...state, Country: action.payload }
    case CONSTS.GET_BRAND_LIST:
      return { ...state, BrandList: action.payload }
    case CONSTS.GET_VERTICAL_LIST:
      return { ...state, Vertical: action.payload }
    case CONSTS.GET_BRAND_RELATION_LIST:
      return { ...state, brandRelation: action.payload }
    case CONSTS.GET_CAMPAIGN_LIST:
      return { ...state, campaignList: action.payload }
    case CONSTS.GET_USECASE_TYPE_LIST:
      return { ...state, useCaseType: action.payload }
    case CONSTS.GET_SPECIAL_TYPE_LIST:
      return { ...state, specialType: action.payload }
    case CONSTS.GET_RESELLER_LIST:
      return { ...state, resellerList: action.payload }
    case CONSTS.POST_IMAGE_UPLAOD:
      return { ...state, imageStored: action.payload }
    case CONSTS.GET_RESPONSIBLE_PARTIES:
      return { ...state, responsibleParties: action.payload }
    default:
      return state
  }
}
