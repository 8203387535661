import React, { useState } from 'react'
import { Tooltip } from 'reactstrap'

const CustomTooltip = ({ children, content, target }) => {
  const [open, setOpen] = useState(false)
  const toggleTooltip = () => setOpen((prev) => !prev)
  return (
    <>
      <Tooltip isOpen={open} toggle={toggleTooltip} placement="bottom" target={target}>
        {content}
      </Tooltip>
      {children}
    </>
  )
}

export default CustomTooltip
