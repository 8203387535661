import React, { useState, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Badge } from 'reactstrap'
import { ToastContainer } from 'react-toastify'
import ReactPaginate from 'react-paginate'
import { useHistory } from 'react-router-dom'
import CSVReader from 'react-csv-reader'

import * as CONSTS from '../../constants/const'
import Sidebar from '../Sidebar/Sidebar'
import Dialog from '../Sidebar/Dialog'
import ContactDetailsSave from '../Home/ContactDetailsSave'
import { getCallForward } from '../../actions/message.action'
import {
  getAllContacts,
  getFilterContactsList,
  getDistributionList,
  updateContact,
} from '../../actions/distribution.action'
import QuickCall from '../Voicemails/QuickCall'
import UserHeader from '../Header/UserHeader'
import ContactDelete from '../Home/ContactDelete'
import DistributionMenu from './DistributionMenu'
import FilterMenu from './FilterMenu'
import NewSms from '../CallHistory/NewSms'
import ContactImage from './ContactImage'
import CustomTooltip from './CustomTooltip'
import { phoneNumberFormat } from '../../lib/utilities'
import './contacts.css'

function filteredContacts(allPhoneNum, { data, filter }) {
  const output = (filter ? data : allPhoneNum).map((el) => ({
    ...el,
    name: el.labelName || el.name,
    formattedNumber: phoneNumberFormat(el.phoneNumber),
  }))
  return output
}

const Contacts = (props) => {
  const dispatch = useDispatch()
  const { isAdmin, savedNumber } = useSelector((state) => state.message.numbers)
  const { contacts } = useSelector((state) => state.message)
  const { loading, allList, paginationContactsList } = useSelector((state) => state.distribution)
  const { lists, totalCounts } = paginationContactsList

  const [contactToogle, updateContactUs] = useState(false)
  const [setNumberToogle, updateSetNumber] = useState(false)
  const [allPhoneNum, setAllPhoneNum] = useState([])
  const [selectedKey, setSelectedKey] = useState('')
  const [showDeleteDialog, setshowDeleteDialog] = useState(false)
  const [showContactDialog, setshowContactDialog] = useState(false)
  const [memberInformation, setMemberIformation] = useState({})
  const [allContactId, setAllContactId] = useState([])
  const [filter, setFilter] = useState({ data: [], filter: false })
  const [sortBy, setSortBy] = useState('labelName-asc')
  const [pageNumber, setPageNumber] = useState(0)
  const [searchKey, setSearchKey] = useState('')
  const [totalPages, setTotalPages] = useState(0)

  const userId = localStorage.getItem('user_id')
  const filteredPhoneNum = useMemo(() => filteredContacts(allPhoneNum, filter), [allPhoneNum, filter])
  // let contactID = contacts.contactID ? contacts.contactID : contacts._id
  let history = useHistory()

  const contactUsModal = () => {
    updateContactUs(!contactToogle)
  }
  const changeSetNumberModal = () => {
    if (!setNumberToogle) {
      dispatch(getCallForward())
    }
    updateSetNumber(!setNumberToogle)
  }
  const handleSearchContact = (e) => {
    setSelectedKey(e.target.value)
  }

  const searchContact = () => {
    setSearchKey(selectedKey)
  }

  const isContactDialog = (val, contact) => {
    setshowContactDialog(val)
    if (contact) {
      setMemberIformation(contact)
    }
  }

  const toggleContactDialog = (val) => {
    setshowContactDialog(val)
  }

  useEffect(() => {
    setAllPhoneNum(lists)
    lists?.length &&
      lists.map((val) => {
        if (!allContactId.includes(val._id)) {
          const list = allContactId.filter((data) => data === val._id)
          setAllContactId(list)
        }
        return null
      })
    // eslint-disable-next-line
  }, [lists])

  useEffect(() => {
    if (!isAdmin) {
      props.history.push('/home')
    }
    //eslint-disable-next-line
  }, [isAdmin])

  useEffect(() => {
    dispatch(getAllContacts())
    dispatch(getDistributionList())
    //eslint-disable-next-line
  }, [userId])

  useEffect(() => {
    const settingsData = { pageNumber: pageNumber, sortBy: sortBy, searchKey: searchKey }
    dispatch({ type: CONSTS.GET_PAGINATION_DISTRIBUTION_CONTACTS_SETTINGS, payload: settingsData })

    getContactsList()
    //eslint-disable-next-line
  }, [pageNumber, sortBy, searchKey])

  useEffect(() => {
    if (totalCounts % 10 !== 0) {
      setTotalPages(totalCounts / 10)
    } else {
      setTotalPages(totalCounts / 10 - 1)
    }
    //eslint-disable-next-line
  }, [totalCounts])

  const phoneNumFormat = (number) => {
    if (number) {
      const phoneNumber = number.toString().replace(/[^+\d]+/g, '')
      return phoneNumber
    } else {
      return number
    }
  }

  const getContactsList = () => {
    dispatch(getFilterContactsList(pageNumber, sortBy, searchKey))
  }

  const handleForce = (data, fileInfo) => {
    // setCsvContacts(data)
    let numberList
    let contactNumber
    data &&
      data.length > 0 &&
      data.map((number) => {
        if (number.group_membership) {
          if (!number.phone_1___value) {
            contactNumber = number.phone_1___value
          } else {
            if (number.phone_1___value) {
              if (typeof number.phone_1___value == 'string' && number.phone_1___value.includes(':::')) {
                numberList = number.phone_1___value.split(' ::: ')
                contactNumber = numberList.shift()
              } else {
                contactNumber = phoneNumFormat(number.phone_1___value)
              }
            }
          }
          return dispatch(
            updateContact(
              number.name,
              number.organization_1___name,
              number.e_mail_1___value,
              number.address_1___street,
              number.address_1___extended_address,
              number.address_1___city,
              number.address_1___postal_code,
              number.address_1___region,
              contactNumber,
              numberList,
              null,
              true,
              '',
              null,
              userId,
            ),
          )
        } else {
          const name =
            number.first_name || number.last_name ? `${number.first_name || ''} ${number.last_name || ''}`.trim() : null
          let address_street
          let address_street2
          let city
          let postal_code
          let state
          let contactList = []
          if (number.home_street) {
            let r = number.home_address && number.home_address.split(',').shift()
            let ret = r && r.replace(number.home_street, '')
            let ret1 = ret && ret.replace(number.home_country, '')
            let ret2 = ret1 && ret1.replace(number.home_city, '')
            let ret3 = ret2 && ret2.replace(number.home_postal_code, '')
            let street2 = ret3 && ret3.replace(number.home_state, '')
            address_street = number.home_street
            address_street2 = street2
            city = number.home_city
            postal_code = number.home_postal_code
            state = number.home_state
          } else if (number.business_street) {
            let r = number.business_address && number.business_address.split(',').shift()
            let ret = r && r.replace(number.business_street, '')
            let ret1 = ret && ret.replace(number.business_country, '')
            let ret2 = ret1 && ret1.replace(number.business_city, '')
            let ret3 = ret2 && ret2.replace(number.business_postal_code, '')
            let street2 = ret3 && ret3.replace(number.business_state, '')
            address_street = number.business_street
            address_street2 = street2
            city = number.business_city
            postal_code = number.business_postal_code
            state = number.business_state
          } else if (number.other_street) {
            let r = number.other_address && number.other_address.split(',').shift()
            let ret = r && r.replace(number.other_street, '')
            let ret1 = ret && ret.replace(number.other_country, '')
            let ret2 = ret1 && ret1.replace(number.other_city, '')
            let ret3 = ret2 && ret2.replace(number.other_postal_code, '')
            let street2 = ret3 && ret3.replace(number.other_state, '')
            address_street = number.other_street
            address_street2 = street2
            city = number.other_city
            postal_code = number.other_postal_code
            state = number.other_state
          }
          if (number.home_phone || number.mobile_phone || number.business_phone || number.other_phone) {
            contactNumber =
              phoneNumFormat(number.home_phone) ||
              phoneNumFormat(number.mobile_phone) ||
              phoneNumFormat(number.business_phone) ||
              phoneNumFormat(number.other_phone)
          } else {
            contactNumber = ''
          }
          if (number.home_phone_2 || number.business_phone_2) {
            contactList[0] = number.home_phone_2 || number.business_phone_2
          }
          return dispatch(
            updateContact(
              name,
              number.company,
              number.e_mail_address,
              address_street,
              address_street2,
              city,
              postal_code,
              state,
              contactNumber,
              contactList,
              null,
              true,
              '',
              null,
              userId,
            ),
          )
        }
      })
  }

  const papaparseOptions = {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: true,
    transformHeader: (header) => header.toLowerCase().replace(/\W/g, '_'),
  }

  const handleCheck = (e) => {
    if (e.target.checked === true) {
      setAllContactId([...allContactId, e.target.value])
    } else {
      const data = allContactId.filter((data) => data !== e.target.value)
      setAllContactId(data)
    }
  }

  const toggleDeleteDialog = (val) => {
    setshowDeleteDialog(val)
  }

  const isDeleteDialog = (val) => {
    setshowDeleteDialog(val)
  }

  const checkDistributionContact = (number) => {
    if (number) {
      const setColor = []
      allList.length &&
        allList.filter((dist) => {
          if (dist.phoneNumber === number && dist.status === true) {
            allList.map((element) => {
              if (element._id === dist.distributionId) {
                setColor.push(element)
                return element
              }
              return element
            })
          }
          return null
        })
      if (setColor.length > 0) {
        const color = setColor.map((col) => col)
        return color
      } else return undefined
    }
  }

  const handleContactDetalis = (contact) => {
    if (contact) {
      setMemberIformation(contact)
      setTimeout(() => {
        history.push(`/contacts/${contact._id}`)
      }, 1000)
    }
  }

  const handleSort = (value) => {
    setSortBy(value)
  }

  const handlePageClick = async (data) => {
    const { selected } = data
    setPageNumber(selected)
  }

  return (
    <div className="light">
      {loading && (
        <div className="loader-container">
          <div className="loader"></div>
        </div>
      )}
      <ToastContainer autoClose={8000} />
      <div className="layout-admin">
        <Sidebar contactUsModal={contactUsModal} changeSetNumberModal={changeSetNumberModal} history={props.history} />
        <Dialog
          contactUsModal={contactUsModal}
          contactToogle={contactToogle}
          changeSetNumberModal={changeSetNumberModal}
          setNumberToogle={setNumberToogle}
        />
        <div className="content">
          <UserHeader changeSetNumberModal={changeSetNumberModal} history={props.history} />
          <div className="numbers">
            <div className="numbers-btns">
              <div className="search-btns align-items-center">
                <input
                  type="text"
                  className="form-control search-input"
                  id="search"
                  placeholder="Search..."
                  onChange={handleSearchContact}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      searchContact()
                    }
                  }}
                />
                <div className="contact-search">
                  <i className="fas fa-search"></i>
                </div>
              </div>
              <div className="contact-sideMenu">
                <FilterMenu onFilter={setFilter} />
                <DistributionMenu />
                <div
                  className="addContactBtn"
                  onClick={() => {
                    isContactDialog(true, {})
                  }}
                >
                  <i className="fas fa-plus" />
                </div>
                <CSVReader
                  label="Import CSV"
                  cssClass="csv-reader-input"
                  onFileLoaded={handleForce}
                  parserOptions={papaparseOptions}
                />

                <div className="disIconst">
                  <Button color="primary" disabled={allContactId.length === 0} onClick={() => isDeleteDialog(true)}>
                    <i className="fas fa-trash-alt"></i>
                  </Button>
                </div>
              </div>
            </div>

            <div>
              <table className="table table-striped mb-0 client-table mt-4">
                <thead>
                  <tr>
                    <th></th>
                    <th></th>
                    <th
                      className="pointer"
                      onClick={() => handleSort(sortBy === 'labelName-asc' ? 'labelName-desc' : 'labelName-asc')}
                    >
                      Name {sortBy === 'labelName-asc' && <i className="fas fa-arrow-up"></i>}
                      {sortBy === 'labelName-desc' && <i className="fas fa-arrow-down"></i>}
                    </th>
                    <th
                      className="pointer"
                      onClick={() => handleSort(sortBy === 'company-asc' ? 'company-desc' : 'company-asc')}
                    >
                      Company {sortBy === 'company-asc' && <i className="fas fa-arrow-up"></i>}
                      {sortBy === 'company-desc' && <i className="fas fa-arrow-down"></i>}
                    </th>
                    <th
                      className="pointer"
                      onClick={() => handleSort(sortBy === 'phoneNumber-asc' ? 'phoneNumber-desc' : 'phoneNumber-asc')}
                    >
                      Number {sortBy === 'phoneNumber-asc' && <i className="fas fa-arrow-up"></i>}
                      {sortBy === 'phoneNumber-desc' && <i className="fas fa-arrow-down"></i>}
                    </th>
                    <th
                      className="pointer"
                      onClick={() => handleSort(sortBy === 'email-asc' ? 'email-desc' : 'email-asc')}
                    >
                      Email {sortBy === 'email-asc' && <i className="fas fa-arrow-up"></i>}
                      {sortBy === 'email-desc' && <i className="fas fa-arrow-down"></i>}
                    </th>
                    <th>Distribution</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredPhoneNum &&
                    filteredPhoneNum.length > 0 &&
                    filteredPhoneNum.map((number, index) => (
                      <tr key={number._id}>
                        <td className="td-center">
                          <input
                            type="checkbox"
                            className="custom-input"
                            id={number._id}
                            value={number._id}
                            checked={allContactId.includes(number._id)}
                            onChange={handleCheck}
                          />
                        </td>
                        <td className="td-center">
                          <ContactImage _id={number._id} profileUrl={number.profileUrl} name={number.name ?? ''} />
                        </td>
                        <td className="td-center pointer" onClick={() => handleContactDetalis(number)}>
                          {number.name}
                        </td>
                        <td className="td-center pointer" onClick={() => handleContactDetalis(number)}>
                          {number.company && number.company}
                        </td>
                        <td className="td-center pointer" onClick={() => handleContactDetalis(number)}>
                          {number.formattedNumber}
                        </td>
                        <td className="td-center pointer" onClick={() => handleContactDetalis(number)}>
                          {number.email && number.email}
                        </td>
                        <td className="td-center">
                          {checkDistributionContact(number.phoneNumber) !== undefined &&
                          checkDistributionContact(number.phoneNumber).length > 0 ? (
                            <div className="multiple-dist">
                              {checkDistributionContact(number.phoneNumber).map((i, index) => {
                                return (
                                  <span key={index}>
                                    <CustomTooltip target={`Tooltip-${i._id}`} content={i.name}>
                                      <Badge
                                        className="dist-badge"
                                        color="secondary"
                                        style={{ backgroundColor: `${i.color}` }}
                                        id={`Tooltip-${i._id}`}
                                      ></Badge>
                                    </CustomTooltip>
                                  </span>
                                )
                              })}
                            </div>
                          ) : (
                            checkDistributionContact(number.phoneNumber) !== undefined && (
                              <>
                                <CustomTooltip target={`Tooltip-${number._id}`} content={number.name}>
                                  <Badge
                                    style={{ backgroundColor: `${number.color}` }}
                                    className="dist-badge"
                                    id={`Tooltip-${number._id}`}
                                  ></Badge>
                                  {JSON.stringify(number)}
                                </CustomTooltip>
                              </>
                            )
                          )}
                        </td>
                        <td className="td-center contact-action">
                          {number.phoneNumber !== null && (
                            <>
                              <QuickCall
                                history={props.history}
                                fromNumber={savedNumber}
                                toNumber={number.phoneNumber}
                              />
                              <NewSms history={props.history} toNumber={number.phoneNumber} fromNumber={savedNumber} />
                            </>
                          )}
                          <div
                            className="btn btn-light"
                            id="newCall"
                            onClick={() => {
                              isContactDialog(true, number)
                            }}
                          >
                            <i className="fas fa-edit"></i>
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>

              <ReactPaginate
                previousLabel={'⟨'}
                nextLabel={'⟩'}
                // breakLabel={'...'}
                breakClassName={'break-me'}
                pageCount={totalPages}
                marginPagesDisplayed={0}
                pageRangeDisplayed={2}
                onPageChange={handlePageClick}
                containerClassName={'con-pagination position-relative mt-3'}
                activeClassName={'active'}
              />

              {showContactDialog && (
                <ContactDetailsSave
                  isContactDialog={isContactDialog}
                  showContactDialog={showContactDialog}
                  toggleContactDialog={toggleContactDialog}
                  memberInformation={memberInformation}
                  distribution={allList}
                  contacts={contacts}
                />
              )}
              {showDeleteDialog && (
                <ContactDelete
                  isContactDialog={isDeleteDialog}
                  showDeleteDialog={showDeleteDialog}
                  toggleDeleteDialog={toggleDeleteDialog}
                  contact_id={allContactId}
                  toggleContactDialog={toggleContactDialog}
                  showContactDialog={showContactDialog}
                  distribution={allList}
                  contacts={contacts}
                  // onDelete={props.onDelete}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Contacts
