import React, { useState, useRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ToastContainer, toast } from 'react-toastify'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Tooltip } from 'reactstrap'
import { sendMessage, setMemberNum, getCallForward, startConversation } from '../../actions/message.action'
import CONFIG from '../../constants/config'
import axios from 'axios'
import './History.css'

const NewSms = (props) => {
  const { toNumber, fromNumber, contact } = props

  const [values, updateValues] = useState({
    phoneNum: toNumber,
    msgText: '',
  })
  const [setNumberToogle, updateSetNumber] = useState(false)
  const [adminPhoneNum, updateAdminPhoneNum] = useState('')
  const [uploadImgName, updateuploadImgName] = useState('')
  const [conversationToogle, updateConversation] = useState(false)
  const [tooltipOpen1, setTooltipOpen1] = useState(false)
  const [tooltipOpen3, setTooltipOpen3] = useState(false)
  const uploadInput = useRef(null)

  const toggleTooltip1 = () => setTooltipOpen1(!tooltipOpen1)
  const toggleTooltip3 = () => setTooltipOpen3(!tooltipOpen3)

  const dispatch = useDispatch()
  const { userName, numbers } = useSelector((state) => state.message)

  useEffect(() => {
    if (!conversationToogle) {
      updateuploadImgName('')
    }
  }, [conversationToogle])
  useEffect(() => {
    if (numbers) {
      updateAdminPhoneNum(numbers.savedNumber)
    }
    // eslint-disable-next-line
  }, [numbers])

  const conversationModal = () => {
    if (!conversationToogle) {
      updateValues({ ...values, phoneNum: '' })
      dispatch(setMemberNum(''))
    }
    updateConversation(!conversationToogle)
  }

  const handleValues = (e) => {
    if (e.target.name === 'phoneNum') {
      updateValues({
        ...values,
        [e.target.name]: e.target.value,
      })
    } else if (e.target.name === 'msgText') {
      if (e.key === 'Enter') {
        sendingMessage()
      } else {
        updateValues({ ...values, [e.target.name]: e.target.value })
      }
    }
  }

  const changeSetNumberModal = () => {
    if (!setNumberToogle) {
      dispatch(getCallForward())
    }
    updateSetNumber(!setNumberToogle)
  }

  const sendingMessage = () => {
    if (!adminPhoneNum) {
      changeSetNumberModal()
    } else if (values.phoneNum) {
      dispatch(sendMessage(toNumber, fromNumber, values.msgText, userName.fullName, uploadImgName))
      uploadInput.current = null
      updateValues({ ...values, msgText: '' })
      updateuploadImgName('')
    }
  }

  const imageUpload = (ev) => {
    ev.preventDefault()
    const data = new FormData()
    data.append('file', uploadInput.current.files[0])
    axios
      .post(`${CONFIG.serverURL}/fileupload`, data, {
        headers: { 'X-Requested-With': 'XMLHttpRequest' },
      })
      .then((response) => {
        updateuploadImgName(response.data.file)
      })
  }

  const startConverstaion = async () => {
    updateValues({ ...values, phoneNum: toNumber })
    if (isNumeric(toNumber) && toNumber.length === 10) {
    } else {
      toast.warn(`Please check Phone Number(${toNumber})'s type and length`, {
        position: toast.POSITION.TOP_RIGHT,
      })
    }

    if (values.msgText === '') {
      toast.warn('Please input message.', {
        position: toast.POSITION.TOP_RIGHT,
      })
    } else {
      dispatch(
        startConversation(toNumber, fromNumber, values.msgText, userName.userEmail, userName.fullName, uploadImgName),
      )
      updateValues({ ...values, msgText: '' })
      conversationModal()
      props.history.push('/home')
    }
  }

  const isNumeric = (value) => {
    return /^\d+$/.test(value)
  }
  return (
    <>
      {contact ? (
        <i id="newConversatoion" onClick={conversationModal} className="far fa-comment-alt"></i>
      ) : (
        <div className="btn btn-light" id="newConversatoion" onClick={conversationModal}>
          <i className="far fa-comment-alt"></i>
        </div>
      )}

      <Tooltip placement="top" isOpen={tooltipOpen1} autohide={false} target="newConversatoion" toggle={toggleTooltip1}>
        New SMS
      </Tooltip>

      <Modal
        isOpen={conversationToogle}
        toggle={conversationModal}
        className={`light-modal modal-dialog modal-dialog-centered modal-dialog-zoom`}
      >
        <ToastContainer autoClose={8000} />
        <ModalHeader toggle={conversationModal}>
          <i className="far fa-comment-alt"></i> New SMS
        </ModalHeader>
        <ModalBody>
          <div className="contact">
            <div className="input-group">
              <input
                type="text"
                className="form-control"
                placeholder="To:"
                name="phoneNum"
                value={toNumber}
                onChange={handleValues}
                pattern="[0-9, ]"
              />
            </div>
            <div className="input-group number-information">
              <span>
                <i className="fa fa-question-circle mt-2 ml-2" id="phoneNumInfo"></i>
              </span>
              <Tooltip
                placement="left"
                isOpen={tooltipOpen3}
                autohide={false}
                target="phoneNumInfo"
                toggle={toggleTooltip3}
              >
                Enter the recipient's 10 digit phone number (ex. 2087358999).
              </Tooltip>
            </div>

            <div className="input-group mt-3 new-conversation">
              <label id="#bb">
                <i className="fas fa-paperclip"></i>
                <input
                  type="file"
                  ref={uploadInput}
                  onChange={imageUpload}
                  accept="image/*|audio/*|video/*|application/*"
                />
              </label>
              <textarea
                type="text"
                name="msgText"
                className="form-control pl-4"
                placeholder="Message: "
                onChange={handleValues}
                value={values.msgText}
              />
            </div>
            <div>{uploadImgName}</div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={startConverstaion}>
            Send
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default NewSms
