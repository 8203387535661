import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { ToastContainer, toast } from 'react-toastify'
import Select from 'react-select'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Tooltip } from 'reactstrap'
import axios from 'axios'

import Loader from './Loader'
import CONFIG from '../../constants/config.json'
import './voicemails.css'

const MoveVoiceMail = (props) => {
  const { vmboxId, mediaId, accountId, voicemailDelete } = props
  const { voicemailBoxes } = useSelector((state) => state.voicemails)

  const [isModal, setIsModal] = useState(false)
  const [tooltipOpen, setTooltipOpen] = useState(false)
  const [selectOptions, setSelectOptions] = useState([])
  const [selectMailBox, setSelectMailBox] = useState('')
  const [loader, setLoader] = useState(false)

  const toggleModal = () => {
    setIsModal(!isModal)
  }

  const moveVoicemail = async () => {
    if (selectMailBox) {
      try {
        setLoader(true)
        const URL = `${CONFIG.API_URL}/accounts/${accountId}/vmboxes/${vmboxId}/messages/${mediaId}`
        const result = await axios.post(URL, { data: { source_id: selectMailBox.value } })

        if (result.data.status === 'success') {
          toast.success('Successfully.', {
            position: toast.POSITION.TOP_RIGHT,
          })
          await voicemailDelete(vmboxId, mediaId, true)
        } else {
          toast.warn('Please try it again.', {
            position: toast.POSITION.TOP_RIGHT,
          })
        }
      } catch (error) {
        toast.warn('Please try it again.', {
          position: toast.POSITION.TOP_RIGHT,
        })
      } finally {
        setLoader(false)
        toggleModal()
      }
    } else {
      toast.warn('Please select VM Box.', {
        position: toast.POSITION.TOP_RIGHT,
      })
    }
  }

  useEffect(() => {
    const options = []
    voicemailBoxes &&
      voicemailBoxes.forEach((box) => {
        options.push({ value: box.id, label: box.name })
      })
    options.sort(function (a, b) {
      const textA = a.label.toUpperCase()
      const textB = b.label.toUpperCase()
      return textA < textB ? -1 : textA > textB ? 1 : 0
    })

    setSelectOptions(options)
    // eslint-disable-next-line
  }, [voicemailBoxes])

  return (
    <>
      <div className="btn btn-light" id="movefile" onClick={toggleModal}>
        <i className="fas fa-clone"></i>
      </div>

      <Tooltip
        placement="top"
        isOpen={tooltipOpen}
        autohide={false}
        target="movefile"
        toggle={() => setTooltipOpen(!tooltipOpen)}
      >
        Move Voicemail
      </Tooltip>

      <Modal
        isOpen={isModal}
        toggle={toggleModal}
        className={`light-modal modal-dialog modal-dialog-centered modal-dialog-zoom`}
      >
        <ToastContainer autoClose={8000} />
        <ModalHeader toggle={toggleModal}>
          <i className="fas fa-clone"></i>
          <span className="ml-2">Move Voicemail</span>
        </ModalHeader>
        <ModalBody>
          <div className="contact">
            <span className="tab-title">To</span>
            <Select
              isClearable
              id="device_id"
              value={selectMailBox}
              onChange={(e) => setSelectMailBox(e)}
              options={selectOptions}
              placeholder="Select Mailbox"
              maxMenuHeight={200}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          {loader ? (
            <Loader loader={loader} />
          ) : (
            <Button color="primary" onClick={moveVoicemail}>
              Move Voicemail
            </Button>
          )}
        </ModalFooter>
      </Modal>
    </>
  )
}

export default MoveVoiceMail
