import React, { useEffect, useState, useRef } from 'react'
import { ReactTinyLink } from 'react-tiny-link'

import './message.css'
import moment from 'moment'
import ReactDOM from 'react-dom'

const MessageText = (props) => {
  const { toggleModal, setScheduleMessage } = props
  const message = props.message.text
  const score = props.message.sentiment_score
  const datePicker = useRef()

  const [positiveScore, setPositiveScore] = useState('')
  const [negativeScore, setNegativeScore] = useState('')

  useEffect(() => {
    if (score >= 0.25 && score <= 1) {
      setPositiveScore(score)
    } else if (score <= -0.25 && score >= -1) {
      setNegativeScore(score)
    }
  }, [score])

  useEffect(() => {
    if (!datePicker.current) {
      return
    }
    const _this = datePicker.current
    _this.onOutsideAction = (event) => {
      if (
        _this.wrapper &&
        !_this.wrapper.contains(event.target) &&
        typeof event?.target?.className === 'string' &&
        !event?.target?.className?.indexOf('react-calendar')
      ) {
        _this.closeCalendar()
        event.stopPropagation()
      }
    }
  }, [])

  const messageDate = props.message.createdAt
  let hours = moment().diff(moment(messageDate), 'hours')

  const loadPreview = (index, url, randomNumber) => {
    const previewElement = document.getElementById('load_preview_' + randomNumber)
    previewElement.innerHTML = ''
    previewElement.classList.remove('preview-older')

    ReactDOM.render(
      <ReactTinyLink cardSize="small" showGraphic={true} maxLine={2} minLine={1} url={url} />,
      previewElement,
    )
  }

  const getLinksInText = (text) => {
    const urlRegex = /\b(?:https?:\/\/|www\.)\S+/gi
    const matches = text.match(urlRegex)
    const messages = []

    if (!matches) {
      return [text]
    }

    text.split(urlRegex).forEach((part, index) => {
      if (matches[index]) {
        const url = /^(f|ht)tps?:\/\//i.test(matches[index]) ? matches[index] : `https://${matches[index]}`
        messages.push(
          <a className="link" key={'link' + index} target="_blank" rel="noopener noreferrer" href={url}>
            {matches[index]}{' '}
          </a>,
        )
      } else {
        messages.push(`${part} `)
      }
    })

    matches.forEach((url, index) => {
      let preview
      if (hours <= 24) {
        preview = (
          <div key={index} className="link-preview">
            <ReactTinyLink cardSize="small" showGraphic={true} maxLine={2} minLine={1} url={url} />
          </div>
        )
      } else {
        const randomNumber = Math.random()
        preview = (
          <div key={index} id={`load_preview_${randomNumber}`} className="link-preview preview-older">
            Click to Load Preview
            <div className="load-preview" onClick={(e) => loadPreview(index, url, randomNumber)}></div>
            <a className="link" key={'link' + index} target="_blank" rel="noopener noreferrer" href={url}>
              {url} <i className="fa fa-angle-right arrow-right"></i>
            </a>
          </div>
        )
      }
      messages.push(preview)
    })
    return messages
  }

  const toggleScheduleModal = () => {
    setScheduleMessage(message)
    toggleModal()
  }

  return (
    <div className="edit-schedule">
      <div className={`message-content ${props.message.scheduleMsg ? 'schedule-message' : ''}`}>
        {props.message.direction === 'in' &&
          ((positiveScore !== '' && <i className="far fa-smile sentiment_result"> </i>) ||
            (negativeScore !== '' && <i className="far fa-frown sentiment_result"> </i>))}{' '}
        {getLinksInText(message)}
        {props.message?.scheduleMsg && (
          <span className="schedule-edit">
            <i className="fas fa-edit" onClick={toggleScheduleModal} />
          </span>
        )}
      </div>
    </div>
  )
}

export default MessageText
