import React from 'react'
import { useDispatch } from 'react-redux'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { AddResellerDetalisAction, getResellerList } from '../../../actions/campaign.action'
import * as yup from 'yup'
import { useFormik } from 'formik'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import './common-style.css'

const validationSchema = yup.object().shape({
  companyName: yup.string().required('Company Name is required'),
  phone: yup
    .number()
    .typeError("That doesn't look like a phone number")
    .positive("phone number can't start with a minus")
    .integer("phone number can't include a decimal point")
    .min(8)
    .required('phone number is  required'),
  email: yup.string().email('Invalid email').required('Email  is required'),
})

const ResellerCreateModal = ({ isOpen, onClose }) => {
  const dispatch = useDispatch()
  const { values, handleChange, handleBlur, handleSubmit, errors, touched, resetForm } = useFormik({
    initialValues: {
      companyName: '',
      phone: '',
      email: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      const isSuccess = await dispatch(AddResellerDetalisAction(values))
      if (isSuccess) {
        resetForm({
          companyName: '',
          phone: '',
          email: '',
        })
        await dispatch(getResellerList())
        onClose()
      }
    },
  })

  return (
    <Modal
      isOpen={isOpen}
      toggle={onClose}
      className={`light-modal call-modal modal-dialog modal-dialog-centered modal-dialog-zoom quick-call-modal`}
    >
      <form onSubmit={handleSubmit}>
        <ModalHeader className="call-header">Reseller Details</ModalHeader>
        <ModalBody>
          <div className="contact">
            <input
              type="text"
              className="form-control  mb-3"
              placeholder="Legal Company Name"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.companyName}
              name="companyName"
            />
            {!!errors.companyName && !!touched.companyName && (
              <div style={{ color: 'red', marginTop: '-12px' }}>{errors.companyName}</div>
            )}

            <div style={{ marginBottom: '10px' }}>
              <PhoneInput
                className="flagInput"
                country={'us'}
                onChange={handleChange('phone')}
                onBlur={handleBlur}
                value={values.phone}
                name="phone"
              />
            </div>
            {!!errors.phone && <div style={{ color: 'red', marginTop: '-12px' }}>{errors.phone}</div>}
            <input
              type="email"
              className="form-control  mb-3"
              placeholder="Email Address"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              name="email"
            />
            {!!errors.email && !!touched.email && (
              <div style={{ color: 'red', marginTop: '-12px' }}>{errors.email}</div>
            )}
          </div>{' '}
        </ModalBody>
        <ModalFooter>
          <Button type="button" className="assign-footer-btn cancel-btn" onClick={onClose}>
            Cancel
          </Button>
          <Button color="primary" type="submit" className="start-call">
            {'Save'}
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  )
}

export default ResellerCreateModal
