import React, { useState, Fragment, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { changePassword, resetPassword } from '../../actions/auth.action'
import { ToastContainer, toast } from 'react-toastify'
import './ResetPassword.css'
import queryString from 'query-string'

const ResetPassword = (props) => {
  const dispatch = useDispatch()
  const { resForgotPass, resetPass } = useSelector((state) => state.auth)
  const { recovery } = queryString.parse(props.location.search)

  if (!recovery) {
    props.history.push('/')
  }
  const [formData, setFormData] = useState({
    password: '',
    repassword: '',
  })
  const [passStrength, setPassStrength] = useState(false)
  const change = () => {
    if (formData.password === formData.repassword) {
      const data = {
        authToken: resetPass.auth_token,
        accountId: resetPass.data.account_id,
        userId: resetPass.data.owner_id,
        password: formData.password,
      }
      dispatch(changePassword(data))
    } else {
      toast.warn('Please input password correctly', {
        position: toast.POSITION.TOP_RIGHT,
      })
    }
  }

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    })
  }

  const onSuccess = (message) => {
    toast.success(message, {
      position: toast.POSITION.TOP_RIGHT,
    })
  }
  useEffect(() => {
    if (formData.password.length === 0) {
      setPassStrength(false)
    } else if (formData.password.length < 6) {
      setPassStrength(true)
    } else {
      setPassStrength(false)
    }
  }, [formData.password])
  useEffect(() => {
    dispatch(resetPassword(recovery))
    // eslint-disable-next-line
  }, [])
  return (
    <Fragment>
      {resForgotPass && resForgotPass.status === 'success' ? (
        <div className="App">
          <div className="form-membership">
            <ToastContainer autoClose={5000} />
            {onSuccess('Password has been changed successfully.')}
            <div className="form-wrapper">
              <p className="form-info">Your password has been successfully updated.</p>
              <div className="infobox">
                <a className="link" href="/">
                  Sign-in
                </a>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="App">
          <div className="form-membership">
            <ToastContainer autoClose={5000} />
            <div className="form-wrapper">
              <div className="form-group input-group-lg">
                <label>New Password</label>
                <input
                  type="password"
                  name="password"
                  onChange={handleChange}
                  className="form-control"
                  required
                  autoFocus
                />
                {passStrength && <p>Please enter at least 6 characters.</p>}
              </div>

              <div className="form-group input-group-lg">
                <label>Confirm Password</label>
                <input type="password" name="repassword" onChange={handleChange} className="form-control" required />
              </div>
              <button className="btn btn-primary btn-lg btn-block" onClick={change}>
                Reset Password
              </button>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  )
}

export default ResetPassword
