// @flow
import * as CONSTS from '../constants/const'

let defaultState = { callFlow: [], callNotes: [], allNotes: [], systemAuth: null, loading: false }

export default (state = defaultState, action) => {
  switch (action.type) {
    case CONSTS.GET_ALL_CALLFLOW:
      return { ...state, callFlow: action.payload, loading: false }
    case CONSTS.GET_ALL_CALLFLOW_LOADING:
      return { ...state, loading: action.payload }
    case CONSTS.SAVE_CALL_NOTES:
      return { ...state, callNotes: action.payload }
    case CONSTS.GET_CALL_NOTES:
      return { ...state, allNotes: action.payload }
    case CONSTS.FAIL_AUTH_REQUEST:
      return { ...state, callFlow: [], systemAuth: action.payload }
    default:
      return state
  }
}
