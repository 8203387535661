import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PerfectScrollbar from 'react-perfect-scrollbar'

import HistorySearch from './HistorySearch'
import HistoryTable from './HistoryTable'
import Sidebar from '../Sidebar/Sidebar'
import Dialog from '../Sidebar/Dialog'
import UserHeader from '../Header/UserHeader'
import { getDevices } from '../../actions/message.action'
import { getCallFlow } from '../../actions/callhistory.action'

import './History.css'

const History = (props) => {
  const dispatch = useDispatch()
  const { callFlow, loading } = useSelector((state) => state.callhistory)

  const [startDate, setStartDate] = useState(new Date(new Date().setDate(new Date().getDate() - 7)))
  const [endDate, setEndDate] = useState(new Date())
  const [filter, setFilter] = useState('')
  const [perPage, setPerPage] = useState(10)
  const [currentPage, setCurrentPage] = useState(0)
  const [total, setTotal] = useState(0)
  const [contactToogle, setContactToogle] = useState(false)
  const [setNumberToogle, setSetNumberToogle] = useState(false)
  const [addCall, setAddCall] = useState(false)
  const [callList, setCallList] = useState([])

  useEffect(() => {
    if (callFlow?.call_data) {
      setCallList(callFlow.call_data)
    }
  }, [callFlow])

  useEffect(() => {
    dispatch(getCallFlow(startDate, endDate))
    //eslint-disable-next-line
  }, [startDate, endDate])

  useEffect(() => {
    dispatch(getDevices())
    //eslint-disable-next-line
  }, [])

  const handleTotalChange = (length) => {
    setTotal(length)
  }

  const startDateChange = (date) => {
    const newDate = new Date(date)
    newDate.setHours(0, 0, 0, 0)
    setStartDate(newDate)
    const dateDiff = parseInt((endDate.getTime() - newDate.getTime()) / (24 * 3600 * 1000), 10)
    if (dateDiff > 30) {
      const newEndDate = new Date(newDate.getTime() + 30 * 24 * 3600 * 1000)
      newEndDate.setHours(23, 59, 59, 999)
      setEndDate(newEndDate)
    }
  }

  const endDateChange = (date) => {
    const newDate = new Date(date)
    newDate.setHours(23, 59, 59, 999)
    setEndDate(newDate)
    const dateDiff = parseInt((newDate.getTime() - startDate.getTime()) / (24 * 3600 * 1000), 10)
    if (dateDiff > 30) {
      const newStartDate = new Date(newDate.getTime() - 30 * 24 * 3600 * 1000)
      newStartDate.setHours(0, 0, 0, 0)
      setStartDate(newStartDate)
    }
  }

  const prev = () => {
    setCurrentPage((prevPage) => prevPage - 1)
  }

  const next = () => {
    setCurrentPage((prevPage) => prevPage + 1)
  }

  // const apply = () => {
  //   dispatch(getCallFlow(startDate, endDate))
  // }

  const selectPerPage = (e) => {
    setPerPage(e.target.value)
  }

  const setCountLabel = (total) => {
    return perPage * (currentPage + 1) < total ? perPage * (currentPage + 1) : total
  }

  const contactUsModal = () => {
    setContactToogle(!contactToogle)
  }

  const changeSetNumberModal = () => {
    setSetNumberToogle(!setNumberToogle)
  }

  const call = () => {
    setAddCall(!addCall)
  }

  const setAddCallHandler = () => {
    setAddCall(!addCall)
  }

  return (
    <div className="light">
      <div className="layout-user">
        <Sidebar history={props.history} contactUsModal={contactUsModal} changeSetNumberModal={changeSetNumberModal} />
        <Dialog
          contactUsModal={contactUsModal}
          contactToogle={contactToogle}
          changeSetNumberModal={changeSetNumberModal}
          setNumberToogle={setNumberToogle}
          addCall={addCall}
          setAddCall={setAddCallHandler}
        />
        <div className="content">
          <UserHeader changeSetNumberModal={changeSetNumberModal} />
          {loading ? (
            <div className="loader-container">
              <div className="loader"></div>
            </div>
          ) : (
            <div className="history">
              <PerfectScrollbar>
                <div className="main-container">
                  <HistorySearch
                    startDateChange={startDateChange}
                    endDateChange={endDateChange}
                    changeFilter={setFilter}
                    // apply={apply}
                    state={{ startDate, endDate, filter }}
                    call={call}
                  />
                  <HistoryTable
                    history={props.history}
                    list={callList}
                    perPage={perPage}
                    currentPage={currentPage}
                    filter={filter}
                    handleTotalChange={handleTotalChange}
                  />
                  <nav className="bottom-nav">
                    <label className="mr-2">Views</label>
                    <select onChange={selectPerPage}>
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                    <label className="ml-2">Per Page</label>
                    <span className="page-num">
                      {perPage * currentPage + 1}-{setCountLabel(total)} of {total}
                    </span>
                    {currentPage === 0 ? (
                      <button onClick={prev} className="button-disable" disabled>
                        &#60;
                      </button>
                    ) : (
                      <button onClick={prev} className="button-enable">
                        &#60;
                      </button>
                    )}
                    {(currentPage + 1) * perPage >= total ? (
                      <button onClick={next} className="button-disable" disabled>
                        &#62;
                      </button>
                    ) : (
                      <button onClick={next} className="button-enable">
                        &#62;
                      </button>
                    )}
                  </nav>
                </div>
              </PerfectScrollbar>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default History
