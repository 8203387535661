import * as CONSTS from '../constants/const'

let defaultState = { notifications: [], readNotifications: null, newNotifications: [] }

export default (state = defaultState, action) => {
  switch (action.type) {
    case CONSTS.GET_ALL_NOTIFICATIONS:
      return { ...state, notifications: action.payload }
    case CONSTS.GET_READ_NOTIFICATIONS:
      return { ...state, readNotifications: action.payload }
    case CONSTS.GET_NEW_NOTIFICATIONS:
      return { ...state, newNotifications: action.payload }
    default:
      return state
  }
}
