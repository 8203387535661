import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import CONFIG from '../../constants/config'

const ClioAuth = (props) => {
  let history = useHistory()
  useEffect(() => {
    const email = localStorage.getItem('email')

    const query = new URLSearchParams(props.location.search)
    const code = query.get('code')
    axios.post(`${CONFIG.serverURL}/clioauth/response`, { code: code, email: email }).then((res) => {
      localStorage.setItem('clioToken', res.data.access_token)
      setTimeout(() => {
        history.push('/home')
      }, 1000)
    })
    // eslint-disable-next-line
  }, [props.location.search])
  return <div></div>
}
export default ClioAuth
