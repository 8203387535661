import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Badge } from 'reactstrap'
import { getDistributionList, addNewDistribution } from '../../actions/distribution.action'
import ContactModal from '../Distribution/ContactModal'
import DistributionModal from '../Distribution/DistributionModal'
import DistributionDelete from './DistributionDelete'
import DistrutionIcons from '../../asset/media/img/distrution.png'

const DistributionMenu = (props) => {
  const { allList } = useSelector((state) => state.distribution)
  const [distributionMenu, setDistributionMenu] = useState(false)
  const [isModal, setIsModal] = useState(false)
  const [iscontact, setIsContactModel] = useState(false)
  const { distributionId } = props
  const [contact, setContact] = useState({})
  const [contact_id, setContact_id] = useState('')
  const distributionList = {}
  const limit = 6
  const [listLength, setListLength] = useState(0)
  const [showMore, setShowMore] = useState(false)
  const [showDeleteDialog, setshowDeleteDialog] = useState(false)
  const [id, setId] = useState('')

  const [newDistribution, setNewDistribution] = useState({
    id: '',
    name: '',
    distributionId: '',
    subscribe: false,
    color: '',
  })
  const [showApply, setShowApply] = useState(false)

  const handleDistribution = () => {
    setDistributionMenu((prevState) => !prevState)
    setShowApply(false)
    setShowMore(false)
    setListLength(0)
  }
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getDistributionList())
    // eslint-disable-next-line
  }, [])

  const toggleModal = () => {
    setIsModal(!isModal)
  }

  const addDistribution = () => {
    toggleModal()
  }

  const editDistribution = () => {
    dispatch(addNewDistribution(newDistribution))
    setShowApply(!showApply)
  }

  const handleEdit = (e, dist) => {
    setNewDistribution({
      ...newDistribution,
      name: e.target.value,
      distributionId: e.target.id,
      id: dist._id,
      color: dist.color,
      subscribe: dist.subscribe,
    })
    setShowApply(!showApply)
  }

  const handleMore = () => {
    setListLength(allList.length - limit)
    setShowMore(true)
  }
  const toggleContactModel = () => {
    setIsContactModel(!iscontact)
  }

  const addContact = (value) => {
    setId(value)
    toggleContactModel()
    setContact({})
  }
  const isContactDialog = (val, contactid) => {
    setshowDeleteDialog(val)
    if (contactid) {
      setContact_id(contactid)
    }
  }

  const toggleDeleteDialog = (val) => {
    setshowDeleteDialog(val)
  }

  return (
    <div className="distribution-menu">
      <Dropdown isOpen={distributionMenu} toggle={handleDistribution}>
        <DropdownToggle className="distribution-icon">
          {' '}
          <div>
            <img className="iconsSet" src={DistrutionIcons} alt="avatar" />
          </div>
        </DropdownToggle>
        <DropdownMenu className="distribution-dropmenu">
          <div className="drop-scroll-distribution">
            {allList &&
              allList.map(
                (dist, index) =>
                  index < limit + listLength && (
                    <DropdownItem toggle={false} key={index} className="drop-distribution">
                      <Badge style={{ backgroundColor: `${dist.color}` }} className="dist-badge"></Badge>
                      <input
                        id={dist.distributionId}
                        name="label"
                        className="dist-input"
                        defaultValue={dist.name}
                        type="text"
                        style={{ marginRight: '0px' }}
                        onChange={(e) => handleEdit(e, dist)}
                      />
                      <div>
                        <i className="fas fa-plus" onClick={() => addContact(dist._id)}></i>
                        <i
                          style={{ margin: '8px', color: 'red' }}
                          className="fas fa-trash-alt"
                          onClick={() => {
                            isContactDialog(true)
                            setId(dist._id)
                          }}
                        ></i>
                      </div>
                    </DropdownItem>
                  ),
              )}
          </div>
          {!showMore && allList.length > limit && (
            <p onClick={handleMore} className="dist-more">
              {allList.length - limit} more
            </p>
          )}
          {showApply ? (
            <div className="btn position-add-dropdown" onClick={editDistribution}>
              Apply
            </div>
          ) : (
            <div className="btn position-add-dropdown" onClick={addDistribution}>
              Add Distribution
            </div>
          )}
        </DropdownMenu>
      </Dropdown>
      <DistributionModal isModal={isModal} toggleModal={toggleModal} distributionList={distributionList} />
      <ContactModal
        isModal={iscontact}
        toggleModal={toggleContactModel}
        setIsContactModel={setIsContactModel}
        distributionId={distributionId || id}
        editData={contact}
      />
      <DistributionDelete
        isContactDialog={isContactDialog}
        showDeleteDialog={showDeleteDialog}
        toggleDeleteDialog={toggleDeleteDialog}
        contact_id={contact_id}
        toggleContactDialog={props.toggleContactDialog}
        showContactDialog={props.showContactDialog}
        distributionId={distributionId || id}
      />
    </div>
  )
}

export default DistributionMenu
