import React from 'react'
import './Audioplayer.css'
import CONFIG from '../../constants/config.json'

const Audioplayer = (props) => {
  const { vmboxId, mediaId, authToken, accountId } = props.props
  const URL = `${CONFIG.API_URL}/accounts/${accountId}/vmboxes/${vmboxId}/messages/${mediaId}/raw?auth_token=${authToken}`

  return (
    <div className="container-audio">
      <audio controls id="player" controlsList="nodownload">
        <source src={URL} />
        <p>Your browser doesn't support the audio tag</p>
      </audio>
    </div>
  )
}

export default Audioplayer
