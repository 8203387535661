import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import './voicemails.css'
import VoicemailBox from './VoicemailBox'
import { getallvmboxes, getmainvmboxes } from '../../actions/voicemails.action'

const Voicemails = (props) => {
  const dispatch = useDispatch()
  const { voicemails, mainvoicemails } = useSelector((state) => state.voicemails)

  const [allmessages, setAllMessages] = useState([])
  const [mainVoicemailMessages, setMainVoicemailMessages] = useState([])
  const [contactToogle, setContactToogle] = useState(false)
  const [setNumberToogle, setSetNumberToogle] = useState(false)

  useEffect(() => {
    dispatch(getallvmboxes())
    dispatch(getmainvmboxes())
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (voicemails !== allmessages) {
      setAllMessages(voicemails)
    }
    if (mainvoicemails !== mainVoicemailMessages) {
      setMainVoicemailMessages(mainvoicemails)
    }

    // eslint-disable-next-line
  }, [voicemails, mainvoicemails])

  const contactUsModal = () => {
    setContactToogle(!contactToogle)
  }

  const changeSetNumberModal = () => {
    setSetNumberToogle(!setNumberToogle)
  }

  return (
    <VoicemailBox
      allmessages={allmessages}
      mainVoicemailMessages={mainVoicemailMessages}
      history={props.history}
      contactUsModal={contactUsModal}
      contactToogle={contactToogle}
      changeSetNumberModal={changeSetNumberModal}
      setNumberToogle={setNumberToogle}
    />
  )
}

export default Voicemails
