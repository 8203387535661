import React, { useState, useEffect, useMemo } from 'react'
import './contact-detail.css'
import { Card } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import Sidebar from '../Sidebar/Sidebar'
import { getCallForward, getMessage, getAllCalls } from '../../actions/message.action'
import Dialog from '../Sidebar/Dialog'
import UserHeader from '../Header/UserHeader'
import ContactDetailsSave from '../Home/ContactDetailsSave'
import 'react-initials-avatar/lib/ReactInitialsAvatar.css'
import { getmainvmboxes } from '../../actions/voicemails.action'
import CONFIG from '../../constants/config'
import * as CONSTS from '../../constants/const'
import Audioplayer from '../Voicemails/Audioplayer'
import DistributionMenuModel from './DistributionMenuModel'
import { getAllContactsById, getDistributionList, getDistributionContactList } from '../../actions/distribution.action'
import { ToastContainer } from 'react-toastify'
import silhoutte from '../../asset/media/img/silhoutte.png'
import { useHistory } from 'react-router-dom'
import 'react-initials-avatar/lib/ReactInitialsAvatar.css'
import ContactImage from './ContactImage'
import { getNotes } from '../../actions/callhistory.action'
import AddNotes from '../CallHistory/AddNotes'
import { phoneNumberFormat, convertDateTime, getDuration, convertCallTime } from '../../lib/utilities'

const ContactsDetails = (props) => {
  const callNotes = useSelector((state) => state.callhistory.allNotes)
  const {
    contactsDetail: storeContactDetail,
    allCalls,
    numbers,
    messages,
    loading,
  } = useSelector((state) => state.message)
  const [contactToogle, updateContactUs] = useState(false)
  const [setNumberToogle, updateSetNumber] = useState(false)
  const [showContactDialog, setshowContactDialog] = useState(false)
  let data = props.history.location.pathname
  let contactId = data.slice(10)
  const [voiceMailDetails, setVoiceMailDetails] = useState([])
  const [callDetails, setcallDetails] = useState([])
  const [msgDetails, setmsgDetails] = useState([])
  const { voicemails } = useSelector((state) => state.voicemails)
  const [audioPlay, setAaudioPlay] = useState(false)
  const [audioId, setAudioId] = useState('')
  const vmbox_id = voiceMailDetails?.vmbox?.id
  let account_id = localStorage.getItem('account_id')
  const auth_token = localStorage.getItem('token')
  const owner_id = localStorage.getItem('user_id')
  const { allList, distributionContacts } = useSelector((state) => state.distribution)
  const [disName, setdisName] = useState([])
  let history = useHistory()
  const dispatch = useDispatch()
  const distributionContact = useMemo(
    () => getContactFromDistribution(distributionContacts, contactId),
    [distributionContacts, contactId],
  )
  const contactsDetail = useMemo(
    () => getContactDetails(storeContactDetail, distributionContact),
    [distributionContact, storeContactDetail],
  )

  const number = contactsDetail.phoneNumber

  const getContactDetails = (contact, distributionContact) => {
    if (!contact) {
      contact = {}
    }
    if (!distributionContact) {
      distributionContact = {}
    }
    const keys = [...new Set([...Object.keys(contact), ...Object.keys(distributionContact)])]
    const output = {}
    for (var key of keys) {
      output[key] = contact[key] || distributionContact[key]
    }
    return output
  }

  const getContactFromDistribution = (distributionContacts, contactId) => {
    const el = (distributionContacts?.distribution || []).find((disphone) => disphone._id === contactId)
    return el ? { ...el, labelName: el.name } : null
  }

  const contactUsModal = () => {
    updateContactUs(!contactToogle)
  }
  const changeSetNumberModal = () => {
    if (!setNumberToogle) {
      dispatch(getCallForward())
    }
    updateSetNumber(!setNumberToogle)
  }

  useEffect(() => {
    dispatch(getAllContactsById(contactId))
    //eslint-disable-next-line
  }, [contactId])

  useEffect(() => {
    dispatch(getmainvmboxes())
    dispatch(getAllCalls())
    dispatch(getDistributionContactList())
    dispatch(getDistributionList())
    //eslint-disable-next-line
  }, [])

  const isContactDialog = (val, contact) => {
    setshowContactDialog(val)
  }

  const toggleContactDialog = (val) => {
    setshowContactDialog(val)
  }

  // Voicemail Messages
  useEffect(() => {
    const voiceMailDetails = voicemails[0]
    let data = []
    voiceMailDetails &&
      voiceMailDetails.messages.length > 0 &&
      voiceMailDetails.messages.map((voiceMail) => {
        if (parseInt(voiceMail.caller_id_number) === parseInt(number)) {
          data.push(voiceMail)
        }
        return voiceMail
      })
    setVoiceMailDetails(data)
  }, [voicemails, contactsDetail, number])

  // Recent Calls
  useEffect(() => {
    if (number && callDetails) {
      let callData = allCalls.filter((calls) => parseInt(calls.caller_id_number) === parseInt(number))
      setcallDetails(callData)

      const uniqueInteractionIds = [...new Set(callData.map((el) => el.interaction_id))]
      dispatch(getNotes(uniqueInteractionIds))
    }
    //eslint-disable-next-line
  }, [allCalls, contactsDetail])

  // Recent Messages
  useEffect(() => {
    dispatch(getMessage(number, numbers.savedNumber, owner_id))
  }, [numbers.savedNumber, contactsDetail, owner_id, number, dispatch])

  useEffect(() => {
    if (number) {
      let msgDataSet = messages.filter(
        (msg) => parseInt(msg.from_number) === parseInt(number) || parseInt(msg.to_number) === parseInt(number),
      )
      setmsgDetails(msgDataSet)
    }
  }, [messages, contactsDetail, number])

  // distribution
  useEffect(() => {
    const output = []
    if (distributionContacts && number) {
      distributionContacts.distribution &&
        distributionContacts.distribution.forEach((dis) => {
          if (number === dis.phoneNumber && dis.status === true) {
            allList &&
              allList.forEach((data) => {
                if (data._id === dis.distributionId) {
                  output.push({ ...data, refId: dis._id })
                }
              })
          }
        })
    }
    setdisName(output)
  }, [distributionContacts, contactsDetail, allList, number])

  const audioPlayer = (key) => {
    setAaudioPlay(!audioPlay)
    setAudioId(key)
  }

  const audioPlayerEnd = async (key, vmbox_id, media_id, state) => {
    if (state === 'new') {
      let url = `${CONFIG.API_URL}/accounts/${this.state.account_id}/vmboxes/${vmbox_id}/messages/${media_id}`
      await axios.post(url)
    }
    setAaudioPlay(!audioPlay)
    setAudioId(key)
  }

  return (
    <div className="light">
      {loading && (
        <div className="loader-container">
          <div className="loader"></div>
        </div>
      )}
      <ToastContainer autoClose={8000} />
      <div className="layout-admin">
        <Sidebar contactUsModal={contactUsModal} changeSetNumberModal={changeSetNumberModal} history={props.history} />
        <Dialog
          contactUsModal={contactUsModal}
          contactToogle={contactToogle}
          changeSetNumberModal={changeSetNumberModal}
          setNumberToogle={setNumberToogle}
        />
        <div className="content">
          <UserHeader changeSetNumberModal={changeSetNumberModal} />
          <div className="right-dash-data">
            <Card className="bodyData">
              <div className="card-head">
                <div>
                  <ContactImage
                    size={100}
                    name={contactsDetail?.labelName || contactsDetail.company || ''}
                    _id={contactsDetail._id}
                    profileUrl={contactsDetail.profileUrl || silhoutte}
                  />
                </div>
                <div className="titleDiv">
                  {contactsDetail?.labelName ? (
                    <>
                      <div className="d-flex justify-content-between">
                        <h2 className="detail-name m-0" style={{ paddingBottom: 0 }}>
                          {contactsDetail.labelName}
                        </h2>
                        <button
                          onClick={() => {
                            history.push(`/contacts`)
                          }}
                          className="backArrowBtn m-0"
                        >
                          <i className="fa fa-arrow-left" aria-hidden="true"></i>
                        </button>
                      </div>
                      <p className="lable">{contactsDetail.company}</p>
                    </>
                  ) : (
                    <h2 className="detail-name" style={{ marginBottom: '8px' }}>
                      {contactsDetail.company}
                    </h2>
                  )}

                  {/* distribution data display */}
                  <div className="chipsDiv">
                    <div className="chipsinnerDiv">
                      {disName &&
                        disName.map((el, i) => (
                          <div key={i} className="displaychips">
                            <div className="grpbox">
                              <div className="boxdata" style={{ backgroundColor: `${el.color}` }} />
                              <p className="boxName">{el.name}</p>
                            </div>
                          </div>
                        ))}
                      <div className="popBox">
                        <DistributionMenuModel
                          disName={disName}
                          setdisName={setdisName}
                          contactsDetail={contactsDetail}
                        />
                      </div>
                    </div>
                    {!storeContactDetail ? (
                      <button className="btnEdit" onClick={() => isContactDialog(true, contactsDetail)}>
                        Add Contact
                      </button>
                    ) : (
                      <button className="btnEdit" onClick={() => isContactDialog(true, contactsDetail)}>
                        Edit
                      </button>
                    )}
                  </div>
                </div>
              </div>
              <hr />
              {/* Contact Details */}
              <div className="container">
                <div className="row">
                  <div className="col-3">
                    <div className="contactDetailContainer">
                      <p className="headerTitle">Contact Details</p>
                      <div className="contactList">
                        {contactsDetail?.email && <i className="fa fa-envelope" />}
                        <span className="liststwo">{contactsDetail?.email || ''}</span>
                      </div>
                      <div className="contactList">
                        {contactsDetail?.phoneNumber && <i className="fa fa-phone" />}
                        <span className="liststwo">{phoneNumberFormat(contactsDetail?.phoneNumber || '')}</span>
                      </div>
                      <div className="contactList">
                        {contactsDetail?.company && <i className="far fa-building" />}
                        <span className="liststwo">{contactsDetail?.company || ''}</span>
                      </div>
                      <div className="contactList street">
                        {contactsDetail?.street && <i className="fas fa-address-card"></i>}
                        <div className="addressContact">
                          <span>{contactsDetail?.street || ''}</span>
                          {contactsDetail.street2 && (
                            <>
                              <br />
                              <span>{contactsDetail.street2}</span>
                              <br />{' '}
                            </>
                          )}
                          <span>
                            {contactsDetail.city && <span className="addressList">{contactsDetail.city},</span>}
                            {contactsDetail.state && <span className="addressList">{contactsDetail.state}</span>}
                            {contactsDetail.zip && <span className="addressList">{contactsDetail.zip}</span>}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Voicemail Messages  */}
                  <div className="col-2 colData">
                    <div className="headerTitle">
                      <div className="cardTitle"> Voicemail Messages ({voiceMailDetails.length})</div>
                    </div>
                    <div className="vertical">
                      {voiceMailDetails &&
                        voiceMailDetails.slice(0, 5).map((voiceMail, index) => {
                          return (
                            <div key={index}>
                              {audioPlay && voiceMail.media_id === audioId ? (
                                <div className="row">
                                  <div className="col-md-10">
                                    <Audioplayer
                                      props={{
                                        vmbox_id: vmbox_id,
                                        account_id: account_id,
                                        auth_token: auth_token,
                                        audioPlay: audioPlay,
                                        media_id: audioId,
                                      }}
                                    />
                                  </div>
                                  <div className="col-md-2">
                                    <button
                                      className="audio-close"
                                      onClick={() =>
                                        audioPlayerEnd(audioPlay, vmbox_id, voiceMail.media_id, voiceMail.folder)
                                      }
                                    >
                                      <i className="fas fa-times"></i>
                                    </button>
                                  </div>
                                </div>
                              ) : (
                                <>
                                  <div
                                    className="about-call-icon listvaluedata"
                                    onClick={() => audioPlayer(voiceMail.media_id)}
                                  >
                                    <i className="fas fa-play pddData"></i>
                                  </div>
                                  <span>{convertDateTime((voiceMail.timestamp - 62167219200) * 1000)}</span>
                                  <span>{getDuration(voiceMail.length / 1000)}</span>
                                </>
                              )}
                            </div>
                          )
                        })}
                      {voiceMailDetails.length === 0 ? (
                        <div className="errorMsg">{CONSTS.EMPTY_VOICEMAIL_LIST_MESSAGE}</div>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>

                  {/* Recent Calls */}
                  <div className="col-2 colData customized">
                    <div className="headerTitle">
                      <div className="cardTitle">Recent Calls ({callDetails.length})</div>
                    </div>
                    <div className="vertical">
                      {callDetails &&
                        callDetails.length > 0 &&
                        callDetails.map((call, index) => {
                          return (
                            <div key={index}>
                              <div>
                                <div className="about-call-icon listvaluedata">
                                  <i className="fas fa-phone-alt"></i>
                                </div>
                                <span>
                                  {convertDateTime(call.iso_8601_combined) +
                                    ', ' +
                                    convertCallTime(call.billing_seconds)}
                                </span>
                              </div>
                            </div>
                          )
                        })}
                      {callDetails.length === 0 ? <div className="errorMsg">{CONSTS.EMPTY_CALL_LIST_MESSAGE}</div> : ''}
                    </div>
                  </div>

                  {/*Recent Messagess  */}
                  <div className="col-2 colData customized">
                    <div className="headerTitle">
                      <div className="cardTitle">Recent Messages ({msgDetails.length})</div>
                    </div>
                    <div className="vertical">
                      {msgDetails &&
                        msgDetails.length > 0 &&
                        msgDetails.map((msg, index) => {
                          return (
                            <>
                              <div key={index}>
                                <div>
                                  <div className="about-call-icon listvaluedata">
                                    <i className="fa fa-sms"></i>
                                  </div>
                                  {convertDateTime(msg.createdAt)}
                                </div>
                              </div>
                            </>
                          )
                        })}
                      {msgDetails.length === 0 ? <div className="errorMsg">{CONSTS.EMPTY_MSG_LIST_MESSAGE}</div> : ''}
                    </div>
                  </div>

                  {/*Recent Call Notes  */}
                  <div className="col-2 colData customized">
                    <div className="headerTitle">
                      <div className="cardTitle">Recent Call Notes ({callNotes.length})</div>
                    </div>
                    <div className="notePrivate">
                      {!!callNotes.length &&
                        callNotes.map((note, index) => {
                          return (
                            <>
                              <div key={index}>
                                <div>
                                  <div className="about-call-icon listvaluedata">
                                    <AddNotes
                                      call={{
                                        ...note,
                                        caller_id_number: note.from,
                                        dialed_number: note.to,
                                        call_id: note.callId,
                                      }}
                                      fromNumber={note.from}
                                      toNumber={note.to}
                                      isOutboundCall={note.call_direction === 'OUTBOUND'}
                                      interaction_id={note.interaction_id}
                                      canPrivate
                                    />
                                  </div>
                                  {convertDateTime(note.createdAt)}
                                </div>
                              </div>
                            </>
                          )
                        })}
                      {callNotes.length === 0 ? <div className="errorMsg">{CONSTS.EMPTY_CALL_NOTE_MESSAGE}</div> : ''}
                    </div>
                  </div>
                </div>
              </div>
            </Card>
          </div>
        </div>
      </div>

      {showContactDialog && (
        <ContactDetailsSave
          isContactDialog={isContactDialog}
          showContactDialog={showContactDialog}
          toggleContactDialog={toggleContactDialog}
          memberInformation={contactsDetail}
        />
      )}
    </div>
  )
}

export default ContactsDetails
