import React, { useState, useEffect } from 'react'
import { useDispatch, connect } from 'react-redux'
import Sidebar from '../../Sidebar/Sidebar'
import { getCallForward } from '../../../actions/message.action'
import Dialog from '../../Sidebar/Dialog'
import './calldetails.css'
import { getCallDetails } from '../../../actions/callhistory.action'
import telIcon from '../../../asset/media/svg/telicon-2.2.0.svg'
import moment from 'moment'
import UserHeader from '../../Header/UserHeader'

const CallDetails = (props) => {
  const { getCallDetails, callHistoryDetails } = props

  const dispatch = useDispatch()
  const [contactToogle, updateContactUs] = useState(false)
  const [setNumberToogle, updateSetNumber] = useState(false)
  const interaction_id = props.match.params.interaction_id

  useEffect(() => {
    getCallDetails(interaction_id)
    // eslint-disable-next-line
  }, [interaction_id])

  const contactUsModal = () => {
    updateContactUs(!contactToogle)
  }

  const changeSetNumberModal = () => {
    if (!setNumberToogle) {
      dispatch(getCallForward())
    }
    updateSetNumber(!setNumberToogle)
  }

  const getDateTime = (timestamp) => {
    const formattedDate = moment.unix(timestamp - 62167219200).format('YYYY-MM-DD')
    const formattedTime = moment.unix(timestamp - 62167219200).format('HH:mm:ss')
    const dateTime = { date: formattedDate, time: formattedTime }
    return dateTime
  }

  const formatDuration = (sec) => {
    var date = new Date(null)
    date.setSeconds(sec)
    var timeString = date.toISOString().substr(11, 8)
    timeString = timeString.split(':')[1] + ':' + timeString.split(':')[2]
    return timeString
  }

  return (
    <div className="light">
      <div className="layout-user">
        <Sidebar contactUsModal={contactUsModal} changeSetNumberModal={changeSetNumberModal} history={props.history} />
        <Dialog
          contactUsModal={contactUsModal}
          contactToogle={contactToogle}
          changeSetNumberModal={changeSetNumberModal}
          setNumberToogle={setNumberToogle}
        />

        <div className="content">
          <UserHeader changeSetNumberModal={changeSetNumberModal} />
          <div className="right-dash">
            <div className="right-dash-middle">
              <div className="row">
                <div className="col-md-12">
                  <div className="dashboard-box">
                    <div className="dash-box-heading">
                      <div className="call-history-table">
                        <div className="row history-table-title">
                          <div className="col-md-4">FROM</div>
                          <div className="col-md-3">TO</div>
                          <div className="col-md-3">DATE TIME</div>
                          <div className="col-md-2">DURATION</div>
                        </div>
                        {callHistoryDetails && callHistoryDetails.length > 0 ? (
                          callHistoryDetails.map((callHistory, index) => (
                            <div key={index} className="row call-history-row">
                              <div className="col-md-4 history-from">
                                <div className="mr-3 call-icon">
                                  {callHistory.call_direction === 'inbound' ? (
                                    <svg className="outcalls-icon">
                                      <use href={`${telIcon}#phone-outbound`} />
                                    </svg>
                                  ) : callHistory.hangup_cause !== 'NO_ANSWER' ? (
                                    <svg className="incalls-icon">
                                      <use href={`${telIcon}#phone-inbound`} />
                                    </svg>
                                  ) : (
                                    <svg className="missed-icon">
                                      <use href={`${telIcon}#phone-missed`} />
                                    </svg>
                                  )}
                                </div>
                                <div>
                                  <div className="text-left name">{callHistory.caller_id_name}</div>
                                  <div className="text-left number">{callHistory.caller_id_number}</div>
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="name text-left">{callHistory.callee_id_name}</div>
                                <div className="number text-left">{callHistory.other_leg_destination_number}</div>
                              </div>
                              <div className="col-md-3">
                                <div className="text-left name">{getDateTime(callHistory.timestamp).date}</div>
                                <div className="text-left number">{getDateTime(callHistory.timestamp).time}</div>
                              </div>
                              <div className="col-md-2">
                                {callHistory.duration_seconds && formatDuration(callHistory.duration_seconds)}
                              </div>
                            </div>
                          ))
                        ) : (
                          <div className="col-md-12 text-center">
                            <h2>No Results!</h2>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  callHistoryDetails: state.dashboard.callHistoryDetails,
})
const mapDispatchToProps = (dispatch) => ({
  getCallDetails: (interaction_id) => {
    dispatch(getCallDetails(interaction_id))
  },
})
export default connect(mapStateToProps, mapDispatchToProps)(CallDetails)
