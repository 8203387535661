import axios from 'axios'
import CONFIG from '../constants/config'

export const getLocalStorageData = () => {
  const account_id = localStorage.getItem('account_id')
  const user_id = localStorage.getItem('user_id')
  const token = localStorage.getItem('token')
  return { account_id, user_id, token }
}

export const fetchDeviceNumber = async (account_id, user_id) => {
  return axios.get(
    `${CONFIG.API_URL}/accounts/${account_id}/callflows?filter_type=mainUserCallflow&filter_owner_id=${user_id}&paginate=false`,
  )
}

export const fetchUserData = async (account_id, user_id) => {
  return axios.get(`${CONFIG.API_URL}/accounts/${account_id}/users/${user_id}`)
}

export const fetchUserBlf = async (account_id, user_id) => {
  return axios.get(`${CONFIG.API_URL}/accounts/${account_id}/users/${user_id}/presence`)
}
export const fetchAssignNumbers = async (account_id, user_id) => {
  return await axios.post(`${CONFIG.serverURL}/getassignnumbers`, {
    userId: user_id,
    accountId: account_id,
  })
}
