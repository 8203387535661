import React from 'react'
import { Tooltip, Card, CardBody, Badge } from 'reactstrap'
import { phoneNumFormat, getMemberName, getCompanyName, getProfileAvatar, truncateString } from '../../lib/utilities'
import ReactPaginate from 'react-paginate'
import { useDispatch, useSelector } from 'react-redux'

import { getFavoriteConversations } from '../../actions/message.action'
import * as CONSTS from '../../constants/const'
import DropMenu from './DropMenu'
import silhoutte from '../../asset/media/img/silhoutte.png'
import './message.css'

const FavoriteConversaions = (props) => {
  const {
    filteredFavMembers,
    setMemNumber,
    userNumber,
    checkAssignConversation,
    checkNotifies,
    isToolTipOpen,
    toggleTooltip4,
    deleteFavorite,
    assignConversationShowPopup,
    checkAssignConversationLength,
    adminPhoneNum,
    isContactDialog,
    favTotalCount,
    searchNumber,
  } = props
  const dispatch = useDispatch()
  const { numbers, userName, favoriteConversations, normalConversations, favoritePagniationNum } = useSelector(
    (state) => state.message,
  )

  const handleFavPageClick = async (data) => {
    const { selected } = data
    await dispatch({
      type: CONSTS.GET_FAVORITE_CONVERSATIONS_PAGINATION_NUMS,
      payload: selected,
    })
    await dispatch(getFavoriteConversations(numbers.savedNumber, userName.userEmail, searchNumber))
  }

  return (
    <>
      <ul className="list-group list-group-flush">
        {filteredFavMembers &&
          filteredFavMembers &&
          filteredFavMembers.map((member, i) => {
            const memberName = getMemberName(member)
            const companyName = getCompanyName(member)
            const avatar = getProfileAvatar(member)

            return (
              <Card key={i} className="chat-name">
                <CardBody>
                  <li
                    key={i}
                    className={`list-group-item ${member.memberNum === userNumber ? 'open-chat' : ''} ${
                      checkAssignConversation(member.memberNum) && !checkNotifies(member.memberNum)
                        ? 'list-chat'
                        : !checkNotifies(member.memberNum) || checkAssignConversation(member.memberNum)
                        ? 'list-count-chat'
                        : ''
                    }`}
                    onClick={() =>
                      setMemNumber(member.memberNum, member.distribution, member.distributionId, member.subscribe)
                    }
                  >
                    <figure className={`avatar ${avatar ? 'profile' : 'icon'}`}>
                      <img alt="Avatar" src={avatar || silhoutte} />
                    </figure>

                    <div className="users-list-body">
                      <span
                        className="home-imp"
                        onClick={() =>
                          setMemNumber(member.memberNum, member.distribution, member.distributionId, member.subscribe)
                        }
                      >
                        {member?.contacts.length === 0 ? (
                          <h5>{phoneNumFormat(member.memberNum)}</h5>
                        ) : (
                          <span>
                            <div className="chat-labelName">
                              <h5>{truncateString(memberName, 17)}</h5>
                              <h5 id={`impnumbercount-${i}`}>
                                {member.contacts.length > 1 && `or  ${member.contacts.length - 1} other`}{' '}
                              </h5>
                              <Tooltip
                                placement="bottom"
                                isOpen={isToolTipOpen(`impnumbercount-${i}`)}
                                autohide={false}
                                target={`impnumbercount-${i}`}
                                toggle={() => toggleTooltip4(`impnumbercount-${i}`)}
                              >
                                {member.contacts.map((data, i) => (
                                  <div key={i}>
                                    {data.labelName !== memberName && (data.labelName || data.company)}{' '}
                                  </div>
                                ))}
                              </Tooltip>
                            </div>
                            <h6>{companyName}</h6>
                            <h6>{phoneNumFormat(member.memberNum)}</h6>
                          </span>
                        )}
                        <i
                          onClick={() => deleteFavorite(member.memberNum)}
                          className="far fa-star chat-favorite-icon"
                        ></i>
                        {checkAssignConversation(member.memberNum) > 0 && (
                          <Badge className="assign-badge" onClick={() => assignConversationShowPopup(member)} pill>
                            Assigned to {checkAssignConversationLength(member.memberNum)}{' '}
                            {`${checkAssignConversationLength(member.memberNum) === 1 ? 'person' : 'people'}`}
                          </Badge>
                        )}
                        {normalConversations.notifies &&
                          normalConversations.notifies.map((notify, i) => {
                            if (notify.number === member.memberNum) {
                              return (
                                <div key={i} className="users-list-action">
                                  <div className="new-message-count">{notify.newMsg}</div>
                                </div>
                              )
                            }
                            return null
                          })}
                      </span>
                    </div>
                  </li>
                  <div className="users-list-action">
                    <div className="action-toggle">
                      <DropMenu
                        tab="favTab2"
                        toNumber={member.memberNum}
                        readConversation={member.readConversation}
                        fromNumber={adminPhoneNum}
                        email={userName.userEmail}
                        contactID={member.contactID}
                        labelName={memberName}
                        favoriteMem={favoriteConversations.favConversationsList}
                        member={member}
                        isContactDialog={isContactDialog}
                      />
                    </div>
                  </div>
                </CardBody>
              </Card>
            )
          })}
      </ul>
      {favTotalCount > 1 && (
        <ReactPaginate
          previousLabel={'⟨'}
          nextLabel={'⟩'}
          // breakLabel={'...'}
          forcePage={favoritePagniationNum}
          breakClassName={'break-me'}
          pageCount={favTotalCount}
          marginPagesDisplayed={0}
          pageRangeDisplayed={2}
          onPageChange={handleFavPageClick}
          containerClassName={'con-pagination'}
          activeClassName={'active'}
        />
      )}
    </>
  )
}

export default FavoriteConversaions
