import axios from 'axios'
import CONFIG from '../constants/config'
import * as CONSTS from '../constants/const'
import { getDistributionContactList } from './distribution.action'
import { getUserData } from './message.action'
import { toast } from 'react-toastify'

const userId = localStorage.getItem('user_id')

function parseRedtailStorage() {
  const data = localStorage.getItem('CRM_CONFIRMED')
  const ids = []
  try {
    if (data) {
      ids.push(...JSON.parse(data))
    }
  } catch (err) {
    console.log(err)
  }
  return ids
}

export function addRedtailToStorage(userId, confirmed = true) {
  const addredids = parseRedtailStorage()
  const index = addredids.findIndex((el) => el.id === userId)
  if (index > -1) {
    addredids[index].confirmed = confirmed
  } else {
    addredids.push({ id: userId, confirmed })
  }
  localStorage.setItem('CRM_CONFIRMED', JSON.stringify(addredids))
}

export function removeIfNotConfirmed(userId) {
  const removeids = parseRedtailStorage()
  const index = removeids.findIndex((el) => el.id === userId && !el.confirmed)
  if (index > -1) {
    removeids.splice(index, 1)
  }
  localStorage.setItem('CRM_CONFIRMED', JSON.stringify([...removeids]))
}

export function updateRedtailAuth(confirmed = true, disabled = false) {
  const items = parseRedtailStorage()
  const index = items.findIndex((el) => el.id === userId)
  if (index > -1) {
    items[index].confirmed = confirmed
    items[index].disabled = disabled
  } else {
    items.push({
      id: userId,
      confirmed,
      disabled,
    })
  }
  localStorage.setItem('CRM_CONFIRMED', JSON.stringify([...items]))
}

export function userAcceptedRedtail() {
  const acceptredtailids = parseRedtailStorage()
  const item = acceptredtailids.find((el) => el.id === userId) || {}
  return {
    id: item.id || userId,
    confirmed: item.confirmed,
    disabled: item.disabled,
  }
}

export function userEnabledRedtailSync() {
  const enableredtailids = parseRedtailStorage()
  const itemData = enableredtailids.find((el) => el.id === userId) || {}
  return itemData.disabled !== true || itemData.disabled === undefined
}

export const getRedTailAuth = () => {
  return async (dispatch) => {
    const userId = localStorage.getItem('user_id')
    addRedtailToStorage(userId)
    axios.get(`${CONFIG.serverURL}/redtailauth`).then((res) => {
      dispatch({ type: CONSTS.RED_SYNC_STATE, payload: res.data })
      toast.success(`Redtail CRM authentication successfully`, {
        position: toast.POSITION.TOP_RIGHT,
      })
    })
  }
}

export const removeRedTailAuth = () => {
  return async (dispatch) => {
    dispatch({ type: CONSTS.RED_SYNC_STATE, payload: [] })
    removeIfNotConfirmed(userId)
  }
}
export const disableRedtailAuth = () => {
  return async (dispatch) => {
    const disabledids = parseRedtailStorage()
    const index = disabledids.findIndex((el) => el.id === userId)

    if (index > -1) {
      disabledids[index].disabled = true
    } else {
      disabledids.push({
        id: userId,
        disabled: true,
      })
    }

    localStorage.setItem('CRM_CONFIRMED', JSON.stringify([...disabledids]))
  }
}

export const enableRedtailAuth = () => {
  return async (dispatch) => {
    const disabledids = parseRedtailStorage()
    const index = disabledids.findIndex((el) => el.id === userId)

    if (index > -1) {
      delete disabledids[index].disabled
      delete disabledids[index].confirmed
    } else {
      disabledids.push({ id: userId, confirmed: false })
    }

    localStorage.setItem('CRM_CONFIRMED', JSON.stringify([...disabledids]))
  }
}

export const syncRedTailContact = (userId) => {
  return async (dispatch) => {
    await axios
      .post(`${CONFIG.serverURL}/sync-redtail-contact`, {
        userId: userId,
      })
      .then((res) => {
        dispatch({ type: CONSTS.GET_ALL_REDTAIL_CONTACTS_LIST, payload: res.data })
        dispatch(getDistributionContactList())
        toast.success(`Contact sync with redtail crm  successfully`, {
          position: toast.POSITION.TOP_RIGHT,
        })
      })
  }
}

export const addBulkContactIntoRedtail = (data) => {
  return async (dispatch) => {
    await axios.post(`${CONFIG.serverURL}/addRedtailContact`, data)
    dispatch(getDistributionContactList())
    toast.success(`Contact add into redtail crm successfully`, {
      position: toast.POSITION.TOP_RIGHT,
    })
  }
}

export const saveSyncRedtail = (state) => {
  return async (dispatch) => {
    dispatch(enableRedtailAuth())
    const owner_id = localStorage.getItem('user_id')
    dispatch({ type: CONSTS.RED_SYNC_STATE, payload: [] })
    await axios
      .post(`${CONFIG.serverURL}/saveRedtailstate`, {
        redtailState: state,
        userId: owner_id,
      })
      .then((res) => {
        if (res.status === 200) dispatch(getUserData())
      })
  }
}
