import React from 'react'
import './voicemails.css'

const MailboxContainer = (props) => {
  const { history, id, name, newcount, messages } = props

  return (
    <div className="voicemailbox">
      <div
        className={
          newcount > 0
            ? `light-voicemailbox-wrapper voicemailbox-wrapper voicemails-top-1`
            : `light-voicemailbox-wrapper voicemailbox-wrapper voicemails-top-2`
        }
        onClick={() => history.push('/voicemails/list/' + id)}
      >
        <div className="pb-4">
          <h2>{name}</h2>
        </div>
        <div className="voicemail-mailbox">
          <div>
            <h1 className={newcount > 0 ? 'newcount' : ''}>{newcount}</h1>
            <span className="num-title">New</span>
          </div>
          <div>
            <h1 className="totalcount">{messages}</h1>
            <span className="num-title">Total</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MailboxContainer
