import React, { useCallback, useMemo, useRef, useState } from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Card, CardHeader, CardBody, Modal, Input, ModalFooter } from 'reactstrap'
import {
  AddbrandDetalisAction,
  getBrandList,
  getCountryList,
  getVerticalList,
  getBrandRelationList,
} from '../../actions/campaign.action'
import './campaignmanagement.css'
import MultiStepProgressBar from './MultiStepProgressBar'
import { useFormik } from 'formik'
import * as yup from 'yup'
import {v4} from 'uuid'

const validationSchema = yup.object().shape({
  companyName: yup.string().required('Company Name is required'),
  displayName: yup.string().required('Name is required'),
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Last name is required'),
  entityType: yup.string().required('Please select entity type'),
  country: yup.string().required('Please select country'),
  postalCode: yup
    .string()
    .required('Please enter your zip')
    .typeError('Please enter a valid zip code'),
  state: yup.string().required('State is required'),
  street: yup.string().required('Street is required'),
  city: yup.string().required('City is required'),
  mobilePhone: yup
    .number()
    .typeError("That doesn't look like a phone number")
    .positive("phone number can't start with a minus")
    .integer("phone number can't include a decimal point")
    .min(8)
    .required('Phone number is  required'),
  vertical: yup.string().required('Please select entity type'),
  ein: yup.string()
    .required()
    .matches(/^[0-9]+$/, "Must be only digits")
    .min(9, 'Must be exactly 9 digits')
    .max(9, 'Must be exactly 9 digits'),
  website: yup.string().required('Website url is required'),
})

const ENTITIES_TYPE = [
  {
    label: 'Publicly Traded Company',
    value: 'PUBLIC_PROFIT',
  },
  {
    label: 'Private Company',
    value: 'PRIVATE_PROFIT',
  },
  {
    label: 'Non-Profit Organization',
    value: 'NON_PROFIT',
  },
  {
    label: 'Government',
    value: 'GOVERNMENT',
  },
  {
    label: 'Sole Proprietor',
    value: 'SOLE_PROPRIETOR',
  },
]

const BrandRelationDetalis = [
  {
    title: ' Basic accounts',
    description: 'No business history with the CSP',
    value: 'BASIC_ACCOUNT',
  },
  {
    title: ' Small accounts',
    description: 'OR some business history with the CSP',
    value: 'SMALL_ACCOUNT',
  },
  {
    title: ' Medium accounts',
    description: 'With good standing with the CSP and solid business history',
    value: 'MEDIUM_ACCOUNT',
  },
  {
    title: ' Large accounts',
    description: 'With a dedicated account manager, highly trusted',
    value: 'LARGE_ACCOUNT',
  },
  {
    title: ' Key accounts',
    description: 'With strategic value and a dedicated account team',
    value: 'KEY_ACCOUNT',
  },
]

function sortByAlphabetic(a, b) {
  if (a.label < b.label) {
    return -1;
  }
  if (a.label > b.label) {
    return 1;
  }
  return 0;
}

function sortCountryData(countries) {
  const data = Object.entries(countries).map(([value, label]) => ({ label, value }))

  const usCountryIndex = data.findIndex(el => el.value === 'US');
  const usCountry = data[usCountryIndex]
  data.splice(usCountryIndex, 1);

  data.sort(sortByAlphabetic)
  const output = [];
  if (usCountry) {
    output.push(usCountry);
  }
  output.push(...data);
  return output;
}

const BrandDetails = ({ onSubmit }) => {
  const dispatch = useDispatch()
  const { Country, Vertical } = useSelector((state) => state.campaignManagement)
  const countryDropdown = useMemo(() => sortCountryData(Country), [Country])
  const verticalDropdown = useMemo(
    () => Object.entries(Vertical).map(([value, data]) => ({ label: data?.displayName, value })).sort(sortByAlphabetic),
    [Vertical],
  )

  useEffect(() => {
    dispatch(getCountryList())
    dispatch(getVerticalList())
    dispatch(getBrandRelationList())
  }, [dispatch])

  const { values, handleChange, handleBlur, handleSubmit, errors, touched } = useFormik({
    initialValues: {
      companyName: '',
      displayName: '',
      firstName: '',
      lastName: '',
      entityType: "",
      ein: '',
      phone: '',
      street: '',
      city: '',
      state: '',
      postalCode: '',
      country: '',
      email: '',
      ipAddress: '192.168.1.2',
      website: '',
      vertical: '',
      referenceId: '',
      mock: false,
      mobilePhone: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      if (values.entityType ==='SOLE_PROPRIETOR'){
        values.referenceId = v4();
      }
      onSubmit(values)
    },
  })
  console.log('temp',values)
  return (
    <form onSubmit={handleSubmit}>
      <CardHeader>
        <p className="title-of-form">Create Brand</p>
      </CardHeader>
      <CardBody>
        <div className="row" style={{ rowGap: 8 }}>
          <div className="col-6 ">
            <Input type="text" bsSize="lg" placeholder="Legal Company Name*" onChange={handleChange}
              onBlur={handleBlur}
              value={values.companyName}
              name="companyName" />
            {!!errors.companyName && !!touched.companyName && (
              <span style={{ color: 'red', marginLeft: "12px" }}>{errors.companyName}</span>
            )}
          </div>

          <div className="col-6 ">
            <Input
              type="text"
              bsSize="lg"
              placeholder="DBA or Brand Name, if different from legal name*"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.displayName}
              name="displayName"
            />
            {!!errors.displayName && !!touched.displayName && (
              <span style={{ color: 'red', marginLeft: "12px" }}>{errors.displayName}</span>
            )}
          </div>
          <div className="col-6">
            <select name="entityType" id="entityType" className="form-control" onBlur={handleBlur}
              value={values.entityType}
              onChange={handleChange}
            >
              <option value="">What type of legal form is the organization?*</option>
              {ENTITIES_TYPE.map((el) => (
                <option value={el.value} key={el.value}>
                  {el.label}
                </option>
              ))}
            </select>
            {!!errors.entityType && !!touched.entityType && (
              <span style={{ color: 'red', marginLeft: "12px" }}>{errors.entityType}</span>
            )}
          </div>
          <div className="col-6">
            <Input type="number" bsSize="lg" placeholder="Tax Number/ID*"
              onBlur={handleBlur}
              value={values.ein}
              onChange={handleChange}
              name="ein"
            />
            {!!errors.ein && !!touched.ein && (
              <span style={{ color: 'red', marginLeft: "12px" }}>{errors.ein}</span>
            )}
          </div>

          <div className="col-6">
            <Input type="text" bsSize="lg" placeholder="Address/Street"
              onBlur={handleBlur}
              value={values.street}
              onChange={handleChange}
              name="street"

            />
            {!!errors.street && !!touched.street && (
              <span style={{ color: 'red', marginLeft: "12px" }}>{errors.street}</span>
            )}
          </div>
          <div className="col-6">
            <Input type="text" bsSize="lg" placeholder="City" onBlur={handleBlur}
              value={values.city}
              onChange={handleChange}
              name="city"
            />
            {!!errors.city && !!touched.city && (
              <span style={{ color: 'red', marginLeft: "12px" }}>{errors.city}</span>
            )}
          </div>
          <div className="col-6">
            <Input type="text" bsSize="lg" placeholder="State/Region*"
              onBlur={handleBlur}
              value={values.state}
              onChange={handleChange}
              name="state"
            />
            {!!errors.state && !!touched.state && (
              <span style={{ color: 'red', marginLeft: "12px" }}>{errors.state}</span>
            )}
          </div>
          <div className="col-6">
            <Input type="number" bsSize="lg" placeholder="Postal Code/Zip Code"
              onBlur={handleBlur}
              value={values.postalCode}
              onChange={handleChange}
              name="postalCode"
            />
            {!!errors.postalCode && !!touched.postalCode && (
              <span style={{ color: 'red', marginLeft: "12px" }}>{errors.postalCode}</span>
            )}
          </div>
          <div className="col-6">
            <select name="country" id="country" className="form-control" onBlur={handleBlur}
              value={values.country}
              onChange={handleChange}
            >
              <option value="">Country</option>
              {countryDropdown.map((el) => (
                <option value={el.value} key={el.value}>
                  {el.label}
                </option>
              ))}
            </select>
            {!!errors.country && !!touched.country && (
              <span style={{ color: 'red', marginLeft: "12px" }}>{errors.country}</span>
            )}
          </div>
          <div className="col-6">
            <Input type="website" bsSize="lg" placeholder="Website/Online Presence"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.website}

              name="website"
            />
            {!!errors.website && !!touched.website && (
              <span style={{ color: 'red', marginLeft: "12px" }}>{errors.website}</span>
            )}
          </div>
          <div className="col-6">
            <select name="vertical" id="vertical" className="form-control" onBlur={handleBlur}
              value={values.vertical}
              onChange={handleChange}
            >
              <option value="">Select Vertical Type</option>
              {verticalDropdown.map((el) => (
                <option value={el.value} key={el.value}>
                  {el.label}
                </option>
              ))}
            </select>
            {!!errors.vertical && !!touched.vertical && (
              <span style={{ color: 'red', marginLeft: "12px" }}>{errors.vertical}</span>
            )}
          </div>
          <div className="col-6">
            <Input type="text" bsSize="lg" placeholder="First Name"
              onBlur={handleBlur} onChange={handleChange}
              value={values.firstName}
              name="firstName"
            />
            {!!errors.firstName && !!touched.firstName && (
              <span style={{ color: 'red', marginLeft: "12px" }}>{errors.firstName}</span>
            )}
          </div>
          <div className="col-6">
            <Input type="text" bsSize="lg" placeholder="Last Name" onBlur={handleBlur} onChange={handleChange}
              value={values.lastName}
              name="lastName" />
            {!!errors.lastName && !!touched.lastName && (
              <span style={{ color: 'red', marginLeft: "12px" }}>{errors.lastName}</span>
            )}
          </div>
          <div className="col-6">
            <Input type="number" bsSize="lg" placeholder="Mobile Phone" onBlur={handleBlur} onChange={handleChange}
              value={values.mobilePhone}
              name="mobilePhone" />
            {!!errors.mobilePhone && !!touched.mobilePhone && (
              <span style={{ color: 'red', marginLeft: "12px" }}>{errors.mobilePhone}</span>
            )}
          </div>
        </div>
      </CardBody>
      <ModalFooter>
        <Button type="submit" color="primary" >
          Next
        </Button>
      </ModalFooter>
    </form>
  )
}

const BrandRelation = ({ onSubmit, onBack }) => {
  const [form, setForm] = useState({ brandRelationship: '' })
  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault()
      onSubmit(form)
    },
    [onSubmit, form],
  )
  const handleRelationShipChange = useCallback((value) => {
    setForm((prev) => ({ ...prev, brandRelationship: value }))
  }, [])

  return (
    <form onSubmit={handleSubmit}>
      <CardHeader>
        <p className="title-of-form">Brand Relation</p>
      </CardHeader>
      <CardBody>
        <div className="main">
          {BrandRelationDetalis.map((rel) => (
            <div onClick={() => handleRelationShipChange(rel.value)} className="box-data">
              <div className="title-desk">{rel.title}</div>
              <div className="box-detalis">
                <p className="tag">{rel.description}</p>
                <div>{rel.value === form.brandRelationship && <p>Selected</p>}</div>
              </div>
            </div>
          ))}
        </div>
      </CardBody>
      <ModalFooter style={{ gap: 8, display: 'flex' }}>
        <Button type="button" style={{ backgroundColor: 'green', color: 'white' }} onClick={onBack}>
          Back
        </Button>
        <Button type="submit" color="primary">
          Next
        </Button>
      </ModalFooter>
    </form>
  )
}

const ContactDetails = ({ onSubmit, onBack }) => {
  const { values, handleChange, handleBlur, handleSubmit, errors, touched } = useFormik({
    initialValues: {
      phone: '',
      email: ''
    },
    validationSchema: yup.object().shape({
      phone: yup
        .number()
        .typeError("That doesn't look like a phone number")
        .positive("phone number can't start with a minus")
        .integer("phone number can't include a decimal point")
        .min(8)
        .required('Phone number is  required'),

      email: yup.string().email('Invalid email').required('Email  is required'),
    }),
    onSubmit: async (values) => {
      onSubmit(values)
    },
  })


  return (
    <form onSubmit={handleSubmit}>
      <CardHeader>
        <p className='title-of-form'>Contact Details</p>
      </CardHeader>
      <CardBody>
        <div className="row" style={{ rowGap: 8 }}>
          <div className="col-6 ">
            <Input type="email" placeholder="Brand Contact Details" onBlur={handleBlur} onChange={handleChange}
              value={values.email}
              name="email" />
            {!!errors.email && !!touched.email && (
              <span style={{ color: 'red', marginLeft: "12px" }}>{errors.email}</span>
            )}
          </div>

          <div className="col-6">
            <Input type="number" placeholder="Support Phone Number"
              onBlur={handleBlur} onChange={handleChange}
              value={values.phone}
              name="phone" />
            {!!errors.phone && !!touched.phone && (
              <span style={{ color: 'red', marginLeft: "12px" }}>{errors.phone}</span>
            )}
          </div>

        </div>
      </CardBody>
      <ModalFooter style={{ gap: 8, display: 'flex' }}>
        <Button type="button" style={{ backgroundColor: 'green', color: 'white' }} onClick={onBack}>
          Back
        </Button>
        <Button type="submit" color="primary">
          Submit
        </Button>
      </ModalFooter>
    </form>
  )
}

const CreateBrand = () => {
  const [currentStep, setCurrentStep] = useState(1)
  const [openBrandModel, setOpenBrandModel] = useState(false)
  const stateRef = useRef({})
  const dispatch = useDispatch()

  const toggleBrandModel = () => {
    setOpenBrandModel((prev) => !prev)
  }

  const handleBrandDetail = useCallback((form) => {
    stateRef.current = {
      ...stateRef.current,
      ...form,
    }
    setCurrentStep(2)
  }, [])

  const handleBrandRelation = useCallback((form) => {
    stateRef.current = {
      ...stateRef.current,
      ...form,
    }
    setCurrentStep(3)
  }, [])

  const handleContactDetails = useCallback(
    async (form) => {
      stateRef.current = {
        ...stateRef.current,
        ...form,
      }
      const isSuccess = await dispatch(AddbrandDetalisAction(stateRef.current))
      if (isSuccess) {
        toggleBrandModel()
        dispatch(getBrandList({ page: 1, recordsPerPage: 10 }))
      }

    },
    [dispatch],
  )

  return (
    <div>
      <Button onClick={toggleBrandModel} style={{ backgroundColor: ' #30b365' }}>
        + Create New Brand
      </Button>
      <Modal isOpen={openBrandModel} toggle={toggleBrandModel} size="lg" centered>
        <Card>
          <MultiStepProgressBar currentStep={currentStep} />
          {currentStep === 1 && <BrandDetails onSubmit={handleBrandDetail} />}
          {currentStep === 2 && <BrandRelation onSubmit={handleBrandRelation} onBack={() => setCurrentStep(1)} />}
          {currentStep === 3 && <ContactDetails onSubmit={handleContactDetails} onBack={() => setCurrentStep(2)} />}
        </Card>
      </Modal>
    </div>
  )
}
export default CreateBrand
