import * as CONSTS from '../constants/const'

let defaultState = {
  allList: [],
  contactsList: [],
  distributionContacts: {},
  loading: false,
  paginationContactsList: { lists: [], totalCounts: 0 },
  paginationContactsSettings: { pageNumber: 0, sortBy: 'labelName-asc', searchKey: '' },
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case CONSTS.GET_ALL_DISTRIBUTION_LIST:
      return { ...state, allList: action.payload }
    case CONSTS.GET_ALL_DISTRIBUTION_CONTACTS_LIST:
      return { ...state, contactsList: action.payload }
    case CONSTS.GET_ALL_DISTRIBUTION_CONTACTS:
      return { ...state, distributionContacts: action.payload }
    case CONSTS.GET_LOADING_DISTRIBUTION_CONTACTS:
      return { ...state, loading: action.payload }
    case CONSTS.GET_PAGINATION_DISTRIBUTION_CONTACTS:
      return { ...state, paginationContactsList: action.payload }
    case CONSTS.GET_PAGINATION_DISTRIBUTION_CONTACTS_SETTINGS:
      return { ...state, paginationContactsSettings: action.payload }
    default:
      return state
  }
}
