import React from 'react'
import path from 'path'
import { ReactTinyLink } from 'react-tiny-link'
import ReactDOM from 'react-dom'

import { getMedia } from '../../lib/utilities'

import './message.css'

const MessageMedia = (props) => {
  const { message } = props
  const randomNumber = Math.random()

  const loadPreview = (url, randomNumber) => {
    document.getElementById('load_preview_' + randomNumber).innerHTML = ''
    document.getElementById('load_preview_' + randomNumber).classList.remove('preview-older')
    ReactDOM.render(
      <ReactTinyLink cardSize="small" showGraphic={false} maxLine={2} minLine={1} url={url} />,
      document.getElementById('load_preview_' + randomNumber),
    )
  }

  return (
    <div className="message-content messsage-content-no-background mt-2 vcfbox">
      {path.extname(message.media) === '.3gp' ||
      path.extname(message.media) === '.amr' ||
      path.extname(message.media) === '.vcf' ? (
        <a href={message.media} target="_blank" rel="noopener noreferrer">
          {path.basename(message.media)}
        </a>
      ) : (
        getMedia(message.media)
      )}

      {path.extname(message.media) === '.vcf' && (
        <div id={`load_preview_${randomNumber}`} className="link-preview preview-older">
          <div className="load-preview" onClick={(e) => loadPreview(message.media, randomNumber)}>
            Click to Load Preview
          </div>
          <a className="link" target="_blank" rel="noopener noreferrer" href={message.media}>
            {message.media} <i className="fa fa-angle-right arrow-right"></i>
          </a>
        </div>
      )}
    </div>
  )
}

export default MessageMedia
