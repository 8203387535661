import React, { useEffect, useState, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import DateTimePicker from 'react-datetime-picker'

import { editScheduleMessage, deleteScheduleMessage } from '../../actions/message.action'
import './message.css'

const ScheduleMessageModal = (props) => {
  const dispatch = useDispatch()
  const { isModal, toggleModal } = props
  const datePicker = useRef()

  const [scheduleDate, setScheduleDate] = useState(new Date())
  const [scheduleMsg, setScheduleMsg] = useState('')

  const editScheduleData = async () => {
    const account_id = await localStorage.getItem('account_id')
    const data = {
      to_number: props.message.to_number,
      from_number: props.message.from_number,
      text: scheduleMsg,
      sender: props.message.sender,
      media: props.message.media,
      tab: props.message.tab,
      schedule_time: scheduleDate,
      account_name: account_id,
    }
    dispatch(editScheduleMessage(props.message.message_id, data))
    toggleModal()
  }
  const deleteScheduleData = async () => {
    if (props.message.distributionId) {
      const data = {
        to_number: props.message.to_number,
        from_number: props.message.from_number,
      }
      dispatch(deleteScheduleMessage(props.message.message_id, data, props.message.distributionId))
      toggleModal()
    } else {
      const data = {
        to_number: props.message.to_number,
        from_number: props.message.from_number,
      }
      dispatch(deleteScheduleMessage(props.message.message_id, data))
      toggleModal()
    }
  }

  useEffect(() => {
    if (props.message.scheduleMsg) {
      setScheduleDate(new Date(props.message.scheduleTime))
      setScheduleMsg(props.message.text)
    }
  }, [props])

  useEffect(() => {
    if (!datePicker.current) {
      return
    }
    const _this = datePicker.current
    _this.onOutsideAction = (event) => {
      if (
        _this.wrapper &&
        !_this.wrapper.contains(event.target) &&
        typeof event?.target?.className === 'string' &&
        !event?.target?.className?.indexOf('react-calendar')
      ) {
        _this.closeCalendar()
        event.stopPropagation()
      }
    }
  }, [])

  return (
    <Modal
      isOpen={isModal}
      toggle={toggleModal}
      className={`light-modal schedule-modal modal-dialog modal-dialog-centered modal-dialog-zoom`}
    >
      <ModalHeader>Edit Schedule message</ModalHeader>
      <ModalBody>
        <div className="contact">
          <input
            type="text"
            name="label"
            className="form-control"
            placeholder="Message"
            value={scheduleMsg}
            onChange={(e) => setScheduleMsg(e.target.value)}
          />
          <div className="mt-2">
            <DateTimePicker
              onChange={setScheduleDate}
              value={scheduleDate}
              minDate={new Date()}
              disableClock={true}
              ref={datePicker}
              openWidgetsOnFocus={false}
            />
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={() => toggleModal()}>
          Cancel
        </Button>
        <Button color="danger" onClick={deleteScheduleData}>
          Delete
        </Button>
        <Button color="primary" onClick={editScheduleData}>
          Schedule Message
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default ScheduleMessageModal
