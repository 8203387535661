import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import openSocket from 'socket.io-client'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { ToastContainer, toast } from 'react-toastify'
import jsPDF from 'jspdf'
import classnames from 'classnames'
import html2canvas from 'html2canvas'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import _ from 'lodash'
import { Tooltip, TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap'

import Sidebar from '../Sidebar/Sidebar'
import Dialog from '../Sidebar/Dialog'
import Printer from './Printer'
import Alarms from './Alarms'
import ContactDetails from './ContactDetails'
import ContactDetailsSave from './ContactDetailsSave'
import StartConverstaion from './StartConverstaion'

import {
  getUserData,
  sendMessage,
  getMessage,
  getPrintMessage,
  setMemberNum,
  newMessage,
  deleteConversation,
  getCallForward,
  getDevices,
  getFollowDevices,
  startConversation,
  getAllCalls,
  getClioAuth,
  getAllAssignConversations,
  setAssignedMember,
  getAssignedMessage,
  addFavoriteMessage,
  deleteFavoriteMessage,
  removeAssignedConversationToUser,
  getAssignConversation,
  assignConversationToUser,
  getNomalConversations,
  getFavoriteConversations,
  getAssignedConversations,
} from '../../actions/message.action'
import { addRedtailToStorage, getRedTailAuth, userAcceptedRedtail } from '../../actions/redtail.action'
import { getAllUsers } from '../../actions/dashboard.action'
import { getContactList, getDistributionMessage, sendDistributionMessage } from '../../actions/distribution.action'
import { getAllNotifiations } from '../../actions/notification.action'

import * as CONSTS from '../../constants/const'
import './Home.css'
import CONFIG from '../../constants/config'
import alertSound from '../../asset/media/mp3/drop.mp3'

import AssignConversationModal from './AssignConversationModal'
import NormalConversations from './NormalConversations'
import FavoriteConversaions from './FavoriteConversaions'
import UnreadConversations from './UnreadConversations'
import AssignConversations from './AssignConversations'

import { isNumeric, phoneNumFormat, setFormatDate, formatDate, getMemberName } from '../../lib/utilities'
import ScheduleMessageModal from './ScheduleMessageModal'
import Footer from './Footer'
import Header from './Header'
import ChatBody from './ChatBody'

const socket = openSocket(CONFIG.socketURL)

const Home = (props) => {
  const dispatch = useDispatch()
  const {
    loading,
    numbers,
    userName,
    messages,
    allCalls,
    clioSyncState,
    clioAuthToken,
    assignConversations,
    assignConversationsLogs,
    assignedMember,
    assignedConversations,
    assignedMessages,
    mem_number,
    normalConversations,
    favoriteConversations,
    assignConversation,
  } = useSelector((state) => state.message)

  const { redTailSyncState } = useSelector((state) => state.redtail)
  const { voicemails } = useSelector((state) => state.voicemails)
  const { totalMsgCount, soundAlert, callFlowID, numberList } = useSelector((state) => state.message.numbers)
  const { users } = useSelector((state) => state.dashboard)

  const [searchNumber, setSearchNumber] = useState('')
  const [values, updateValues] = useState({
    phoneNum: '',
    msgText: '',
    distribution: false,
    distributionId: '',
    distributionSubscribe: false,
    optStatus: false,
  })

  const [callDetails, updateCallDetails] = useState([])
  const [toogleSidebar, updateToggleSidebar] = useState(false)
  const [printerToogle, updatePrinter] = useState(false)
  const [setNumberToogle, updateSetNumber] = useState(false)
  const [userNumPanelToggle, updateUserNumPanel] = useState(false)
  const [contactToogle, updateContactUs] = useState(false)
  const [conversationToogle, updateConversation] = useState(false)
  const [tooltipOpen1, setTooltipOpen1] = useState(false)
  const [tooltipOpen2, setTooltipOpen2] = useState(false)
  const [startDate, updateStartDate] = useState(new Date(new Date().setDate(new Date().getDate() - 7)))
  const [endDate, updateEndDate] = useState(new Date())
  const [adminPhoneNum, updateAdminPhoneNum] = useState('')
  const [uploadImgName, updateuploadImgName] = useState('')
  const [adminUseremail, updateAdminUseremail] = useState('')
  const [msgNofications, updateMsgNotifications] = useState([])
  const [state, setState] = useState({})
  const messagesEndRef = useRef(null)
  const uploadInput = useRef(null)
  const uploadAvatarRef = useRef(null)
  const [audio] = useState(new Audio(alertSound))
  const [soundNotify, setSoundAlert] = useState(false)
  const [favTab, updateFavTab] = useState('favTab1')
  const [newMessages, setnewMessages] = useState([])
  const [showContactInformationPane, setContactInformationPane] = useState(false)
  const [showContactDialog, setshowContactDialog] = useState(false)
  const [memberInformation, setMemberIformation] = useState([])
  const [filteredNormlMembers, setFilteredNormMembers] = useState([])
  const [selectedContact, setSelected] = useState(null)
  const [filteredFavMembers, setFilteredFavMembers] = useState([])
  const [unreadMembers, setUnreadMembers] = useState([])
  const [isMemberDataFetched, setMemberDataFetched] = useState(false)
  const [assignedConversation, setAssignedConversation] = useState([])
  const [newAssignedMessges, setNewAssignedMessges] = useState([])
  const [messagesList, setMessagesList] = useState([])
  const [normalTotalCount, setNormalTotalCount] = useState(0)
  const [allUserNames, setAllUserNames] = useState([])
  const [isScheduleModal, setIsScheduleModal] = useState(false)
  const [assignConversationModal, setAssignConversationModal] = useState(false)
  const [assignMemberNum, setAssignMemberNum] = useState(0)
  const [phoneState, setPhoneState] = useState(false)
  const [favTotalCount, setFavTotalCount] = useState(0)
  const [assignTotalCount, setAssignTotalCount] = useState(0)
  const [showInfoPane, setShowInfoPane] = useState(false)
  const [scheduleMessage, setScheduleMessage] = useState({})

  const owner_id = localStorage.getItem('user_id')

  const scheduleToggleModal = () => {
    setIsScheduleModal(!isScheduleModal)
  }
  const toggleTooltip1 = () => setTooltipOpen1(!tooltipOpen1)
  const toggleTooltip2 = () => setTooltipOpen2(!tooltipOpen2)
  const toggleTooltip4 = (targetName) => {
    if (!state[targetName]) {
      setState({ ...state, [targetName]: true })
    } else {
      setState({ ...state, [targetName]: !state[targetName] })
    }
  }

  const isToolTipOpen = (targetName) => {
    return state[targetName] ? state[targetName] : false
  }

  useEffect(() => {
    const dataArray = []
    users &&
      users.forEach((user) => {
        dataArray.push({
          email: user.username,
          userName: user.first_name + ' ' + user.last_name,
          state: false,
          userId: user.id,
        })
      })
    setAllUserNames(dataArray)
    // eslint-disable-next-line
  }, [users])

  const handleValues = (e) => {
    if (e.target.name === 'phoneNum') {
      updateValues({
        ...values,
        [e.target.name]: e.target.value,
      })
    } else if (e.target.name === 'msgText' || e.target.id === 'msgText') {
      updateValues({ ...values, msgText: e.target.value })
    }
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      sendingMessage()
    }
  }

  const handleSearch = () => {
    dispatch(getNomalConversations(numbers.savedNumber, userName.userEmail, searchNumber))
    dispatch(getFavoriteConversations(numbers.savedNumber, userName.userEmail, searchNumber))
    dispatch(getAssignedConversations(searchNumber))
  }

  const changeSetNumberModal = () => {
    if (!setNumberToogle) {
      dispatch(getCallForward())
    }
    updateSetNumber(!setNumberToogle)
  }
  const contactUsModal = () => {
    updateContactUs(!contactToogle)
  }
  const userPhoneNumModal = () => {
    updateUserNumPanel(!userNumPanelToggle)
  }
  const changeSidebar = () => {
    updateToggleSidebar(!toogleSidebar)
  }
  const sendingMessage = () => {
    updateValues({ ...values, msgText: values.msgText })
    if (favTab !== 'favTab3') {
      if (!adminPhoneNum) {
        changeSetNumberModal()
      } else if (values.phoneNum) {
        if (values.distribution) {
          dispatch(
            sendDistributionMessage(
              values.msgText,
              uploadImgName,
              adminPhoneNum,
              values.phoneNum,
              selectedContact.distributionId,
            ),
          )
        } else {
          dispatch(sendMessage(values.phoneNum, adminPhoneNum, values.msgText, adminUseremail, uploadImgName, favTab))
        }
      }
    } else if (Object.keys(assignedMember).length > 0) {
      if (values.phoneNum) {
        dispatch(
          sendMessage(
            values.phoneNum,
            assignedMember.assigner_number,
            values.msgText,
            adminUseremail,
            uploadImgName,
            favTab,
          ),
        )
      }
    }
    uploadInput.current = null
    updateValues({ ...values, msgText: '' })
    updateuploadImgName('')
  }
  const imageUpload = async (ev) => {
    ev.preventDefault()
    const data = new FormData()
    data.append('file', uploadInput.current.files[0])
    await axios
      .post(`${CONFIG.serverURL}/fileupload`, data, {
        headers: { 'X-Requested-With': 'XMLHttpRequest' },
      })
      .then((response) => {
        updateuploadImgName(response.data.file)
      })
  }

  const uploadAvatar = (ev) => {
    ev.preventDefault()
    dispatch({ type: CONSTS.SET_LOADING_USER_DATA, payload: true })
    const data = new FormData()
    data.append('file', uploadAvatarRef.current.files[0])
    data.append('userId', owner_id)
    axios
      .post(`${CONFIG.serverURL}/uploadavatar`, data, {
        headers: { 'X-Requested-With': 'XMLHttpRequest' },
      })
      .then((response) => {
        dispatch(getUserData())
      })
  }

  const setMemNumber = async (
    number,
    distribution = false,
    distributionId,
    distributionSubscribe = false,
    optStatus = false,
  ) => {
    await updateValues({
      ...values,
      msgText: '',
      phoneNum: number,
      distribution,
      distributionId,
      distributionSubscribe,
      optStatus,
    })
    await dispatch(setMemberNum(number))
    await updateCallDetailsData(number)
    if (distribution) {
      await dispatch(getDistributionMessage(adminPhoneNum, number))
    } else {
      await dispatch(getMessage(number, adminPhoneNum, owner_id))
    }
  }

  const updateCallDetailsData = async (number) => {
    const callDetails = []
    allCalls &&
      (await allCalls.forEach((call) => {
        if (call.caller_id_number === number || call.dialed_number === number) {
          call.unix_time = Number(call.unix_timestamp)
          callDetails.push(call)
        }
      }))
    callDetails.sort(async (a, b) => (await a.unix_time) - b.unix_time)

    await updateCallDetails(callDetails)
  }

  const updateNewMessageDetails = async (messages, callDetails) => {
    let temp = [...messages]
    let tempSearch = []
    temp.push(...callDetails)
    let filterAssignConversations = assignConversationsLogs.filter(
      (item) => values.phoneNum && item.assigned_number === values.phoneNum,
    )
    temp.push(...filterAssignConversations)
    temp.map((item) => {
      if (item?.unix_timestamp) {
        item.unix_time = Number(item.unix_timestamp)
      } else {
        item.unix_time = Number((new Date(item.createdAt).getTime() / 1000).toFixed())
      }
      if (
        (item.text !== '' &&
          item.media === '' &&
          values.phoneNum === item.to_number &&
          adminPhoneNum === item.from_number &&
          item.direction === 'out') ||
        (values.phoneNum === item.from_number && adminPhoneNum === item.to_number && item.direction === 'in')
      ) {
        tempSearch.push(item)
      }
      return item
    })
    temp.sort((a, b) => a.unix_time - b.unix_time)
    await setnewMessages(temp)
  }
  const updateNewAssignMessageDetails = async (messages, callDetails) => {
    let temp = [...messages]
    let tempSearch = []
    temp.push(...callDetails)
    let filterAssignConversations = assignConversationsLogs.filter(
      (item) => values.phoneNum && item.assigned_number === values.phoneNum,
    )
    temp.push(...filterAssignConversations)
    temp.map((item) => {
      if (item?.unix_timestamp) {
        item.unix_time = Number(item.unix_timestamp)
      } else {
        item.unix_time = Number((new Date(item.createdAt).getTime() / 1000).toFixed())
      }
      if (item.text !== '' && item.media === '') {
        tempSearch.push(item)
      }
      return item
    })
    temp.sort((a, b) => a.unix_time - b.unix_time)
    setNewAssignedMessges(temp)
  }

  const inComingMssage = async (data) => {
    await dispatch(newMessage(data))
  }

  const sendScheduleMessage = async (data) => {
    if (data.tab !== 'favTab3') {
      dispatch(getMessage(data.to_number, data.from_number, owner_id))
    } else {
      dispatch(getAssignedMessage(assignedMember))
    }
  }

  const conversationModal = () => {
    if (!conversationToogle) {
      updateValues({ ...values, phoneNum: '' })
      dispatch(setMemberNum(''))
    }
    updateConversation(!conversationToogle)
  }

  const startConverstaion = async (uplaodConversation) => {
    const numberSort = values.phoneNum.replace(/[\s&\\#+()$~%.'":*?<>{}-]/g, '')
    updateValues({ ...values, phoneNum: numberSort })
    const array = numberSort.split(',')
    if (array.length > 0 && array.length < 101) {
      const phoneArray = []
      await array.forEach((item) => {
        if (isNumeric(item) && item.length === 10) {
          phoneArray.push(`+1${item}`)
        } else {
          toast.warn(`Please check Phone Number(${item})'s type and length`, {
            position: toast.POSITION.TOP_RIGHT,
          })
        }
      })
      if (values.msgText === '') {
        toast.warn('Please input message.', {
          position: toast.POSITION.TOP_RIGHT,
        })
      } else {
        dispatch(
          startConversation(
            phoneArray,
            adminPhoneNum,
            values.msgText,
            userName.userEmail,
            userName.fullName,
            uplaodConversation,
          ),
        )
        updateValues({ ...values, msgText: '' })
        conversationModal()
      }
    } else {
      toast.warn('You can input up to 10 Phone Numbers', {
        position: toast.POSITION.TOP_RIGHT,
      })
      return
    }
  }

  const printerModal = () => {
    updatePrinter(!printerToogle)
    dispatch(
      getPrintMessage(
        values.phoneNum,
        numbers.savedNumber,
        setFormatDate(startDate, 'start'),
        setFormatDate(endDate, 'end'),
      ),
    )
  }

  const exportPDF = async () => {
    html2canvas(document.getElementsByClassName('messages')[0], {
      scale: 4,
    }).then((canvas) => {
      const imgData = canvas.toDataURL('image/jpeg')
      const imgWidth = 204
      const pageHeight = 280
      const imgHeight = (canvas.height * imgWidth) / canvas.width + 1
      let heightLeft = imgHeight
      const doc = new jsPDF('p', 'mm', 'a4')
      let position = 30
      let headerContent = {}
      headerContent.textContentLine1 = 'SMS Conversation with ' + values.phoneNum + ' from ' + numbers.savedNumber
      headerContent.textContentLine2 = formatDate(startDate, true, true) + ' - ' + formatDate(endDate, true, true)
      doc.setFontSize(14)
      doc.text(headerContent.textContentLine1, 10, 15)
      doc.text(headerContent.textContentLine2, 10, 25)
      doc.addImage(imgData, 'JPEG', 3, position, imgWidth, imgHeight)
      heightLeft -= pageHeight
      while (heightLeft >= 0) {
        position = heightLeft - imgHeight
        doc.addPage()
        doc.addImage(imgData, 'JPEG', 3, position, imgWidth, imgHeight)
        heightLeft -= pageHeight
      }
      doc.save(`message-${formatDate(startDate)}_${formatDate(endDate)}.pdf`)
    })
    updatePrinter(!printerToogle)
    dispatch(getMessage(values.phoneNum, adminPhoneNum, owner_id))
  }

  const startDateChange = (date) => {
    updateStartDate(date)
    dispatch(
      getPrintMessage(
        values.phoneNum,
        numbers.savedNumber,
        setFormatDate(date, 'start'),
        setFormatDate(endDate, 'end'),
      ),
    )
  }

  const endDateChange = (date) => {
    updateEndDate(date)
    dispatch(
      getPrintMessage(
        values.phoneNum,
        numbers.savedNumber,
        setFormatDate(startDate, 'start'),
        setFormatDate(date, 'end'),
      ),
    )
  }

  const deleteHistory = () => {
    dispatch(deleteConversation(values.phoneNum, adminPhoneNum, userName.userEmail))
  }

  const isContactInformation = (val, user) => {
    setContactInformationPane(val)
    if (val && user) {
      if (val === true) {
        dispatch(getAllCalls())
      }
      setMemberIformation(user)
    }
  }

  const isContactDialog = (val, user) => {
    setshowContactDialog(val)
    if (val && user) {
      if (val === true) {
        dispatch(getAllCalls())
      }
      setMemberIformation(user)
    }
  }
  const toggleContactDialog = (val) => {
    setshowContactDialog(val)
  }

  const favTabToggole = (tab) => {
    if (favTab !== tab) {
      updateFavTab(tab)
    }
    if (tab !== 'favTab3') {
      updateAdminPhoneNum(numbers.savedNumber)
      updateAdminUseremail(userName.userEmail)
    }
  }

  const [addCall, setAddCall] = useState(false)
  const call = () => {
    setAddCall(true)
  }
  const checkAssignConversation = (number) => {
    return assignConversations.findIndex((item) => item.assigned_number === number) === -1 ? false : true
  }
  const checkAssignConversationLength = (number) => {
    const state = assignConversations.filter((item) => item.assigned_number === number)
    return state.length
  }

  const changeAssignedConversation = async (conversation) => {
    updateValues({
      ...values,
      phoneNum: conversation.assigned_number,
    })
    await dispatch(setAssignedMember(conversation))
    await updateAdminPhoneNum(conversation.assigner_number)
    await dispatch(getAssignedMessage(conversation))
    await updateCallDetailsData(conversation.assigner_number)
    await dispatch(getAllAssignConversations(conversation.assigned_number, conversation.assigner_number))
  }

  useEffect(() => {
    if (messages.length > 0) {
      updateNewMessageDetails(messages, callDetails)
    }
    // eslint-disable-next-line
  }, [messages, callDetails, assignConversationsLogs])

  useEffect(() => {
    if (assignedMessages.length > 0) {
      updateNewAssignMessageDetails(assignedMessages, callDetails)
    }
    // eslint-disable-next-line
  }, [assignedMessages, callDetails, assignConversationsLogs])

  useEffect(() => {
    if (numberList && numberList.length > 0) {
      const data = {
        show: true,
        user_id: owner_id,
      }
      localStorage.setItem('showNumber', JSON.stringify(data))
    } else {
      localStorage.removeItem('showNumber')
    }
  }, [numberList, owner_id])

  useEffect(() => {
    setFilteredNormMembers(normalConversations?.normalConversationsList)
    const normalPageCount =
      Number(parseInt(normalConversations.normalTotalCount / 9)) +
      Number(normalConversations.normalTotalCount % 9 > 0 ? 1 : 0) -
      1
    setNormalTotalCount(normalPageCount)
    setUnreadMembers(normalConversations.unreadMem)
    // eslint-disable-next-line
  }, [normalConversations])

  useEffect(() => {
    const assignPages =
      Number(parseInt(assignedConversations?.assignedConversationTotalCount) / 9) +
      Number(assignedConversations?.assignedConversationTotalCount % 9 > 0 ? 1 : 0) -
      1
    setAssignTotalCount(assignPages)
    setAssignedConversation(assignedConversations?.assignedConversationList)
  }, [assignedConversations])

  useEffect(() => {
    setFilteredFavMembers(favoriteConversations?.favConversationsList)
    const favPageCount =
      Number(parseInt(favoriteConversations.favoriteTotalCount) / 9) +
      Number(favoriteConversations.favoriteTotalCount % 9 > 0 ? 1 : 0) -
      1

    setFavTotalCount(favPageCount)
    // eslint-disable-next-line
  }, [favoriteConversations])

  useEffect(() => {
    if (numbers.savedNumber && userName.userEmail) {
      updateUserNumPanel(false)
      if (favTab === 'favTab3' && Object.keys(assignedMember).length > 0) {
        updateAdminPhoneNum(assignedMember.assigner_number)
      } else {
        updateAdminPhoneNum(numbers.savedNumber)
        updateAdminUseremail(userName.userEmail)
      }
      dispatch(getMessage(values.phoneNum, numbers.savedNumber, owner_id))
      if (isMemberDataFetched === false && userName.userEmail) {
        setMemberDataFetched(true)
      }

      dispatch(getNomalConversations(numbers.savedNumber, userName.userEmail, searchNumber))
      dispatch(getFavoriteConversations(numbers.savedNumber, userName.userEmail, searchNumber))
      dispatch(getAssignedConversations(searchNumber))
    }
    dispatch(getAllAssignConversations(values.phoneNum, numbers.savedNumber))
    // eslint-disable-next-line
  }, [numbers.savedNumber, userName.userEmail])

  useEffect(() => {
    if (normalConversations && !normalConversations.notifies) {
      updateMsgNotifications([])
    } else {
      const dif = _.differenceWith(normalConversations.notifies, msgNofications, _.isEqual)
      if (dif.length > 0) {
        updateMsgNotifications(normalConversations.notifies)
      }
    }
    // eslint-disable-next-line
  }, [normalConversations.notifies])

  useEffect(() => {
    msgNofications &&
      msgNofications.forEach((notify) => {
        toast.success(`You have unread  ${notify.newMsg} text messages from ${phoneNumFormat(notify.number)}!`, {
          position: toast.POSITION.TOP_RIGHT,
        })
        if (soundNotify) audio.play()
      })
    // eslint-disable-next-line
  }, [msgNofications])

  useEffect(() => {
    if (callFlowID) {
      dispatch(getFollowDevices(callFlowID))
    }
    // eslint-disable-next-line
  }, [callFlowID])

  useEffect(() => {
    if (values.phoneNum === '') {
      dispatch({ type: CONSTS.GET_ALL_MESSAGES, payload: [] })
    }
    if (values.distribution) {
      dispatch(getContactList(values.phoneNum))
    }
    // eslint-disable-next-line
  }, [values])

  useEffect(() => {
    setSoundAlert(soundAlert)
  }, [soundAlert])

  useEffect(() => {
    if (messagesList.length > 0) messagesEndRef.current.scrollIntoView()
  }, [messagesList])

  useEffect(() => {
    if (favTab !== 'favTab3') {
      setMessagesList(newMessages)
      updateValues({ ...values, phoneNum: mem_number })
      updateAdminPhoneNum(numbers.savedNumber)
    } else {
      setMessagesList(newAssignedMessges)
      updateValues({ ...values, phoneNum: assignedMember.assigned_number })
      updateAdminPhoneNum(assignedMember.assigner_number)
    }
    // eslint-disable-next-line
  }, [favTab, newMessages, newAssignedMessges])

  useEffect(() => {
    if (clioSyncState && clioAuthToken === undefined) {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="redtail-model-ui">
              <h1>Alert</h1>
              <p>Would you connect to Clio?</p>
              <button onClick={onClose}>No</button>
              <button
                onClick={() => {
                  dispatch(getClioAuth())
                  onClose()
                }}
              >
                Yes
              </button>
            </div>
          )
        },
      })
    }
    // eslint-disable-next-line
  }, [clioAuthToken, clioSyncState])

  useEffect(() => {
    if (redTailSyncState.length === 0) {
      const item = userAcceptedRedtail()
      if (item.confirmed !== undefined || item.disabled === true) {
        return
      }
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="redtail-model-ui">
              <h1>Alert</h1>
              <p>Would you connect to Redtail?</p>
              <button
                onClick={() => {
                  addRedtailToStorage(localStorage.getItem('user_id'), false)
                  onClose()
                }}
              >
                No
              </button>
              <button
                onClick={() => {
                  dispatch(getRedTailAuth())
                  onClose()
                }}
              >
                Yes
              </button>
            </div>
          )
        },
      })
    }
    // eslint-disable-next-line
  }, [redTailSyncState])

  useEffect(() => {
    dispatch(getCallForward())
    dispatch(getDevices())
    dispatch(getAllUsers())
    dispatch(getAllNotifiations())

    socket.on('incomMessage', (data) => {
      if (data.state === 'success') {
        inComingMssage(data)
      }
    })
    socket.on('scheduleMessage', (data) => {
      sendScheduleMessage(data)
    })
    // eslint-disable-next-line
  }, [])

  const deleteFavorite = (toNumber) => {
    const currentUser = allUserNames.find((user) => user.email === userName.userEmail)
    dispatch(removeAssignedConversationToUser(currentUser, toNumber))
    dispatch(deleteFavoriteMessage(adminPhoneNum, toNumber, userName.userEmail))
  }

  const addFavorite = (toNumber) => {
    dispatch(addFavoriteMessage(adminPhoneNum, toNumber, userName.userEmail))
  }

  const assignConversationShowPopup = (member) => {
    if (favTab !== 'favTab3') {
      setAssignMemberNum(member.memberNum)
      dispatch(getAssignConversation(member.memberNum, adminPhoneNum))
      setAssignConversationModal(!assignConversationModal)
    } else {
      setAssignMemberNum(member.item.assigned_number)
      dispatch(getAssignConversation(member.item.assigned_number, member.item.assigner_number))
      setAssignConversationModal(!assignConversationModal)
    }
  }

  const toggleAssignConversationPopup = () => {
    setAssignConversationModal(!assignConversationModal)
  }

  const saveAssignConversation = (toNumber) => {
    const owner_id = localStorage.getItem('user_id')
    const userData = {
      assigned_number: toNumber,
      assigner_number: adminPhoneNum,
      assigner_email: userName.userEmail,
      assigner_userid: owner_id,
    }
    dispatch(assignConversationToUser(allUserNames, userData))
    toggleAssignConversationPopup()
  }

  useEffect(() => {
    const dataArray = []
    users &&
      users.forEach((user) => {
        dataArray.push({
          email: user.username,
          userName: user.first_name + ' ' + user.last_name,
          state: false,
          userId: user.id,
          toNumber: memberInformation.memberNum,
        })
      })
    setAllUserNames(dataArray)
    // eslint-disable-next-line
  }, [users, memberInformation])

  const changeConversationAssignState = (e) => {
    const userData = [...allUserNames]
    const index = allUserNames.findIndex((user) => user.email === e.target.id)
    userData[index].state = e.target.checked
    setAllUserNames(userData)
  }

  const setUserData = async (userData) => {
    if (userData && userData.length > 0) {
      if (assignConversation && assignConversation.length > 0) {
        assignConversation.forEach((assignuser) => {
          const users = [...userData]
          const index = users.findIndex(
            (user) =>
              user.email === assignuser.assigned_email &&
              user.userId === assignuser.assigned_userid &&
              user.toNumber === assignuser.assigned_number,
          )
          if (index !== -1) {
            users[index]['state'] = assignuser.state
          }
          setAllUserNames(users)
        })
      } else {
        const userData = await allUserNames.map((e) => ({
          ...e,
          state: false,
        }))
        setAllUserNames(userData)
      }
    }
  }

  useEffect(() => {
    setUserData(allUserNames)
    // eslint-disable-next-line
  }, [assignConversation])

  const checkNotifies = (number) => {
    return normalConversations.notifies &&
      normalConversations.notifies.findIndex((count) => count.number === number) === -1
      ? false
      : true
  }

  return (
    <div className="light">
      {loading && (
        <div className="loader-container">
          <div className="loader" />
        </div>
      )}
      <ToastContainer autoClose={8000} />
      <Alarms />

      <div className="layout">
        <Header
          printerModal={printerModal}
          call={call}
          phoneState={phoneState}
          isContactInformation={isContactInformation}
          setShowInfoPane={setShowInfoPane}
          setMemNumber={setMemNumber}
          memberInformation={memberInformation}
          uploadAvatarRef={uploadAvatarRef}
          uploadAvatar={uploadAvatar}
          numbers={numbers}
          userName={userName}
        />
        <div className="chat-upper">
          <Sidebar
            changeSidebar={changeSidebar}
            toogleSidebar={toogleSidebar}
            contactUsModal={contactUsModal}
            changeSetNumberModal={changeSetNumberModal}
            userPhoneNumModal={userPhoneNumModal}
            history={props.history}
          />
          <div className="content">
            <div className={`sidebar-group ${toogleSidebar ? 'open' : ''}`}>
              <div id="chats" className="sidebar active">
                <button type="button" className="close d-none" aria-label="Close" onClick={changeSidebar}>
                  <span aria-hidden="true">×</span>
                </button>
                <div className="row mb-3 px-3">
                  <div className="col-12 autocomplete-wrapper" title="Search Chat">
                    <div className="search-btns align-items-center">
                      <input
                        type="text"
                        className="form-control search-input"
                        id="search"
                        placeholder="Search Number"
                        value={searchNumber}
                        onChange={(e) => setSearchNumber(e.target.value)}
                        onKeyDown={(event) => {
                          if (event.key === 'Enter') {
                            handleSearch()
                          }
                        }}
                      />
                      <div className="home-search">
                        <i className="fas fa-search"></i>
                      </div>
                    </div>
                  </div>
                  <div className="sms-group">
                    <div className="btn btn-light new-sms" id="newConversatoion" onClick={conversationModal}>
                      <i className="fas fa-comment sms-icon"></i>
                    </div>
                    <Tooltip
                      placement="top"
                      isOpen={tooltipOpen1}
                      autohide={false}
                      target="newConversatoion"
                      toggle={toggleTooltip1}
                    >
                      New Conversation
                    </Tooltip>
                  </div>
                </div>
                <Nav variant="pills" tabs className="flex-row chat-side-header">
                  <NavItem>
                    <NavLink
                      className={classnames(
                        {
                          active: favTab === 'favTab1',
                        },
                        'conversationTab',
                      )}
                      onClick={() => {
                        favTabToggole('favTab1')
                      }}
                    >
                      Conversation
                    </NavLink>
                  </NavItem>
                  {normalConversations.notifies && normalConversations.notifies.length > 0 && (
                    <NavItem>
                      <NavLink
                        className={classnames(
                          {
                            active: favTab === 'favTab4',
                          },
                          'unreadTab',
                        )}
                        onClick={() => {
                          favTabToggole('favTab4')
                        }}
                      >
                        <div className="unread-message-count mr-1">{normalConversations.notifies.length}</div>
                      </NavLink>
                    </NavItem>
                  )}
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: favTab === 'favTab2',
                      })}
                      onClick={() => {
                        favTabToggole('favTab2')
                      }}
                    >
                      <div className="d-flex">
                        {favoriteConversations.favoriteTotalCount > 0 && (
                          <div
                            className={`${
                              favoriteConversations.favoriteTotalCount > 0
                                ? 'unread-message-count mr-1'
                                : 'unread-message-count mr-1'
                            }`}
                          >
                            {favoriteConversations.favoriteTotalCount}
                          </div>
                        )}
                        <div>Important</div>
                      </div>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: favTab === 'favTab3',
                      })}
                      onClick={() => {
                        favTabToggole('favTab3')
                      }}
                    >
                      <div className="d-flex">
                        {assignedConversations.assignedConversationTotalCount > 0 && (
                          <div className="unread-message-count  mr-1">
                            {assignedConversations.assignedConversationTotalCount}
                          </div>
                        )}
                        <div>Assigned</div>
                      </div>
                    </NavLink>
                  </NavItem>
                </Nav>
                <div className="sidebar-body">
                  <PerfectScrollbar>
                    <TabContent activeTab={favTab}>
                      <TabPane tabId="favTab1">
                        <NormalConversations
                          filteredNormlMembers={filteredNormlMembers}
                          setSelected={setSelected}
                          showInfoPane={showInfoPane}
                          isContactInformation={isContactInformation}
                          setMemberIformation={setMemberIformation}
                          setMemNumber={setMemNumber}
                          userNumber={values.phoneNum}
                          checkAssignConversation={checkAssignConversation}
                          checkNotifies={checkNotifies}
                          isToolTipOpen={isToolTipOpen}
                          toggleTooltip4={toggleTooltip4}
                          deleteFavorite={deleteFavorite}
                          addFavorite={addFavorite}
                          assignConversationShowPopup={assignConversationShowPopup}
                          checkAssignConversationLength={checkAssignConversationLength}
                          adminPhoneNum={adminPhoneNum}
                          userName={userName}
                          deleteHistory={deleteHistory}
                          favoriteConversations={favoriteConversations.favConversationsList}
                          isContactDialog={isContactDialog}
                          normalTotalCount={normalTotalCount}
                          searchNumber={searchNumber}
                        />
                      </TabPane>
                      <TabPane tabId="favTab2">
                        <FavoriteConversaions
                          filteredFavMembers={filteredFavMembers}
                          setMemNumber={setMemNumber}
                          userNumber={values.phoneNum}
                          checkAssignConversation={checkAssignConversation}
                          checkNotifies={checkNotifies}
                          isToolTipOpen={isToolTipOpen}
                          toggleTooltip4={toggleTooltip4}
                          deleteFavorite={deleteFavorite}
                          assignConversationShowPopup={assignConversationShowPopup}
                          checkAssignConversationLength={checkAssignConversationLength}
                          adminPhoneNum={adminPhoneNum}
                          userName={userName}
                          favoriteConversations={favoriteConversations.favConversationsList}
                          isContactDialog={isContactDialog}
                          favTotalCount={favTotalCount}
                          searchNumber={searchNumber}
                        />
                      </TabPane>
                      <TabPane tabId="favTab3">
                        <AssignConversations
                          assignedConversation={assignedConversation}
                          assignedMember={assignedMember}
                          changeAssignedConversation={changeAssignedConversation}
                          checkAssignConversation={checkAssignConversation}
                          checkNotifies={checkNotifies}
                          isToolTipOpen={isToolTipOpen}
                          toggleTooltip4={toggleTooltip4}
                          deleteFavorite={deleteFavorite}
                          addFavorite={addFavorite}
                          assignConversationShowPopup={assignConversationShowPopup}
                          checkAssignConversationLength={checkAssignConversationLength}
                          adminPhoneNum={adminPhoneNum}
                          userName={userName}
                          deleteHistory={deleteHistory}
                          favoriteConversations={favoriteConversations.favConversationsList}
                          isContactDialog={isContactDialog}
                          assignTotalCount={assignTotalCount}
                          searchNumber={searchNumber}
                        />
                      </TabPane>
                      <TabPane tabId="favTab4">
                        <UnreadConversations
                          unreadMembers={unreadMembers}
                          isContactInformation={isContactInformation}
                          setMemberIformation={setMemberIformation}
                          setMemNumber={setMemNumber}
                          userNumber={values.phoneNum}
                          checkAssignConversation={checkAssignConversation}
                          checkNotifies={checkNotifies}
                          isToolTipOpen={isToolTipOpen}
                          toggleTooltip4={toggleTooltip4}
                          deleteFavorite={deleteFavorite}
                          addFavorite={addFavorite}
                          assignConversationShowPopup={assignConversationShowPopup}
                          checkAssignConversationLength={checkAssignConversationLength}
                          adminPhoneNum={adminPhoneNum}
                          userName={userName}
                          deleteHistory={deleteHistory}
                          favoriteConversations={favoriteConversations.favConversationsList}
                          isContactDialog={isContactDialog}
                          searchNumber={searchNumber}
                        />
                      </TabPane>
                    </TabContent>
                  </PerfectScrollbar>
                </div>
              </div>
            </div>
            <div className="chat">
              <div className="chat-header">
                <div className="chat-header-user">
                  <div className="conversation-header">
                    <h5>Conversations</h5>
                    <div className="user-number">
                      <small className="text-muted phoneNumber-admin">
                        <i>{adminPhoneNum ? phoneNumFormat(adminPhoneNum) : 'Phone Number'}</i>
                      </small>
                      <div
                        className="btn number-btn position-number-dropdown"
                        id="userPhonenumber"
                        onClick={userPhoneNumModal}
                      >
                        <i className="fas fa-caret-down"></i>
                        {totalMsgCount > 0 && (
                          <div className="unread-message-count total-msg-count ml-2">{totalMsgCount}</div>
                        )}
                      </div>
                      <Tooltip
                        placement="top"
                        isOpen={tooltipOpen2}
                        autohide={false}
                        target="userPhonenumber"
                        toggle={toggleTooltip2}
                      >
                        Phone Number
                      </Tooltip>
                    </div>
                  </div>
                </div>
              </div>
              <ChatBody
                messagesList={messagesList}
                values={values}
                adminPhoneNum={adminPhoneNum}
                memberInformation={memberInformation}
                scheduleToggleModal={scheduleToggleModal}
                setScheduleMessage={setScheduleMessage}
                messagesEndRef={messagesEndRef}
              />
              <Footer
                uploadInput={uploadInput}
                imageUpload={imageUpload}
                values={values}
                handleValues={handleValues}
                handleKeyDown={handleKeyDown}
                updateValues={updateValues}
                sendingMessage={sendingMessage}
                fromNumber={favTab !== 'favTab3' ? adminPhoneNum : assignedMember.assigner_number}
                favTab={favTab}
                uploadImgName={uploadImgName}
                adminUseremail={adminUseremail}
              />
            </div>
          </div>
        </div>
      </div>
      <Dialog
        setNumberToogle={setNumberToogle}
        userNumPanelToggle={userNumPanelToggle}
        changeSetNumberModal={changeSetNumberModal}
        userPhoneNumModal={userPhoneNumModal}
        contactUsModal={contactUsModal}
        contactToogle={contactToogle}
        values={values}
        addCall={addCall}
        setAddCall={setAddCall}
        phoneState={phoneState}
        setPhoneState={setPhoneState}
      />
      <StartConverstaion
        conversationToogle={conversationToogle}
        conversationModal={conversationModal}
        handleValues={handleValues}
        uploadInput={uploadInput}
        values={values}
        adminUseremail={adminUseremail}
        updateValues={updateValues}
        startConverstaion={startConverstaion}
        fromNumber={adminPhoneNum}
        imageUpload={imageUpload}
        uploadImgName={uploadImgName}
        userName={userName.fullName}
      />
      {showContactInformationPane && (
        <ContactDetails
          isContactInformation={isContactInformation}
          memberInformation={memberInformation}
          callDetails={callDetails}
          voiceMailDetails={voicemails[0]}
          memberName={getMemberName(memberInformation)}
          setShowInfoPane={setShowInfoPane}
        />
      )}
      {showContactDialog && (
        <ContactDetailsSave
          isContactDialog={isContactDialog}
          showContactDialog={showContactDialog}
          toggleContactDialog={toggleContactDialog}
          fromNumber={adminPhoneNum}
          memberInformation={memberInformation}
        />
      )}
      {assignConversationModal && (
        <AssignConversationModal
          tab="favTab1"
          assignConversationShowPopup={assignConversationShowPopup}
          toggleAssignConversationPopup={toggleAssignConversationPopup}
          toNumber={assignMemberNum}
          fromNumber={adminPhoneNum}
          email={userName.userEmail}
          allUserNames={allUserNames}
          saveAssignConversation={saveAssignConversation}
          changeConversationAssignState={changeConversationAssignState}
          assignConversationModal={assignConversationModal}
        />
      )}
      {printerToogle && (
        <Printer
          printerToogle={printerToogle}
          printerModal={printerModal}
          startDate={startDate}
          endDate={endDate}
          startDateChange={startDateChange}
          endDateChange={endDateChange}
          exportPDF={exportPDF}
        />
      )}
      {isScheduleModal && (
        <ScheduleMessageModal message={scheduleMessage} isModal={isScheduleModal} toggleModal={scheduleToggleModal} />
      )}
    </div>
  )
}

export default Home
