import axios from 'axios'
import { logOut } from './auth.action'
import * as CONSTS from '../constants/const'
import CONFIG from '../constants/config'

export const getallvmboxes = () => {
  return async (dispatch) => {
    try {
      const account_id = localStorage.getItem('account_id')
      const user_id = localStorage.getItem('user_id')
      const auth_token = localStorage.getItem('token')

      axios.defaults.headers.common['X-AUTH-TOKEN'] = auth_token

      const voicemailsURL = `${CONFIG.API_URL}/accounts/${account_id}/vmboxes?filter_owner_id=${user_id}`
      const voicemailsResponse = await axios.get(voicemailsURL)

      const vmBoxes = voicemailsResponse.data.data
      const authToken = voicemailsResponse.data.auth_token

      const promises = vmBoxes.map(async (vmBox) => {
        const url = `${CONFIG.API_URL}/accounts/${account_id}/vmboxes/${vmBox.id}/messages?paginate=false`
        axios.defaults.headers.common['X-AUTH-TOKEN'] = authToken
        const res = await axios.get(url)
        const messages = res.data.data
        const newMsgs = messages.filter((message) => message.folder === 'new')
        const vmBoxId = res.request.responseURL.split('/')[7]
        const foundVmBox = vmBoxes.find((box) => box.id === vmBoxId)
        foundVmBox.newcount = newMsgs ? newMsgs.length : 0
        foundVmBox.messages = messages.length
        return { vmbox: foundVmBox, messages }
      })

      const allMessages = await Promise.all(promises)
      if (vmBoxes.length === 0) {
        allMessages.push({
          vmbox: { newcount: 0, messages: 0 },
          messages: [],
        })
      }

      dispatch({ type: CONSTS.GET_ALL_VOICEMAILS, payload: allMessages })
    } catch (error) {
      if (error.response?.status === 401) {
        dispatch({ type: CONSTS.FAIL_AUTH_REQUEST, payload: 'Auth_Failed' })
        dispatch(logOut())
      }
    }
  }
}

export const getmainvmboxes = () => {
  return async (dispatch) => {
    try {
      const account_id = localStorage.getItem('account_id')
      const auth_token = localStorage.getItem('token')
      axios.defaults.headers.common['X-AUTH-TOKEN'] = auth_token

      const voicemails = `${CONFIG.API_URL}/accounts/${account_id}/vmboxes`
      const response = await axios.get(voicemails)

      dispatch({ type: CONSTS.ALL_VOICEMAIL_BOXES, payload: response.data.data })

      const vmboxes = response.data.data.filter((vmbox) => !vmbox.owner_id || vmbox.owner_id === account_id)

      const promises = vmboxes.map(async (vmbox) => {
        const url = `${CONFIG.API_URL}/accounts/${account_id}/vmboxes/${vmbox.id}/messages?paginate=false`
        axios.defaults.headers.common['X-AUTH-TOKEN'] = auth_token
        const res = await axios.get(url)

        const messages = res.data.data
        const newMsgs = messages.filter((message) => message.folder === 'new')
        const vmbox_id = res.request.responseURL.split('/')[7]
        const foundVmBox = vmboxes.find((box) => box.id === vmbox_id)

        foundVmBox.newcount = newMsgs ? newMsgs.length : 0
        foundVmBox.messages = messages.length
        return { vmbox: foundVmBox, messages }
      })

      const mainBoxAllMessages = await Promise.all(promises)

      if (vmboxes.length === 0) {
        mainBoxAllMessages.push({
          vmbox: { newcount: 0, messages: 0 },
          messages: [],
        })
      }

      dispatch({ type: CONSTS.GET_ALL_MAINVOICEMAILS, payload: mainBoxAllMessages })
    } catch (error) {
      if (error?.response?.status === 401) {
        dispatch({ type: CONSTS.FAIL_AUTH_REQUEST, payload: 'Auth_Failed' })
        dispatch(logOut())
      }
    }
  }
}
