import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Badge } from 'reactstrap'
import FilterIcons from '../../asset/media/img/filtericons.png'

const FilterMenu = (props) => {
  const [distributionMenu, setDistributionMenu] = useState(false)
  const [filterDistribution, setFilterDistribution] = useState(false)
  const { allList, distributionContacts } = useSelector((state) => state.distribution)
  const { contacts } = useSelector((state) => state.message)
  const [checked, setChecked] = useState({})
  const clearSelected = false
  const handleDistributionModel = () => {
    setFilterDistribution((prevState) => !prevState)
  }

  const handleDistribution = () => {
    setDistributionMenu((prevState) => !prevState)
  }

  const handleOpen = () => {
    setFilterDistribution(true)
  }

  // conatct clicked
  const handleContactOpen = () => {
    props.onFilter({ data: contacts, filter: true })
  }

  const handleClearAll = () => {
    props.onFilter({ data: [], filter: false })
    handleDistribution(true)
  }

  const handleDistributionCheck = (dis) => {
    const updated = { ...checked }
    if (updated[dis._id]) {
      delete updated[dis._id]
    } else {
      updated[dis._id] = dis
    }
    setChecked(updated)
  }

  const handleDistributionChange = () => {
    if (!Object.keys(checked).length) {
      props.onFilter({ data: [], filter: false })
      return
    }
    const output = distributionContacts.distribution
      .filter((x) => checked[x.distributionId])
      .reduce((prev, curr) => {
        prev[curr.phoneNumber] = curr
        return prev
      }, {})
    props.onFilter({
      data: distributionContacts.distribution_contact.filter((x) => output[x.phoneNumber]),
      filter: true,
    })
    setFilterDistribution(false)
    setDistributionMenu(false)
  }

  const toggleSelectAll = () => {
    setFilterDistribution(true)
    if (Object.keys(checked).length) {
      setChecked({})
    } else {
      setChecked(
        allList.reduce((prev, curr) => {
          prev[curr._id] = curr
          return prev
        }, {}),
      )
    }
  }

  return (
    <div className="distribution-menu">
      <Dropdown isOpen={distributionMenu || filterDistribution || clearSelected} toggle={handleDistribution}>
        <DropdownToggle className="distribution-icon">
          {' '}
          <div>
            <img src={FilterIcons} className="iconsSet" alt="avatar" />
          </div>
        </DropdownToggle>
        <DropdownMenu className="distribution-dropmenu">
          <DropdownItem toggle={false} className="drop-filter" header>
            <div className="filter-header" style={{ cursor: 'pointer' }} onClick={handleClearAll}>
              Filter<div className="filter-clear">Clear all</div>
            </div>
          </DropdownItem>
          <DropdownItem
            style={{ cursor: 'pointer' }}
            onClick={handleContactOpen}
            toggle={false}
            className="drop-filter"
          >
            Contact List
          </DropdownItem>
          <DropdownItem toggle={false} onClick={handleOpen} className="drop-filter-distribution">
            Distribution <i className="fas fa-greater-than"></i>
          </DropdownItem>
          <DropdownItem className="drop-select" toggle={false} onClick={toggleSelectAll}>
            Select All
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
      {filterDistribution && (
        <Dropdown isOpen={filterDistribution} toggle={handleDistributionModel}>
          <DropdownToggle className="dist-toggle"></DropdownToggle>
          <DropdownMenu className="filter-dropMenu">
            <div className="drop-scroll">
              {allList &&
                allList.map((distData, index) => (
                  <DropdownItem toggle={false} key={index} className="drop-distribution">
                    <input
                      defaultChecked={!!checked[distData._id]}
                      value={!!checked[distData._id]}
                      type="checkbox"
                      className="drop-checkbox"
                      onClick={() => handleDistributionCheck(distData)}
                    />
                    <div className="disDataDis">{distData.name}</div>
                    <Badge style={{ backgroundColor: `${distData.color}` }} className="dist-badge"></Badge>
                  </DropdownItem>
                ))}
            </div>
            <div className="btn position-add-dropdown" onClick={handleDistributionChange}>
              Apply
            </div>
          </DropdownMenu>
        </Dropdown>
      )}
    </div>
  )
}

export default FilterMenu
