import React, { useState } from 'react'
import { Tooltip } from 'reactstrap'
import CONFIG from '../../constants/config.json'
import './History.css'

const CallRecording = (props) => {
  const { recording } = props
  const [tooltipOpen1, setTooltipOpen1] = useState(false)
  const toggleTooltip1 = () => setTooltipOpen1(!tooltipOpen1)

  const ACCOUNT_ID = localStorage.getItem('account_id')
  const auth_token = localStorage.getItem('token')

  return (
    <>
      <div className="btn btn-light" id="callRecording">
        <a
          href={`${CONFIG.API_URL}/accounts/${ACCOUNT_ID}/recordings/${recording}?auth_token=${auth_token}&accept=audio/mpeg&inline=false`}
        >
          <i className="far fa-file-audio"></i>
        </a>
      </div>
      <Tooltip placement="top" isOpen={tooltipOpen1} autohide={false} target="callRecording" toggle={toggleTooltip1}>
        Download Recording
      </Tooltip>
    </>
  )
}

export default CallRecording
