import React from 'react'
import DatePicker from 'react-datepicker'
import './History.css'

const HistorySearch = (props) => {
  const onChange = (e) => {
    props.changeFilter(e.target.value.trim())
  }

  return (
    <div className="history-search">
      <div className="state-date">
        <label>START DATE</label>
        <DatePicker
          className="form-control calendar1"
          onChange={props.startDateChange}
          maxDate={props.state.endDate}
          selected={props.state.startDate}
          placeholderText="mm/dd/yyyy"
        />
      </div>
      <div className="end-date">
        <label>END DATE</label>
        <DatePicker
          className="form-control calendar1"
          onChange={props.endDateChange}
          selected={props.state.endDate}
          minDate={props.state.startDate}
          maxDate={new Date()}
          placeholderText="mm/dd/yyyy"
        />
      </div>
      <div className="text-right">
        <input className="form-control call-search-text" type="text" placeholder="Search" onChange={onChange} />
      </div>
    </div>
  )
}

export default HistorySearch
