import axios from 'axios'
import CONFIG from '../constants/config'
import * as CONSTS from '../constants/const'
import { getLocalStorageData } from '../lib/helper'

axios.defaults.headers.get['Content-Type'] = 'application/json'
const auth_token = localStorage.getItem('token')
axios.defaults.headers.common['X-AUTH-TOKEN'] = auth_token

export const getAllNotifiations = () => {
  return async (dispatch) => {
    try {
      const result = await axios.get(`${CONFIG.serverURL}/notification`)
      dispatch({ type: CONSTS.GET_ALL_NOTIFICATIONS, payload: result.data })
    } catch (err) {
      dispatch({ type: CONSTS.GET_ALL_NOTIFICATIONS, payload: [] })
    }
  }
}

export const addNotifiation = (data) => {
  return async (dispatch) => {
    try {
      await axios.post(`${CONFIG.serverURL}/notification`, { data })
      dispatch(getAllNotifiations())
    } catch (err) {
      console.log(err)
    }
  }
}

export const deleteNotifiation = (id) => {
  return async (dispatch) => {
    try {
      console.log('==========', id)
      await axios.post(`${CONFIG.serverURL}/delnotification`, { id })
      dispatch(getAllNotifiations())
    } catch (err) {
      console.log(err)
    }
  }
}

export const readNotification = (notifications) => {
  return async (dispatch) => {
    try {
      const { account_id, user_id } = getLocalStorageData()
      const data = {
        account_id,
        user_id,
        notifications,
      }
      const res = await axios.post(`${CONFIG.serverURL}/readnotification`, data)
      console.log('res===>', res)
      if (res.status === 200) {
        console.log('res.data?.notifiations==>', res.data?.notifications)
        dispatch({
          type: CONSTS.GET_READ_NOTIFICATIONS,
          payload: res.data?.notifications,
        })
      }
      // dispatch({
      //   type: CONSTS.GET_READ_NOTIFICATIONS,
      //   payload: res.data?.notifiations || [],
      // })
    } catch (err) {
      console.log(err)
    }
  }
}
