import axios from 'axios'
import CONFIG from '../constants/config'

import * as CONSTS from '../constants/const'
import { getUserData } from './message.action'

export const getPhoneNumbers = (getResync) => {
  return async (dispatch) => {
    const account_id = await localStorage.getItem('account_id')
    const user_id = await localStorage.getItem('user_id')
    const auth_token = await localStorage.getItem('token')
    axios.defaults.headers.common['X-AUTH-TOKEN'] = auth_token

    const checkResult = await axios.post(`${CONFIG.serverURL}/checkadmindid`, { accountId: account_id })
    const phoneNum = `${CONFIG.API_URL}/accounts/${account_id}/users/${user_id}/phone_numbers`
    const callflow = `${CONFIG.API_URL}/accounts/${account_id}/callflows?paginate=false`
    const user = `${CONFIG.API_URL}/accounts/${account_id}/users?paginate=false`
    const group = `${CONFIG.API_URL}/accounts/${account_id}/groups?paginate=false`
    await axios
      .all([axios.get(phoneNum), axios.get(callflow), axios.get(user), axios.get(group)])
      .then(
        axios.spread(async (phoneNums, callflows, users, groups) => {
          const phoneNumData = phoneNums ? phoneNums.data.data.numbers : []
          const callFlowData = callflows ? callflows.data.data : []
          const userData = users ? users.data.data : []
          const groupData = groups ? groups.data.data : []
          const phoneNumArr = Object.keys(phoneNumData)
          const flowData = callFlowData.filter(
            (flow) =>
              flow && flow.numbers.length > 0 && flow.numbers.filter((num) => phoneNumArr.includes(num)).length > 0,
          )
          const userNames = []
          userData.forEach((user) => {
            userNames.push({
              email: user.username,
              userName: user.first_name + ' ' + user.last_name,
              state: false,
              userId: user.id,
            })
          })

          const data = []
          for (let i = 0; i < flowData.length; i++) {
            const _flowItem = flowData[i]
            for (let j = 0; j < _flowItem.numbers.length; j++) {
              const num = _flowItem.numbers[j]
              if (phoneNumArr.includes(num)) {
                const res1 = await getPhoneData(num)
                makeArray(num, _flowItem, res1)
              }
            }
          }
          async function getPhoneData(num) {
            const URL = `${CONFIG.API_URL}/accounts/${account_id}/phone_numbers/${num}`
            const result = await axios.get(URL)
            const e911 = result.data.data && result.data.data.e911 ? result.data.data.e911 : ''
            const cnam = result.data.data && result.data.data.cnam ? result.data.data.cnam : ''

            return { e911: e911, cnam: cnam }
          }

          function makeArray(num, flowdata, phoneData) {
            if (!phoneNumData[num].used_by) {
              data.push({
                assigned: false,
                number: num,
                features: phoneNumData[num].features,
                features_available: phoneNumData[num].features_available,
                state: phoneNumData[num].state,
                assignment: 'Unassigned',
                assignedName: '',
                e911: '',
                ownerType: '',
              })
            } else {
              if (flowdata.owner_id && userData.findIndex((user) => user.id === flowdata.owner_id) !== -1) {
                const index = userData.findIndex((user) => user.id === flowdata.owner_id)
                data.push({
                  assigned: true,
                  number: num,
                  features: phoneNumData[num].features,
                  features_available: phoneNumData[num].features_available,
                  state: phoneNumData[num].state,
                  assignedName: userData[index].first_name + ' ' + userData[index].last_name,
                  assignment: 'Individual',
                  owner_id: flowdata.owner_id,
                  e911: phoneData.e911,
                  cnam: phoneData.cnam,
                  ownerType: 'user',
                })
              } else if (flowdata.group_id) {
                const index = groupData.findIndex((group) => group.id === flowdata.group_id)
                data.push({
                  groupId: flowdata.group_id,
                  assigned: true,
                  number: num,
                  features: phoneNumData[num].features,
                  features_available: phoneNumData[num].features_available,
                  state: phoneNumData[num].state,
                  e911: phoneData.e911,
                  cnam: phoneData.cnam,
                  assignedName: index > -1 ? groupData[index].name : 'Deleted Group',
                  assignment: 'Multiple Users',
                  ownerType: 'group',
                })
              } else if (flowdata.type && flowdata.type === 'main') {
                data.push({
                  assigned: true,
                  number: num,
                  features: phoneNumData[num].features,
                  features_available: phoneNumData[num].features_available,
                  state: phoneNumData[num].state,
                  e911: phoneData.e911,
                  cnam: phoneData.cnam,
                  assignedName: 'Office Number',
                  assignment: 'Multiple Users',
                  ownerType: 'main',
                })
              } else if (flowdata.type && flowdata.type === 'conference') {
                data.push({
                  assigned: true,
                  number: num,
                  features: phoneNumData[num].features,
                  features_available: phoneNumData[num].features_available,
                  state: phoneNumData[num].state,
                  e911: phoneData.e911,
                  cnam: phoneData.cnam,
                  assignedName: 'Main Conference Number',
                  assignment: 'Multiple Users',
                  ownerType: 'conference',
                })
              } else {
                data.push({
                  assigned: true,
                  number: num,
                  features: phoneNumData[num].features,
                  features_available: phoneNumData[num].features_available,
                  state: phoneNumData[num].state,
                  e911: phoneData.e911,
                  cnam: phoneData.cnam,
                  assignedName: 'Custom Callflow',
                  assignment: 'Multiple Users',
                  ownerType: 'callflows',
                  callflowName: flowdata.name ? flowdata.name : '',
                })
              }
            }
          }

          dispatch({ type: CONSTS.GET_ADMINDID_NUMBERS, payload: data })
          dispatch({ type: CONSTS.GET_ADMINDID_USERS, payload: userNames })
          if (!checkResult.data) {
            dispatch(storeAllNumberSate())
          }
          if (getResync === true) {
            const userData = true
            dispatch(resyncNums(userData))
          }
        }),
      )
      .catch((error) => {
        if (typeof error !== 'undefined' && typeof error.response !== 'undefined' && error.response.status === 401) {
          dispatch({ type: CONSTS.FAIL_AUTH_REQUEST, payload: 'Auth_Failed' })
        }
      })
  }
}
export const resyncNums = (userData) => {
  return async (dispatch, getState) => {
    const account_id = localStorage.getItem('account_id')
    const { adminDID } = getState()
    const { allNumbers } = adminDID
    const nums = []
    allNumbers.forEach((num) => {
      nums.push(num.number)
    })
    await axios
      .post(`${CONFIG.serverURL}/resyncnumbers`, {
        accountName: account_id,
        allNumbers: nums,
      })
      .then(async (res) => {
        if (res.data === 'OK') {
          dispatch(storeAllNumberSate())
          if (userData === true) {
            dispatch(getUserData())
          }
        }
      })
  }
}
export const saveCallerIdState = (number, data) => {
  return async (dispatch) => {
    let saveData
    if (data.displayName === '') {
      saveData = { inbound_lookup: data.inboundLookup }
    } else {
      saveData = { inbound_lookup: data.inboundLookup, display_name: data.displayName }
    }
    const account_id = localStorage.getItem('account_id')
    const auth_token = localStorage.getItem('token')
    axios.defaults.headers.common['X-AUTH-TOKEN'] = auth_token

    const URI = `${CONFIG.API_URL}/accounts/${account_id}/phone_numbers/${number}`
    await axios.post(URI, { data: { cnam: saveData } }).then((res) => {
      dispatch(getPhoneNumbers())
    })
  }
}
export const saveE911State = (number, data) => {
  return async (dispatch) => {
    const account_id = localStorage.getItem('account_id')
    const auth_token = localStorage.getItem('token')
    axios.defaults.headers.common['X-AUTH-TOKEN'] = auth_token

    const e911 = `${CONFIG.API_URL}/accounts/${account_id}/phone_numbers/${number}`
    await axios
      .post(e911, { data: { e911: data } })
      .then((res) => {
        dispatch(getPhoneNumbers())
      })
      .catch((err) => {
        dispatch({ type: CONSTS.SAVE_E911_ERRORS, payload: err.response.data.data })
      })
  }
}
export const removeE911State = (number) => {
  return async (dispatch) => {
    const account_id = localStorage.getItem('account_id')
    const auth_token = localStorage.getItem('token')
    axios.defaults.headers.common['X-AUTH-TOKEN'] = auth_token

    const e911 = `${CONFIG.API_URL}/accounts/${account_id}/phone_numbers/${number}`
    await axios
      .post(e911, { data: {} })
      .then((res) => {
        dispatch(getPhoneNumbers())
      })
      .catch((err) => {
        dispatch({ type: CONSTS.SAVE_E911_ERRORS, payload: err.response.data.data })
      })
  }
}
export const clearE911State = () => {
  return async (dispatch) => {
    dispatch({ type: CONSTS.SAVE_E911_ERRORS, payload: '' })
  }
}
export const storeAllNumberSate = () => {
  return async (dispatch, getState) => {
    const { adminDID } = getState()
    const { allNumbers } = adminDID
    for (let i = 0; i < allNumbers.length; i++) {
      const _numberData = allNumbers[i]
      switch (_numberData.ownerType) {
        case 'main':
          dispatch(getAssignMainNumState(_numberData.number))
          break
        case 'group':
          dispatch(getAssignGroupNumState(_numberData.groupId, _numberData.number))
          break
        case 'callflows':
          dispatch(getAssignMainNumState(_numberData.number))
          break
        case 'user':
          dispatch(getAssignUserNumState(_numberData.owner_id, _numberData.number))
          break
        default:
          break
      }
    }
  }
}
export const getAssignMainNumState = (number) => {
  return async (dispatch, getState) => {
    const { adminDID } = getState()
    const { allUsers } = adminDID
    const account_id = localStorage.getItem('account_id')
    await axios
      .post(`${CONFIG.serverURL}/getassignmain`, {
        users: allUsers,
        number: number,
        accountName: account_id,
      })
      .then((res) => {
        dispatch({ type: CONSTS.GET_ADMINDID_USERS, payload: res.data })
      })
  }
}
export const saveAssignMainNumState = (data, number) => {
  return async (dispatch) => {
    const account_id = localStorage.getItem('account_id')
    await axios
      .post(`${CONFIG.serverURL}/saveassignmain`, {
        data: data,
        number: number,
        accountName: account_id,
      })
      .then((res) => {
        dispatch(getAssignMainNumState(number))
      })
  }
}
export const getAssignUserNumState = (owner_id, number) => {
  return async (dispatch) => {
    const account_id = localStorage.getItem('account_id')
    const auth_token = localStorage.getItem('token')

    axios.defaults.headers.common['X-AUTH-TOKEN'] = auth_token

    const username = `${CONFIG.API_URL}/accounts/${account_id}/users/${owner_id}`
    const device_num = `${CONFIG.API_URL}/accounts/${account_id}/callflows?filter_type=mainUserCallflow&filter_owner_id=${owner_id}&paginate=false`
    await axios.all([axios.get(device_num), axios.get(username)]).then(
      axios.spread((deviceNum, userData) => {
        if (deviceNum.data.data[0].numbers.includes(number)) {
          axios
            .post(`${CONFIG.serverURL}/getusernumber`, {
              userId: owner_id,
              number: number,
              accountName: account_id,
            })
            .then((res) => {
              console.log(res)
            })
        }
      }),
    )
  }
}

export const getAssignGroupNumState = (groupId, number) => {
  return async (dispatch, getState) => {
    const account_id = localStorage.getItem('account_id')
    const auth_token = localStorage.getItem('token')
    axios.defaults.headers.common['X-AUTH-TOKEN'] = auth_token
    const { adminDID } = getState()
    const { allUsers } = adminDID
    const group = `${CONFIG.API_URL}/accounts/${account_id}/groups/${groupId}`

    await axios.get(group).then(async (res) => {
      const groupData = res.data.data
      const groups = Object.keys(groupData.endpoints)
      if (groups && groups.length > 0) {
        const users = allUsers.filter((user) => groups.includes(user.userId))
        await axios
          .post(`${CONFIG.serverURL}/getassignmain`, {
            users: users,
            number: number,
            accountName: account_id,
          })
          .then((res) => {
            dispatch({ type: CONSTS.GET_ADMINDID_GROUPUSERS, payload: res.data })
          })
      }
    })
  }
}
export const saveAssignGroupNumState = (data, number) => {
  return async (dispatch) => {
    const account_id = localStorage.getItem('account_id')
    await axios.post(`${CONFIG.serverURL}/saveassignmain`, {
      data: data,
      number: number,
      accountName: account_id,
    })
  }
}

export const generatePortOutPin = (userEmail) => {
  return async (dispatch) => {
    await axios
      .post(`${CONFIG.serverURL}/saveportoutpin`, {
        email: userEmail,
      })
      .then((res) => {
        if (res.status === 200) dispatch(getUserData())
      })
  }
}
