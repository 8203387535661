import React from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'

import MessageText from './MessageText'
import MessageMedia from './MessageMedia'
import silhoutte from '../../asset/media/img/silhoutte.png'
import robot from '../../asset/media/img/robot-solid.svg'
import CONFIG from '../../constants/config'

import { convertDateTime, convertScheduleDateTime, getMemberName } from '../../lib/utilities'

const ChatBody = ({
  messagesList,
  values,
  adminPhoneNum,
  memberInformation,
  scheduleToggleModal,
  setScheduleMessage,
  messagesEndRef,
}) => {
  return (
    <div className={`chat-body ${messagesList.length === 0 ? 'no-message' : ''}`}>
      <PerfectScrollbar>
        {messagesList.length === 0 ? (
          <div className="no-message-container">
            <i className="fas fa-comments"></i>
            <p>Select a conversation to read messages</p>
          </div>
        ) : (
          <div className="messages">
            {values.phoneNum &&
              messagesList &&
              messagesList.length > 0 &&
              messagesList.map((message, index) => {
                if (
                  (values.phoneNum === message.to_number || values.phoneNum === message.distributionId) &&
                  adminPhoneNum === message.from_number &&
                  message.direction === 'out'
                ) {
                  return (
                    <div key={index} className="message-item outgoing-message  mt-2">
                      <div className="message-row">
                        <div className="message-text">
                          {message.text && (
                            <MessageText
                              message={message}
                              toggleModal={scheduleToggleModal}
                              setScheduleMessage={setScheduleMessage}
                            />
                          )}
                          {message.media && <MessageMedia message={message} />}

                          <div className="message-action">
                            <span className="sender">
                              {message.automation ? '' : message.userName !== '' ? message.userName : message.sender}
                            </span>
                            <p>
                              {message.scheduleTime
                                ? convertDateTime(message.scheduleTime)
                                : convertDateTime(message.createdAt)}
                            </p>
                            {message.is_failed === true ? (
                              <div className="error-close-icon"> {message.err_description}</div>
                            ) : message.state === '1' ? (
                              <i className="ti-double-check"></i>
                            ) : (
                              <i className="ti-check"></i>
                            )}
                          </div>
                          {message.scheduleMsg && (
                            <div className="chat-end-msg">
                              <div className="chat-schedule-msg">
                                Your message will be sent to{' '}
                                <span className="chat-schedule-name">@{getMemberName(memberInformation)} </span>
                                {convertScheduleDateTime(message.scheduleTime)}.{' '}
                                <span
                                  className="chat-schedule-edit"
                                  onClick={() => {
                                    setScheduleMessage(message)
                                    scheduleToggleModal()
                                  }}
                                >
                                  Edit this message
                                </span>
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="user-image">
                          {message.automation ? (
                            <img src={robot} className="robot" alt="avatar" />
                          ) : message.avatar ? (
                            <img src={`${CONFIG.serverURL}/users/${message.avatar}`} className="avatar" alt="avatar" />
                          ) : (
                            <img src={silhoutte} className="avatar" alt="avatar" />
                          )}
                        </div>
                      </div>
                    </div>
                  )
                } else if (
                  values.phoneNum === message.from_number &&
                  adminPhoneNum === message.to_number &&
                  message.direction === 'in'
                ) {
                  return (
                    <div key={index} className="message-item  incoming-message">
                      <div className="incoming-sender">
                        <div className="sender-user-image">
                          {/* {message.automation ? (
                            <img src={robot} className="robot" alt="avatar" />
                          ) : message.avatar ? (
                            <img src={`${CONFIG.serverURL}/users/${message.avatar}`} className="avatar" alt="avatar" />
                          ) : (
                            <img src={silhoutte} className="avatar" alt="avatar" />
                          )} */}
                        </div>
                        <div className="message-text-media">
                          {message.text && <MessageText message={message} />}
                          {message.media && <MessageMedia message={message} />}
                        </div>
                      </div>
                      <div className="message-action">{convertDateTime(message.createdAt)}</div>
                    </div>
                  )
                }
                if (message.assigner_name && message.assigned_number === values.phoneNum) {
                  return (
                    <div className="assigned-message" key={index}>
                      <div className="title">
                        Message {message.state ? 'assigned' : 'unassigned'} to {message.assigned_name} by{' '}
                        {message.assigner_name}
                      </div>
                      <div className="time">{convertDateTime(message.createdAt)}</div>
                    </div>
                  )
                }
                return true
              })}
            <div ref={messagesEndRef} id="end-message" />
            <div className="chat-end-msg"></div>
          </div>
        )}
      </PerfectScrollbar>
    </div>
  )
}

export default ChatBody
