import React from 'react'
import { Tooltip, Card, CardBody, Badge } from 'reactstrap'
import { phoneNumFormat, getMemberName, getCompanyName, getProfileAvatar, truncateString } from '../../lib/utilities'
import ReactPaginate from 'react-paginate'
import { useDispatch, useSelector } from 'react-redux'

import * as CONSTS from '../../constants/const'
import { getAssignedConversations } from '../../actions/message.action'

import DropMenu from './DropMenu'
import silhoutte from '../../asset/media/img/silhoutte.png'
import './message.css'

const AssignConversations = (props) => {
  const {
    assignedConversation,
    assignedMember,
    changeAssignedConversation,
    checkAssignConversation,
    checkNotifies,
    isToolTipOpen,
    toggleTooltip4,
    deleteFavorite,
    addFavorite,
    assignConversationShowPopup,
    checkAssignConversationLength,
    adminPhoneNum,
    deleteHistory,
    isContactDialog,
    assignTotalCount,
    searchNumber,
  } = props
  const dispatch = useDispatch()
  const { userName, normalConversations, favoriteConversations } = useSelector((state) => state.message)

  const handleAssignPageClick = async (data) => {
    const { selected } = data
    await dispatch({
      type: CONSTS.GET_ASSIGNED_CONVERSATIONS_PAGINATION_NUMS,
      payload: selected,
    })
    await dispatch(getAssignedConversations(searchNumber))
  }

  const getFavorite = (member) => {
    return favoriteConversations.favConversationsList.findIndex((item) => item.memberNum === member) === -1
      ? false
      : true
  }

  return (
    <>
      <ul className="list-group list-group-flush">
        {assignedConversation &&
          assignedConversation &&
          assignedConversation.map((conversation, i) => {
            const memberName = getMemberName(conversation)
            const companyName = getCompanyName(conversation)
            const avatar = getProfileAvatar(conversation)

            return (
              <Card key={i} className="chat-name">
                <CardBody>
                  <li
                    className={`list-group-item ${
                      conversation.item.assigned_number === assignedMember.assigned_number ? 'open-chat' : ''
                    } ${
                      checkAssignConversation(conversation.item.assigned_number) &&
                      !checkNotifies(conversation.item.assigned_number)
                        ? 'list-chat'
                        : !checkNotifies(conversation.item.assigned_number) ||
                          checkAssignConversation(conversation.item.assigned_number)
                        ? 'list-count-chat'
                        : ''
                    }`}
                    onClick={() => changeAssignedConversation(conversation.item)}
                  >
                    <figure className={`avatar ${avatar ? 'profile' : 'icon'}`}>
                      <img alt="Avatr" src={avatar || silhoutte} />
                    </figure>

                    <div className="users-list-body">
                      <span onClick={() => changeAssignedConversation(conversation.item)}>
                        {conversation.contacts?.length === 0 ? (
                          <h5>{phoneNumFormat(conversation.item.assigned_number)}</h5>
                        ) : (
                          <span>
                            <div className="chat-labelName">
                              <h5>{truncateString(memberName, 17)}</h5>
                              <h5 id={`assignnumbercount-${i}`}>
                                {conversation.contacts.length > 1 && `or  ${conversation.contacts.length - 1} other`}{' '}
                              </h5>
                              <Tooltip
                                placement="bottom"
                                isOpen={isToolTipOpen(`assignnumbercount-${i}`)}
                                autohide={false}
                                target={`assignnumbercount-${i}`}
                                toggle={() => toggleTooltip4(`assignnumbercount-${i}`)}
                              >
                                {conversation.contacts.map((data, i) => (
                                  <div key={i}>{data.labelName === '' ? data.company : data.labelName},</div>
                                ))}
                              </Tooltip>
                            </div>
                            <h6>{companyName}</h6>
                            <h6>{phoneNumFormat(conversation.item.assigned_number)}</h6>
                          </span>
                        )}
                        {getFavorite(conversation.item.assigned_number) ? (
                          <i
                            onClick={() => deleteFavorite(conversation.item.assigned_number)}
                            className="far fa-star chat-favorite-icon"
                          ></i>
                        ) : (
                          <i
                            onClick={() => addFavorite(conversation.item.assigned_number)}
                            className="far fa-star chat-important-icon"
                          ></i>
                        )}
                        {!conversation.readConversation && (
                          <div key={i} className="users-list-action">
                            <div className="new-message-count">1</div>
                          </div>
                        )}
                        <Badge className="assign-badge" onClick={() => assignConversationShowPopup(conversation)} pill>
                          Assigned to {checkAssignConversationLength(conversation.item.assigned_number)}{' '}
                          {`${
                            checkAssignConversationLength(conversation.item.assigned_number) === 1 ? 'person' : 'people'
                          }`}
                        </Badge>
                        {normalConversations.notifies &&
                          normalConversations.notifies.map((notify, i) => {
                            if (notify.number === conversation.item.assigned_number) {
                              return (
                                <div key={i} className="users-list-action">
                                  <div className="new-message-count">{notify.newMsg}</div>
                                </div>
                              )
                            }
                            return null
                          })}
                      </span>
                    </div>
                  </li>
                  <div className="users-list-action">
                    <div className="action-toggle">
                      <DropMenu
                        tab="favTab3"
                        toNumber={conversation.item.assigned_number}
                        readConversation={conversation.readConversation}
                        fromNumber={adminPhoneNum}
                        email={userName.userEmail}
                        deleteHistory={deleteHistory}
                        contactID={conversation.contactID}
                        labelName={conversation.labelName}
                        member={conversation.item}
                        isContactDialog={isContactDialog}
                      />
                    </div>
                  </div>
                </CardBody>
              </Card>
            )
          })}
      </ul>
      {assignTotalCount > 1 && (
        <ReactPaginate
          previousLabel={'⟨'}
          nextLabel={'⟩'}
          breakClassName={'break-me'}
          pageCount={assignTotalCount}
          marginPagesDisplayed={0}
          pageRangeDisplayed={2}
          onPageChange={handleAssignPageClick}
          containerClassName={'con-pagination'}
          activeClassName={'active'}
        />
      )}
    </>
  )
}

export default AssignConversations
