import React, { Fragment, useCallback, useState, useEffect } from 'react'
import ResellerCreateModal from './reseller-create-modal'
import { useDispatch, useSelector } from 'react-redux'
import { getResellerList } from '../../../actions/campaign.action'

const ResponsibleParties = React.memo(({ resellerId, onChange }) => {
  const dispatch = useDispatch()
  const resellers = useSelector((state) => state.campaignManagement?.resellerList ?? [])
  const [isNewSellerModalOpen, setNewSellerModalOpen] = useState(false)

  useEffect(() => {
    dispatch(getResellerList())
  }, [dispatch])

  const handleResellerChange = useCallback(
    (e) => {
      if (e.target.value === 'NEW') {
        setNewSellerModalOpen(true)
      } else {
        onChange(e.target.value)
      }
    },
    [onChange],
  )

  return (
    <Fragment>
      <div className="wrapper-preview">
        <p>Other Responsible Parties</p>
        <span style={{ fontSize: '15px', lineHeight: '14px', fontWeight: 400, color: '#000' }}>
          For Sole Proprietor campaigns, if your CNP is not showing in the list, it means they are not enabled to
          receive Sole Proprietor campaigns. Please reach out to your CNP for more details.
        </span>
        <div>
          <div style={{ marginTop: 12 }}>
            <select
              name="country"
              id="country"
              value={resellerId}
              className="form-control "
              onChange={handleResellerChange}
            >
              <option value="" disabled>
                Select if reseller involved
              </option>
              <option value="NEW">Add new reseller</option>
              {resellers.map((el) => (
                <option value={el.resellerId} key={el.resellerId}>
                  {el.companyName}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
      <ResellerCreateModal isOpen={isNewSellerModalOpen} onClose={() => setNewSellerModalOpen(false)} />
    </Fragment>
  )
})

export default ResponsibleParties
