import React from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import Sidebar from '../Sidebar/Sidebar'
import Dialog from '../Sidebar/Dialog'
import UserHeader from '../Header/UserHeader'
import MailboxContainer from './MailboxContainer'

import './voicemails.css'

const VoicemailBox = (props) => {
  const {
    history,
    contactUsModal,
    changeSetNumberModal,
    contactToogle,
    setNumberToogle,
    allmessages,
    mainVoicemailMessages,
  } = props

  return (
    <div className="light">
      <div className="layout-user">
        <Sidebar history={history} contactUsModal={contactUsModal} changeSetNumberModal={changeSetNumberModal} />
        <Dialog
          contactUsModal={contactUsModal}
          contactToogle={contactToogle}
          changeSetNumberModal={changeSetNumberModal}
          setNumberToogle={setNumberToogle}
        />
        <div className="content">
          <UserHeader changeSetNumberModal={changeSetNumberModal} />
          <div className="voicemailbox-container">
            <PerfectScrollbar>
              <div className="voicemail-list">
                {allmessages &&
                  allmessages.map((box, index) => (
                    <div className="col-md-4 mb-3 main-container" key={`vmbox-${index}`}>
                      <MailboxContainer {...box.vmbox} history={history} index={index} />
                    </div>
                  ))}
                {mainVoicemailMessages &&
                  mainVoicemailMessages.map((box, index) => (
                    <div className="col-md-4 mb-3 main-container" key={`main-vmbox-${index}`}>
                      <MailboxContainer {...box.vmbox} history={history} index={index} />
                    </div>
                  ))}
              </div>
            </PerfectScrollbar>
          </div>
        </div>
      </div>
    </div>
  )
}

export default VoicemailBox
