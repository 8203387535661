import * as CONSTS from '../constants/const'

let defaultState = {
  incoming: [],
  inbox: [],
  outbox: [],
  details: [],
  faxboxNumbers: [],
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case CONSTS.GET_ALL_INCOMING_FAXES:
      return { ...state, incoming: action.payload }
    case CONSTS.GET_ALL_INBOX_FAXES:
      return { ...state, inbox: action.payload }
    case CONSTS.GET_ALL_OUTBOX_FAXES:
      return { ...state, outbox: action.payload }
    case CONSTS.GET_FAX_DETAILS:
      return { ...state, details: action.payload }
    case CONSTS.FAXBOX_NUMBERS:
      return { ...state, faxboxNumbers: action.payload }
    default:
      return state
  }
}
