import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import './campaignmanagement.css'
import CreateBrand from './CreateBrand'
import { getBrandList } from '../../actions/campaign.action'
import ReactPaginate from 'react-paginate'
import { useHistory, useLocation } from 'react-router'
import { useCallback } from 'react'
import moment from 'moment/moment'
import { Button } from 'reactstrap'
import { toast } from 'react-toastify'
import Axios from 'axios'
import CONFIG from '../../constants/config'

const BrandManagement = () => {
  const [openBrandModel, setOpenBrandModel] = useState(false)
  const { BrandList } = useSelector((state) => state.campaignManagement)
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const [isLoading, setLoading] = useState(false)
  const params = new URLSearchParams(location.search)
  const page = params.get('page') || '1'
  const recordsPerPage = params.get('recordsPerPage') || '10'
  const [isDeleting, setDeleting] = useState({})

  const toggleBrandModel = () => {
    setOpenBrandModel(!openBrandModel)
  }


  const handlePageChange = useCallback(
    ({ selected }) => {
      const updatedPage = String(selected + 1)
      if (updatedPage !== page) {
        history.push({
          pathname: history.location.pathname,
          search: `?page=${selected + 1}`,
        })
      }
    },
    [page, history],
  )

  useEffect(() => {
    setLoading(true)
    dispatch(getBrandList({ page, recordsPerPage }, () => setLoading(false)))
  }, [dispatch, page, recordsPerPage])



  const handleBrandDeactivate = useCallback(async (brandId) => {
    try {
      setDeleting(prev => ({ ...prev, [brandId]: true }))
      await Axios.delete(`${CONFIG.serverURL}/brand-registry/${brandId}`);
      await dispatch(getBrandList({ page, recordsPerPage }, () => { }))
    } catch (err) {
      toast.error("Something went wrong..")
    } finally {
      setDeleting(prev => ({ ...prev, [brandId]: false }))
    }
  }, [page, recordsPerPage, dispatch])

  return (
    <div className="col-lg-12">

      <div className="brand-inner report-table-content">
        <div onClick={toggleBrandModel}>
          <CreateBrand />
        </div>
        <table className="table table-striped mb-0 client-table mt-4">
          <thead>
            <tr>
              <th>Brand Name</th>
              <th>Brand ID</th>
              <th>BRAND RELATIONSHIP</th>
              <th>REGISTERED ON</th>
              <th>ENTITY TYPE</th>
              <th>COUNTRY</th>
              <th>City</th>
              <th>Delete</th>
            </tr>
          </thead>
          <tbody>
            {BrandList?.records?.length > 0 &&
              BrandList?.records.map((brand, index) => (
                <tr key={index}>
                  <td>{brand.displayName}</td>
                  <td>{brand.brandId}</td>
                  <td>{brand.brandId}</td>
                  <td>{moment(brand.createDate).format('YYYY-MM-DD')}</td>
                  <td>{brand.entityType}</td>
                  <td>{brand.country}</td>
                  <td>{brand.city}</td>
                  <td>
                    <Button color="primary"  disabled={isDeleting[brand?.brandId]} onClick={() => handleBrandDeactivate(brand.brandId)}>
                      Delete
                    </Button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        {isLoading ? (
          <p className="loading-text">Loading...</p>
        ) : (
          <div className="pagination">
            <ReactPaginate
              breakLabel="..."
              nextLabel="Next"
              onPageChange={handlePageChange}
              pageCount={Math.floor(BrandList.totalRecords || 0) / Number(recordsPerPage)}
              previousLabel="Previous"
              initialPage={Number(page) - 1}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default BrandManagement
